<template>
    <main>
        <ImportProductDigital  v-on:applyData="ChooseProductDigital"  v-on:closeModal="closePopup"  v-if="isEnablePopup"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Produk Promo Banner  
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola diskon / promo pada harga produk yang akan di terapkan pada konten banner di apps.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="form-group ">
                                <label class=" p-0">Produk Digital Promo <small class="text-danger">*</small></label>
                                <span ><i class="mdi mdi-tag-plus mr-1" v-if="model_digital.name"></i> {{ model_digital.name ? '( ' + model_digital.name + ')'  :  'Tentukan produk digital.'}} </span>
                                <small class="text-danger"  v-if="service_form_error.product_name"><br/>{{ service_form_error.product_name[0] }}</small>
                                <div class="float-right">
                                    <a href="javascript:;" class="btn btn-sm btn-light btn-query" @click="isEnablePopup = true"><i class="mdi mdi-magnify mr-1"></i> Cari Produk Digital</a>
                                </div>
                                <hr/>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col">
                                        <label class=" p-0">Nama Promo <small class="text-danger">*</small></label>
                                        <input type="text" class="form-control"  placeholder="...." v-model="model.title">
                                        <small class="text-danger" v-if="service_form_error.title">{{ service_form_error.title[0] }}</small>
                                    </div>
                                    <div class="col">
                                        <label>Tipe Listing</label>
                                        <select class="form-control" v-model="model.listing_type">
                                            <option value=""> -- --</option>
                                            <option value="direct">Direct</option>
                                            <option value="group">Group</option>
                                        </select>
                                        <small class="text-danger" v-if="service_form_error.listing_type">{{ service_form_error.listing_type[0] }}</small>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div class="form-group row mt-3">
                                <label class="p-3">Masa berlaku promo <small class="text-danger">*</small></label>
                                <div class="col">
                                    <label>Start Date</label>
                                    <input type="date" id="startDate" class="form-control"/>
                                    <small class="text-danger" v-if="service_form_error.start_date">{{ service_form_error.start_date[0] }}</small>
                                </div>
                                <div class="col">
                                    <label>End Date</label>
                                    <input type="date" id="endDate" class="form-control"/>
                                    <small class="text-danger" v-if="service_form_error.end_date">{{ service_form_error.end_date[0] }}</small>
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label>Deskripsi <small class="text-danger">*</small></label>
                                <QuillEditor  theme="snow" output="html" style="height: 400px; background-color:#fff;"/>
                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                            </div> 
                            <div class="form-group text-center mt-5">
                                <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Save()"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    
    import $ from 'jquery';
    import moment from "moment";

    export default 
    {   
        components: {
            QuillEditor
        },
        data() {
            return {
                isSubmitProcess : false,
                isEnablePopup : false,
                model: {},
                model_digital : {}
            };
        },
       
        methods: 
        {
            closePopup()
            {
                this.isEnablePopup = false;
            },
            ChooseProductDigital(data)
            {
                this.model_digital = data;
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;


                 // Start Date - End Date
                var sdate = document.getElementById('startDate').value;
                var edate = document.getElementById('endDate').value;

                if( sdate !== '' ){
                    this.model.start_date = moment(sdate).format("DD/MM/YYYY HH:mm:ss");
                }
                if( edate !== '' ){
                    this.model.end_date = moment(edate).format("DD/MM/YYYY HH:mm:ss");
                }


                // processing rewrite value
                this.model.product_operator_code = this.model_digital.operator_code;
                this.model.product_code = this.model_digital.product_code;
                this.model.product_name = this.model_digital.name;
                this.model.description = $('.ql-editor').html();
                let res = await this.ServiceApiPost({ action : 'post', url: 'promo/store', data :this.model, callback_url : 'promo-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
<style>
.groupbox{
    background-color:#fafafaa4;
    border:1px solid #ececec;
    padding:10px;
    float:left;
    width:100%;
}
.btn-query{
    position:relative;
    top:-17px;
}
</style>