<template>
    <PopupManageStock v-if="isEnablePopup == 'manage-stock'"  :data_model="model_detail" v-on:closePopup="CloseManageStock" />
    <PopupDetailStock v-if="isEnablePopup == 'detail-stock'"  :data_model="model_detail" v-on:closePopup="CloseDetailStock" />
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Faktur Pembelian
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data produk non-digital.</div>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="table-info">
                            <div class="float-left">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-cloud-download text-success"></i> 
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <input type="text" class="form-control" placeholder="Cari Kode Item, Nama Produk ...." v-model="mixmeta_data.sval"/>
                                <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                <a href="javascript:;" @click="ServiceSearchData()" class="search"><i class="mdi mdi-magnify"></i></a>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter">Action</th>
                                        <th class="text-center no-filter">Photo</th>
                                        <th class="no-filter">Nama & Kode Reseller</th>
                                        <th class="no-filter" >Terima Barang (Qty)</th>
                                        <th class="no-filter" >Penjualan Barang (Qty) </th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="ManageStockProduct(idx)"  v-if="$store.state.privileges_modules.uam.includes('insert_stock')">Distribusi Barang</a>
                                                    <a href="javascript:;" class="dropdown-item" @click="DetailStockProduct(idx)" > Detail Stock </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center" width="80">
                                            <ImageSmCircle :photo="items.photo" :initial="'sales'"/>
                                        </td>
                                        <td  width="200">
                                            {{ $filters.ucwords(items.name) }} <br/>
                                            <b>{{ items.code_otmx_reseller }}</b>
                                        </td>
                                        <td><span class="text-success">{{ items.total_receive }}</span> Pcs</td>
                                        <td><span class="text-danger">{{ items.total_sales }}</span> Pcs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import PopupManageStock from './PopupManageStock';
    import PopupDetailStock from './PopupDetailStock';
    export default {
        components : {PopupManageStock, PopupDetailStock},
        data() {
            
            return {
                isEnablePopup : false,
                model_detail : {},
                isFirstLoad : true,
            }
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            ChooseProvider(data)
            {
                this.fid_provider = data ? data.id : 'all';
                this.DataQuery();
            },
            CloseDetailStock()
            {
                this.isEnablePopup = false;
                this.DataQuery();
            },
            DetailStockProduct(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'detail-stock';
            },
            CloseManageStock(initial)
            {
                this.isEnablePopup = false;
                if(initial){
                    this.DataQuery();
                }
            },
            ManageStockProduct(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'manage-stock';
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_distribution-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                   
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }
        }
    }
</script>
