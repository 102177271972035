<template>
    <footer class="footer">
        <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © <a href="https://dflash.co.id/" target="_blank" class="text-orange">Dflash</a> 2023</span>
            <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center"> Development By : <a href="https://dflash.co.id/" target="_blank" class="text-white">Dflash Technology Team</a></span>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
footer{
    margin-top:150px;
    span{
        font-size:13px;
    }
    .text-muted{color:#363636 !important;}
}
</style>