<template>
    <main>
        <header class="">
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Access Kontrol
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola akes kontrol aplikasi dengan mencentang fitur pada module yang di butuhkan.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                     <div class="card-header">
                        <div class="alert alert-light alert-dismissible fade show" role="alert">
                            <h4><i class="mdi mdi-information-outline text-warning"></i> <span>Informasi</span></h4>
                            <hr/>
                            <p>
                                - Pilih kategori module yang tersedia. <br/>
                                - Jika ingin memberikan akses terhadap module aplikasi centang pada opsi checkbox 
                            </p>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="mt-3">
                                    <span class="icon-privileges"><i class="mdi mdi-checkbox-marked-circle-outline"></i> </span> 
                                    <div>
                                        <span class="text-secondary">Privileges</span><br/>
                                        <span>{{ privileges_name }}</span>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="">Pilih Module Aplikasi </label>
                                <select class="form-control" @change="ChangeModule()" v-model="isViewModule">
                                    <option value="">-- --</option>
                                    <option value="aplikasi">Aplikasi</option>
                                    <option value="aplikasi_android">Aplikasi Android</option>
                                    <option value="product">Produk</option>
                                    <option value="keuangan">Keuangan</option>
                                    <option value="developers">Developers</option>
                                    <option value="report">Report</option>
                                </select>
                            </div>
                            <div class="col-md-12"><hr/></div>

                            <div class="col-md-12" v-if="isViewModule == 'aplikasi'">
                                <label class="text-secondary mt-3">Aplikasi</label>
                                <div class="app-list" v-for="(items, idx) in module_aplikasi" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)"   :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'developers'">
                                <label class="text-secondary mt-3">Developers</label>
                                <div class="app-list" v-for="(items, idx) in module_developers" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'aplikasi_android'">
                                <label class="text-secondary mt-3">Aplikasi Android</label>
                                <div class="app-list" v-for="(items, idx) in module_aplikasi_android" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12" v-if="isViewModule == 'product'">
                                <label class="text-secondary mt-3">Product</label>
                                <div class="app-list" v-for="(items, idx) in module_product" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'keuangan'">
                                <label class="text-secondary mt-3">Keuangan</label>
                                <div class="app-list" v-for="(items, idx) in module_keuangan" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'sales'">
                                <label class="text-secondary mt-3">Sales</label>
                                <div class="app-list" v-for="(items, idx) in module_sales" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'konter'">
                                <label class="text-secondary mt-3">Konter</label>
                                <div class="app-list" v-for="(items, idx) in module_konter" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="isViewModule == 'report'">
                                <label class="text-secondary mt-3">Report</label>
                                <div class="app-list" v-for="(items, idx) in module_report" :value="idx" v-bind:key="idx" >
                                    <div>
                                        <h6 ><i :class="items.parent.icon"></i>  {{ items.parent.name }} <br/><small class="text-secondary mt-2" v-if="items.parent.caption">{{ items.parent.caption }}</small></h6>
                                        <div v-if="!items.child" class="checkbox-tools">
                                            <div class="cover" v-for="(row, index) in items.parent.action" v-bind:key="index">
                                                <input type="checkbox" :id="GenerateId(items.parent.mkey+'-'+index)"  :data-access="index" :class="GenerateClass(items.parent.mkey)" :checked="ValidateChecked(items.parent.mkey, index )" @change="CollectModule(GenerateId(items.parent.mkey+'-'+index), items.parent.mkey, index)" :disabled="isEnableCheckbox" /> 
                                                <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <ul>
                                                <li v-for="(items_child, idx) in items.child" :key="idx" >
                                                    <h6>{{ items_child.name }} <small class="text-secondary mt-2">{{ items_child.caption }}</small></h6>
                                                    <div class="checkbox-tools" v-for="(row, index) in items_child.action" v-bind:key="index">
                                                        <input type="checkbox"  :data-access="index" :class="GenerateClass(items_child.mkey)" :id="GenerateId(items_child.mkey+'-'+index)" :checked="ValidateChecked(items_child.mkey, index )"     @change="CollectModule(GenerateId(items_child.mkey+'-'+index), items_child.mkey, index)" :disabled="isEnableCheckbox"/> 
                                                        <span :class="CleanString(index) == 'View' ? 'access-modules' : ''">{{ CleanString(index) == 'View' ? 'Izinkan Akses Modul' : CleanString(index) }}</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
    import $ from 'jquery';
    import Reg from '../../json/reg.json';
    export default {

        data() 
        {
            return {
                isViewModule : 'aplikasi',
                isEnableCheckbox : true,
                selected_index_module : '',
                user_modules:[],

                privileges_name : localStorage.getItem('QUERY_NAME'),
                model : [],
                module_collection:[],
                model_privileges : {},
                module_aplikasi : [],
                module_developers : [],
                module_keuangan : [],
                module_sales : [],
                module_konter : [],
                module_report : [],
                module_product : [],
                module_aplikasi_android : [],
            }
        },
        created()
        {
            this.DataQuery();
            this.GroupModule();
        },
        methods:
        {
            GroupModule()
            {
                let model = Reg;
                this.model = model;
                for(let key in model)
                {
                    if(model[key].parent.group == 'aplikasi'){
                        this.module_aplikasi.push(model[key]);
                    }
                    if(model[key].parent.group == 'developers'){
                        this.module_developers.push(model[key]);
                    }
                    if(model[key].parent.group == 'keuangan'){
                        this.module_keuangan.push(model[key]);
                    }
                    if(model[key].parent.group == 'sales'){
                        this.module_sales.push(model[key]);
                    }
                    if(model[key].parent.group == 'konter'){
                        this.module_konter.push(model[key]);
                    }
                    if(model[key].parent.group == 'report'){
                        this.module_report.push(model[key]);
                    }
                    if(model[key].parent.group == 'product'){
                        this.module_product.push(model[key]);
                    }
                    if(model[key].parent.group == 'aplikasi_android'){
                        this.module_aplikasi_android.push(model[key]);
                    }
                  
                }
            },
            CleanString(str)
            {
                let word = str.replace('_', ' ');
                return word.charAt(0).toUpperCase() + word.slice(1)
            },
            GenerateClass(string){
                return string.replace('.', '-');
            },
            GenerateId(string)
            {
                let s = string.split('.');
                if( s[1] !== undefined ){
                    return s[1];
                }else{
                    return string;
                }
            },
           
            ValidateChecked( mkey, action)
            {
                let model = this.user_modules;
                for(let key in model)
                { 
                    var arr_action = model[key].action;
                    if( mkey == model[key].mkey )
                    {
                        if( arr_action.includes(action) ){
                            return true;
                        }
                    }
                }
                
                if(action == 'view'){
                    $('.'+this.GenerateClass(mkey)).each(function()
                    {
                        if( $(this).attr('data-access') !== 'view' )
                        {
                            $(this).parent().hide();
                        }
                    });
                }
                
                
                return false;
            },

            async CollectModule(initial_id, mkey, action)
            {
                var value = 'no';
                if($("#"+initial_id).is(':checked')){value = 'yes';}

                if( action == 'view' )
                {
                    $('.'+this.GenerateClass(mkey)).each(function()
                    {
                        if( $(this).attr('data-access') !== 'view' )
                        {
                            if(value == 'no'){
                                $(this).parent().hide();
                            }else{  
                                $(this).parent().show();
                            }
                        }
                    });
                }
                
                const query = await this.ServiceApiPost({url:'module/update', data : {
                    id_privileges : localStorage.getItem('QUERY_ID'),
                    action : action,
                    mkey : mkey,
                    val : value
                }});
                if(query){ 

                    if(query.result == 'true')
                    {
                        //this.user_modules = [];
                        //this.DataQuery();
                    }else{
                        alert('Failed update access control');
                    }
                }
            },
            
            async DataQuery()
            {
                this.isEnableCheckbox = true;
                let query = await this.ServiceApiPost({
                    url: process.env.VUE_APP_API_URL + 'app/module', 
                    data:{id_privileges : localStorage.getItem('QUERY_ID')}
                });
                if(query)
                {
                    for(let key in query.data){
                        this.user_modules.push({'mkey' : query.data[key].mkey, 'action' :  JSON.parse(query.data[key].action)});
                    }
                    
                }
                this.isEnableCheckbox = false;

            },
        }
    }
</script>

<style scoped lang="scss">

.icon-privileges{
    width:43px;
    height:43px;
    float:left;
    text-align:center;
    border-radius:27px;
    background-color:#ffffff87;
    margin-right:10px;
    position:relative;
    i{
        font-size:23px;
        position:relative;
        top:5px;
        color:#2cc62c;
    }
}
.data-group{
    padding:10px;
    margin:0;
    width:100%;
    float:left;
    border-radius:3px;
    li{
        float:left;
        width:100%;
        list-style:none;
    }
}
.app-list{
    float:left;
    width:100%;
    border-bottom:1px solid whitesmoke;
    padding:20px;
  
    div.cover{
        float:left;
        position:relative;
    }
    .checkbox-tools{
        position:relative;
        float:left;
        padding:5px;
        input[type='checkbox']{
            position:relative;
            float:left;
            z-indeX:9;
            top:6px;
        }
        .access-modules{
            position:relative;
            z-index:1;
            left:-25px;
            background-color:whitesmoke;
            border-radius:3px;
            border:1px solid #ececec;
            padding:5px;
            padding-left:25px;
        }
        span{
            font-size:13px;
            position:relative;
            margin-left:5px;
            margin-right:15px;
            top:0px;
        }
    }

    h6{
        i{
            margin-right:5px;
            color:gray;
        }
        font-size:14px;
    }
    
    ul{
        padding:0;
        margin-top:10px;
        margin-left:20px;
        li{
            padding:5px;
            float:left;
            width:100%;
            list-style:none;

            h6{
                display:grid;
                width:100%;
                float:left;
            }
           
        }
    }

  
}

</style>