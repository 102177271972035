<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-md" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Pesan</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body profiles">
                    <div class="row">
                        <div class="col-md-12">
                            <ul >
                                <li>
                                    <span class="float-left">Reseller </span> <span class="float-right">{{ data_model.reseller_name }}</span><br/>
                                </li>
                                <li>
                                    <span class="float-left">Kategori </span> <span class="float-right">{{ data_model.category }}</span><br/>
                                </li>
                                <li>
                                    <span class="float-left">Rating </span> 
                                    <div class="float-right">
                                        <span v-for="(items, idx) in data_model.rating" :key="idx" class="rating"> <i class="mdi mdi-star text-warning"></i></span>
                                    </div>
                                    <br/>
                                </li>
                                <li>
                                    <span class="float-left">Tgl.Dibuat </span> <span class="float-right">
                                        {{ $filters.formatDate(data_model.create_date, 'DD/MM/YYYY') }} - <small class="text-secondary">{{ $filters.formatDate(data_model.create_date, 'LT') }}</small>
                                    </span><br/>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-12 p-4">
                            <span for=""> Pesan</span>
                            <div class="message-box">
                                {{ data_model.message }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            }
        }
    }
</script>

<style lang="scss" scoped>
#box-info{
    padding:15px;
    background-color:#e4ffef;
}
.profiles{
    overflow-y:scroll;
    height:500px;
    padding:20px;

    img{
        position:relative;
        top:15px;
        border:1px solid whitesmoke;
        width:100%;
        border-radius:3px;
    }

    ul{
        padding:0;
        margin:0;
        li{
            width:100%;
            list-style:none;
            padding:10px;
            border-bottom:1px solid whitesmoke;
            font-size:12px;
        }
    }

    span.rating{
        width: auto;
        float:left;
        padding:3px;
    }
}

.message-box{
    margin-top:10px;
    padding:10px;
    border:1px solid rgb(237, 237, 237);
    height:200px;
    font-size:14px;
    overflow-y:scroll;
}
</style>
