<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Produk Non Digital <br/> <span class="text-secondary">Pilih salah satu untuk import data.</span></h5>
                    <a href="javascript:;" class="nav-module link-close" @click="CloseModal()"><i class="mdi mdi-close mdi-icon"></i> </a>
                </div>
                <div class="modal-body">
                    <hr/>
                    <div class="row table-filter no-border">
                        <div class="col-md-5 col">
                            <div class="form-group">
                                <SelectProductTypeNonDigital  v-on:ChooseData="FilterProductType" />
                            </div>
                        </div>
                        <div class="col-md-4 col">
                            <div class="form-group">
                                <SelectProductCategory v-on:ChooseData="FilterCategory" :type="'non-digital'" v-if="meta_filter.type == 'accessories'"/>
                                <div v-else>
                                <label for="">Kategori Produk</label>
                                <select class="form-control"><option>-- --</option></select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col">
                            <div class="form-btn">
                                <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery();"/>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-info">
                                <div class="float-left">
                                    <ul>
                                        <li class="">
                                            <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                        </li>
                                        <li class="border-left">
                                            <i class="mdi mdi-cloud-download text-success"></i> 
                                        </li>
                                    </ul>
                                </div>
                                <div class="group-search">
                                    <input type="text" class="form-control" placeholder="Cari Kode Item, Nama Produk ...." v-model="mixmeta_data.sval"/>
                                    <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    <a href="javascript:;" @click="ServiceSearchData()" class="search"><i class="mdi mdi-magnify"></i></a>
                                </div>
                            </div>
                            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                            <div class="table-cover s-table scrollbox" v-else>
                                <table :class="isRefreshTable ? 'table-load' : '' ">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th class="text-center">Photo</th>
                                            <th data-filter="sku">SKU</th>
                                            <th data-filter="kode_item">Kode Item</th>
                                            <th data-filter="type">Produk Tipe</th>
                                            <th data-filter="category_name">Category</th>
                                            <th data-filter="name">Produk</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                            <td width="60">
                                                <a href="javascript:;" class="btn btn-sm btn-light" @click="ChooseData(idx)"><i class="mdi mdi-check text-success"></i></a>
                                            </td>
                                            <td  width="110">
                                                <ImageSmCircle :photo="$filters.GetImageThumnail(items.images)" />
                                            </td>
                                            <td width="150"><span class="text-secondary">{{ items.sku }}</span></td>
                                            <td width="150">{{ items.kode_item }}</td>
                                            <td width="200">{{ items.type }}</td>
                                            <td width="200">{{ items.category_name }}</td>
                                            <td width="400">{{ items.name }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>




<script>
    export default 
    {
        name : 'ImportProductNonDigital',
        data() {
           return {
                isFirstLoad : true,
                meta_filter : {type : 'all', id_category : 'all'},

                collection : [],
                model_items : [],
            }
        },
        created() {
            this.mixmeta_data.okey = 'name';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            CloseModal(){
                this.$emit('closeModal');
            },
            ChooseData(idx)
            {
                this.$emit('applyData', this.mixmodel.data[idx]);
                this.$emit('closeModal');
            },
           
            FilterProductType(data)
            {
                this.meta_filter.type = 'all';
                if( data ){
                    this.meta_filter.type = data;
                }
            },
            FilterCategory(data)
            {
                this.meta_filter.id_category = 'all';
                if( data.id ){
                    this.meta_filter.id_category = data.id;
                }
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_non_digital/data', 
                    offset : this.page,
                    search: { key : 'global-search', value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'type', val : this.meta_filter.type},
                        {key : 'id_category', val : this.meta_filter.id_category},
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isRefreshTable = false;
                    this.isFirstLoad = false;
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>
.scrollbox{
    height:19srem;
}
.table-info{
    background-color:#f9f9f9fc;
}
</style>


