<template>
     <div class="popup-modal">
        <div class="modal-dialog modal-md" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Member</h5>
                    <button class="btn btn-sm btn-close" type="button" @click="CloseModal()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <div class="col">
                                    <span class="text-secondary">Nama Counter</span><br/>
                                    <span>{{ data_model.counter_name }}</span>
                                </div>
                                <div class="col">
                                    <span class="text-secondary">Nama Pemilik</span><br/>
                                    <span>{{ data_model.owner_name }}</span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <div class="col">
                                    <span class="text-secondary">Phone</span><br/>
                                    <span>{{ data_model.phone }}</span>
                                </div>
                                <div class="col">
                                    <span class="text-secondary">Email</span><br/>
                                    <span>{{ data_model.email }}</span>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div class="col-md-12 mt-3">
                            <ButtonProcess :class="'btn-sm float-end'" :type="'Submit'" :title="'OK, LANJUTKAN'" @click="Submit()"/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default 
    {
        name : 'DetailMember',
        props : ['data_model'],
        created(){
            console.log('hello', this.data_model);
        },
        methods:
        {
            CloseModal()
            {
                this.$emit('closeModal');
            } 
        }
    }
</script>

<style lang="scss" scoped>


</style>
