<template>
    <main>
        <ImportSupplier v-on:applyData="ImportSupplier" v-on:closeModal="CloseModal" v-if="isEnablePopup == 'supplier'"/>
        <ImportProductNonDigital v-on:applyData="ImportProduct" v-on:closeModal="CloseModal" v-if="isEnablePopup == 'product'"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Purchasing Faktur 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola faktur pembelian produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-body">
                        <form class="form-horizontal" @submit="submit">
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <label>Nama Supplier <small class="text-danger">*</small></label>
                                                <div class="box-browse">
                                                    <a href="javascript:;" class="btn btn-sm btn-light  float-right mt-2"  @click="isEnablePopup = 'supplier'">Browse Supplier</a>
                                                   <div class="info"  v-if="!model.supplier_name">
                                                        <span><i class="ti-info-alt text-info"></i> Klik "Browse Supplier" untuk menemukan data supplier.</span>
                                                    </div>
                                                    <div class="info" v-if="model.supplier_name">
                                                        <span>{{ model.supplier_name }}</span><br/>
                                                        <span class="text-secondary">{{ model.supplier_address }}</span>
                                                    </div>
                                                </div>
                                                <small class="text-danger" v-if="service_form_error.id_supplier"><br/>{{ service_form_error.id_supplier[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label>Tgl.Faktur <small class="text-danger">*</small></label>
                                                <input type="date" class="form-control" v-model="model.faktur_date" placeholder="...." id="faktur-date" >
                                                <small class="text-danger" v-if="service_form_error.faktur_date">{{ service_form_error.faktur_date[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Nomor Faktur <small class="text-danger">*</small></label>
                                                <input type="text" class="form-control"  v-model="model.faktur_number"  placeholder="...." >
                                                <small class="text-danger" v-if="service_form_error.faktur_number">{{ service_form_error.faktur_number[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div class="row">
                                <div class="col-md-12">
                                    <a href="javascript:;" class="btn btn-sm btn-light  float-right mt-3"  @click="BrowseProduct()" ><i class="mdi mdi-plus-circle text-success"></i> Tambah Produk</a>
                                    <label>Produk Detail <small class="text-danger">*</small></label><br/>
                                    <span class="text-secondary">Tambah nama produk dalam list pembelian faktur.</span>
                                </div>
                                <div class="col-md-12 mt-3" >
                                    <div class="table-cover stable">
                                        <table :class="isFirstLoad ? 'table-load' : '' ">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">--</th>
                                                    <th>SKU</th>
                                                    <th>Nama Produk</th>
                                                    <th class="text-center">Qty</th>
                                                    <th>Unit</th>
                                                    <th><span class="pr-3">Harga Beli</span></th>
                                                    <th class="text-right"><span class="pr-3">Total  ( IDR )</span></th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="model_faktur.length > 0">
                                                <tr v-for="(items, idx) in model_faktur" :key="idx">
                                                    <td class="text-center" width="20">
                                                        <a href="javascript:;" class="action-delete" @click="RemoveItem(idx)"><i class="mdi mdi-delete-circle text-danger"></i></a>
                                                    </td>
                                                    <td  width="150">
                                                        {{ model_faktur[idx].sku }}
                                                    </td>
                                                    <td width="400">
                                                        <div>{{ items.product_name }}</div>
                                                        <small class="text-danger msg-input-error" :id="'msg-input-error-'+idx"></small>
                                                    </td>
                                                    <td class="text-center" width="100">
                                                        <input type="number" class="input-mini" min="1" placeholder="0" v-model="model_faktur[idx].qty"  :id="'item-qty-'+idx" @keyup="CalculateTotalFaktur()" @change="CalculateTotalFaktur()"/>
                                                    </td>
                                                    <td width="100">pcs </td>
                                                    <td width="150" class="text-right">
                                                        <input type="number" class="input-mini item-price" min="1" placeholder="0" v-model="model_faktur[idx].price"  :id="'item-price-'+idx" @keyup="CalculateTotalFaktur()" @change="CalculateTotalFaktur()"/>
                                                    </td>
                                                    <td  class="text-right">
                                                        <b><span class="" :id="'item-price-total-'+idx">{{ $filters.formatNumber(model_faktur[idx].qty * model_faktur[idx].price) }}</span></b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6"><b>Total Pembelian</b></td>
                                                    <td  class="text-right" width="200" >
                                                        Rp <span class="text-success"><b>{{ $filters.formatNumber(total_faktur_amount) }}</b></span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td width="30"></td>
                                                    <td width="250">-</td>
                                                    <td class="text-center" width="120">-</td>
                                                    <td class="text-center" width="80">-</td>
                                                    <td width="100">-</td>
                                                    <td width="150" >-</td>
                                                    <td>-</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="text-center mt-2 mb-3">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Update Data'"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";

    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                isEnablePopup : false,
                model : [],
                model_faktur:[],
                total_faktur_amount :0,
            };
        },
        created() {
            this.DataQuery();
        },
        methods: 
        {
            CloseModal(){
                this.isEnablePopup = false;
            },
            ImportProduct(data)
            {
                this.model_faktur.push({
                    sku : data.sku,
                    product_name : data.name,
                    qty : '',
                    price : '',
                });
            },
            ImportSupplier(data)
            {
                this.model.id_supplier = data.id;
                this.model.supplier_name = data.supplier_name;
                this.model.supplier_address = data.address;
            },
            BrowseProduct()
            {
                $('.msg-input-error').text('');
                let index = this.model_faktur.length - 1;
                let amount = parseInt($('#item-price-total-'+index).text());
                if( amount < 1 ){
                    $('#msg-input-error-'+index).html('! Mohon lengkapi kolom isian qty dan harga');
                    return;
                }

                this.isEnablePopup = 'product';
            },
           
            AddItem()
            {
                $('.msg-input-error').text('');
                let index = this.model_faktur.length - 1;
                let amount = parseInt($('#item-price-total-'+index).text());
                if( amount < 1 ){
                    $('#msg-input-error-'+index).html('! Enter completely the numeric field');
                    return;
                }

                if( this.items.id_provider  !== '' && this.items.product_name !== '' )
                {
                    this.model_faktur.push({
                        id_provider : this.items.id_provider,
                        sku : '',
                        product_name : this.items.product_name,
                        qty : '',
                        price : '',
                    });

                }else{
                    alert('Silahkan pilih provider dan masukan nama produk')
                }

            },
            RemoveItem(idx)
            {
                this.model_faktur.splice(idx, 1);
            },
            
            CalculateTotalFaktur()
            {
                let total = 0;
                for(let key in this.model_faktur)
                {
                    total += this.model_faktur[key].qty * this.model_faktur[key].price
                }
                this.total_faktur_amount = total;
            },
            async DataQuery()
            {
                let query = await this.ServiceApiPost({url: 'purchasing/find', data: {id : localStorage.getItem('QUERY_ID')}});
                if(query){
                    
                    this.model_faktur = JSON.parse(query.product_items);
                    this.model = query;
                    this.isFirstLoad = false;

                    setTimeout(() => {
                        this.CalculateTotalFaktur();
                    }, 300);
                }
            },
            async submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                 // validate input
                $('.msg-input-error').text('');
                for(let key in this.model_faktur){
                    let amount = parseInt($('#item-price-total-'+key).text());
                    if(amount < 1){
                        $('#msg-input-error-'+key).html('! Enter completely the numeric field');
                        return;
                    }
                }  

                var fdate = document.getElementById('faktur-date').value;
                let dataStore = {
                    id : this.model.id,
                    id_supplier : this.model.id_supplier,
                    faktur_number : this.model.faktur_number,
                    faktur_date : fdate == '' ? '' : moment(fdate).format("YYYY/MM/DD"),
                    product_items : this.model_faktur
                }   
                
                let res = await this.ServiceApiPost({ action : 'post', url: 'purchasing/update', data :dataStore, callback_url : 'purchasing-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
.action-delete{
    font-size:20px;
}
.input-mini{
    width:90%;
    border:1px solid #c1c1c1;
    padding:3px;
    border-radius:3px;
}
.box-browse{
    float:left;
    width:100%;
    height:105px;
    padding:30px;
    background-color:#ffffff4f;
    border:1px solid #ef3f312b;
    .info{
        float:left;
        width:70%;
        border-right:1px dashed #dcdcdc;
        padding-right:10px;
    }
}

.table{
    tbody{
        tr > td{
            border:1px solid #c4c4c4;
        }
    }
}
</style>