import ButtonProcess from './parts/ButtonProcess';
import ImageMdCircle from './parts/ImageMdCircle';
import ImageSmCircle from './parts/ImageSmCircle';
import UploadSingleImage from './parts/UploadSingleImage';
import UploadSingleVideo from './parts/UploadSingleVideo';
import LoaderBar from './parts/LoaderBar';
import GoogleMap from './parts/GoogleMap';
import LeafletMap from './parts/LeafletMap';
import SystemConnection from './parts/SystemConnection';
import ColorPicker from './parts/ColorPicker';
import Loader from './parts/Loader';
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

// Api
import Notification from './api/Notification';
import SelectPrivileges from "./api/SelectPrivileges";
import SelectIdentity from "./api/SelectIdentity";
import SelectProvider from "./api/SelectProvider";
import SelectProductCategory from "./api/SelectProductCategory";
import SelectProductSubCategory from "./api/SelectProductSubCategory";
import SelectLevelCode from "./api/SelectLevelCode";
import SelectStatusCustomer from "./api/SelectStatusCustomer";
import SelectProductTypeNonDigital from  "./api/SelectProductTypeNonDigital";
import ImportSupplier from "./api/ImportSupplier";
import ImportPromo from "./api/ImportPromo";
import ImportProductNonDigital from "./api/ImportProductNonDigital";
import ImportProductDigital from "./api/ImportProductDigital";
import DetailPurchasingFaktur from "./api/DetailPurchasingFaktur";

import SqlCommand from "./api/SqlCommand";
import DetailMember from "./api/DetailMember";
import CutoffDetail from "./api/CutoffDetail";

import Download from "./api/Download";



export default [
    SystemConnection,
    SelectPrivileges,
    SelectIdentity,
    SelectProvider,
    SelectProductCategory,
    SelectProductSubCategory,
    SelectLevelCode,
    SelectStatusCustomer,
    SelectProductTypeNonDigital,
    VPagination,
    ButtonProcess,
    ImageMdCircle,
    ImageSmCircle,
    UploadSingleImage,
    UploadSingleVideo,
    LoaderBar,
    GoogleMap,
    LeafletMap,
    ColorPicker,
    Loader,
    Notification,
    ImportSupplier,
    ImportPromo,
    DetailPurchasingFaktur,
    ImportProductNonDigital,
    ImportProductDigital,
    DetailMember,
    SqlCommand,
    CutoffDetail,
    Download
];