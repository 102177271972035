<template>
     <div class="popup-modal">
        <div class="modal-dialog modal-xl" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Produk Promo</h5>
                    <button class="btn btn-sm btn-close" type="button" @click="CloseModal()"></button>
                </div>
                 <div v-if="isFirstLoad" class="page-loader page-loader-blank">
                    <lottie-player src="assets/img/lotties/72785-searching-orange.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/>
                    <span class="text-secondary">Searching for product, Please Wait ...</span>
                </div>
                <div class="modal-body" v-else>
                    <div className="row">
                        <div class="col-md-12 mb-3">
                            <div class="table-search mt-4">
                                <a href="javascript:;" class=" ml-2">
                                    <div class="input-group input-group-joined input-group-solid">
                                        <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                        <div class="input-group-text">
                                            <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                            <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                        </div>
                                        <div class="box" v-if="mixsearch">
                                            <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                            <label class="text-secondary">Pilih Target Pencarian</label>
                                            <select v-model="mixmeta_data.skey">
                                                <option value="">-- Query Search Field --</option>
                                                <option value="kode">Kode Operator</option>
                                                <option value="nama">Nama Produk</option>
                                            </select>
                                            <div class="group-input">
                                                <label class="text-secondary">Masukan Keyword Pencarian</label>
                                                <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                                <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                                <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                            </div> 
                                        </div>
                                    </div>
                                </a>  
                            </div>
                        </div>
                        <div class="table-responsive scrollbox" v-if="mixmodel.total > 0">
                           <table class="table s-table">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter">Action</th>
                                        <th data-filter="title">Title</th>
                                        <th >Nama Produk</th>
                                        <th >Harga Jual</th>
                                        <th data-filter="created_at">Tgl.Dibuat</th>
                                        <th data-filter="updated_at">Tgl.Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="$store.state.loading.status">
                                        <td colspan="7">
                                            <div id="loader-table"><span></span><small></small></div>
                                        </td>
                                    </tr>
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td  class="text-center" width="120">
                                            <a href="javascript:;" class="btn btn-sm btn-light" @click="ChooseData(idx)"  data-dismiss="modal">Pilih Item</a>
                                        </td>
                                        <td>{{ items.title }}</td>
                                        <td width="350">
                                            <span class="text-secondary">Kode Operator : {{ items.product_operator_code }}</span><br/>
                                            {{ items.product_name }}
                                        </td>
                                        <td>Rp {{ $filters.formatNumber(items.selling_price) }}</td>
                                        <td>{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        <td>
                                            <span v-if="items.updated_at !== null">
                                                {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.updated_at, 'LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                        <td>
                                            <span v-if="items.publish_at !== null">
                                                {{ $filters.formatDate(items.publish_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.publish_at, 'LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default 
    {
        name : 'ImportPromo',
        data() {
           return {
                isFirstLoad : true,
                error_msg: '',
                collection : [],
                model_items : [],
                fid_provider : 'all',
            }
        },
        created() 
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:    
        {
            CloseModal(){
                this.$emit('closeModal');
            },
            ChooseData(idx)
            {
                console.log( '--', this.mixmodel.data[idx] );
                this.$emit('applyData', this.mixmodel.data[idx]);
                this.$emit('closeModal');
            },
           
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;

                let res = await this.ServiceApiDataPaging({
                    url:'promo/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>
.scrollbox{
    height:30rem;
}
</style>