<template>
    <div>
        <label>Status Member <small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select  class="form-control" v-model="selected"  @change="ChooseData()" >
            <option value="all" v-if="mode == 'form'">--  --</option>
            <option value="all" v-else>-- Semua Status --</option>
            <option value="">NULL</option>
            <option value="register">Register</option>
            <option value="member">Member</option>
            <option value="block">Block</option>
            <option value="delete">Delete</option>
            <option value="pairing">Pairing</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectStatusCustomer',
    props : ['mode', 'id'],
    
    data() {
        return {
            selected : 'all',
        }
    },
    created(){
        if( this.id ){
            this.selected = this.id;
        }
    },
    methods:
    {
        
        ChooseData()
        {
            this.$emit('ChooseData', this.selected)
        }
    }
}
</script>