<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-xl" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Log.History Harga</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div v-if="isFirstLoad" class="page-loader page-loader-blank">
                    <lottie-player src="assets/img/lotties/72785-searching-orange.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/>
                    <span class="text-secondary">Searching for product, Please Wait ...</span>
                </div>
                <div class="modal-body" v-else>
                    <div class="row">
                        <div class="col-xl-12">
                            <ul class="data-list ">
                                <li>
                                    <div class="float-end ml-2 pt-2">
                                        <span> <b class="text-danger">{{ data_model.author_name }}</b></span><br/>
                                        <span>{{ data_model.author  }}</span>
                                    </div>
                                        <div class="float-end">
                                        <ImageSmCircle :photo="data_model.photo_owner" v-if="data_model.photo_owner !== null"/>
                                        <div class="avatar avatar-xl" v-else>
                                            <img class="avatar-img img-fluid" src="assets/img/avatar.png" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <hr/>
                            <div class="table-filter">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group mr-2 float-start">
                                            <label>Tipe Produk</label>
                                            <select class="form-control"  v-model="ftype" @change="DataQuery()">
                                                <option value="voucher">Voucher</option>
                                                <option value="digital">Digital</option>
                                            </select>
                                        </div>
                                        <div class="form-group mr-2 float-start">
                                            <SelectProvider v-on:ChooseData="ChooseProvider"/>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="table-search mt-4">
                                            <a href="javascript:;" class="">
                                                <div class="input-group input-group-joined input-group-solid">
                                                    <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                                    <div class="input-group-text">
                                                        <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                                        <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                                    </div>
                                                    <div class="box" v-if="mixsearch">
                                                        <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                                        <label class="text-secondary">Pilih Target Pencarian</label>
                                                        <select v-model="mixmeta_data.skey">
                                                            <option value=""> -- --</option>
                                                            <option value="product.sku">SKU Produk</option>
                                                            <option value="product.name">Nama Produk</option>
                                                        </select>
                                                        <div class="group-input">
                                                            <label class="text-secondary">Masukan Keyword Pencarian</label>
                                                            <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                                            <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                                            <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div class="" v-if="mixmodel.total > 0">
                                <div class="row scrollbox">
                                    <div class="col-md-5 pr-0">
                                        <div class="">
                                            <table class="table s-table table-product">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2" class="border-right">Produk Market</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="border-right border-top">SKU</th>
                                                        <th class="border-right border-top">Nama Produk</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                        <td width="120"><div class="con-width con-title"><span class="title-global">{{ $filters.ucwords(items.sku) }}</span></div></td>
                                                        <td><div class="con-width con-title"><span class="title-global">{{ $filters.ucwords(items.name) }}</span></div></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="float-start">
                                            <span class="text-secondary">Produk Records  {{ mixmodel.current_page }} &nbsp;/&nbsp; {{ mixmodel.last_page }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 pl-0" style="position:relative;">
                                        <div class="table-price" id="table-price">
                                            <div class="">
                                                <table class="table s-table ">
                                                    <thead>
                                                        <tr>
                                                            <template v-for="(fk, id_fk) in data_faktur" :key="id_fk">
                                                                <th colspan="2" :class="(id_fk % 2) == 0 ? 'text-center bg-ganjil' : 'text-center bg-genap'" width="60"># {{ fk.faktur_number }}</th>
                                                            </template>
                                                        </tr>
                                                        <tr class="border-top">
                                                            <template v-for="(items, idx) in data_faktur" :key="idx">
                                                                <th class="text-center bg-buy">Beli</th>
                                                                <th class="text-center bg-sell">Jual</th>
                                                            </template>
                                                        </tr>
                                                    </thead>
                                                    <tbody v-if="!isLoadFaktur">
                                                        <tr v-for="(dt, idx_dt) in mixmodel.data" :key="idx_dt">
                                                            <template v-for="(set, idx_set) in data_faktur" :key="idx_set">
                                                                <td>
                                                                    <div class="con-width text-center pt-2"><span>{{  GetDataFaktur('price', dt.sku, set.product_items) > 0 ? $filters.formatNumber(GetDataFaktur('price', dt.sku, set.product_items))  : '--'}}</span></div>
                                                                </td>
                                                                <td>
                                                                    <div class="con-width text-center pt-2">
                                                                        <span  v-if="GetDataFaktur('selling_price', dt.sku, set.product_items) > 0">{{ GetDataFaktur('selling_price', dt.sku, set.product_items) }}</span>
                                                                        <span class="text-secondary" v-else>--</span>
                                                                    </div>
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="text-secondary float-start"><i class="fas fa-arrows-alt-h"></i> Scroll horizontal untuk melihat data lainya.</span>
                                            <span class="text-secondary float-end">Faktur Records  {{ model_faktur.current_page }} &nbsp;/&nbsp; {{ model_faktur.last_page }}</span>
                                        </div>
                                        <div class="navigation-table navleft">
                                            <a href="javascript:;" @click="QueryFaktur('prev')"><i class="fas fa-chevron-left"></i></a>
                                        </div>
                                        <div class="navigation-table navright">
                                            <a href="javascript:;" @click="QueryFaktur('next')"><i class="fas fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <v-pagination
                                    v-if="mixmodel.total > 0"
                                    v-model="page"
                                    :class="'pagination'"
                                    :pages="parseInt(mixmodel.last_page)"
                                    :range-size="1"
                                    active-color="#ffffff"
                                    @update:modelValue="DataQuery"
                                />
                            </div>
                            <div class="page-loader page-loader-blank" v-else>
                                <lottie-player src="assets/img/lotties/not-found.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/><br/>
                                <span class="text-secondary">Opps, Sorry data not found !</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,

                fid_provider : 'all',
                ftype : 'voucher',
                model : {},
                data_faktur : [],
                model_faktur : [],
                offset_fkpage : 1,

            };
        },
        created()
        {
            console.log('--', this.data_model);
            this.ServiceInitFunction('DataQuery', 's-table');
            this.QueryFaktur();
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            GetDataFaktur(initial, sku, product_items)
            {
                let model = JSON.parse(product_items);
                for(let key in model){
                    if(model[key].sku == sku){
                        if( initial == 'price' ){
                            return model[key].price;
                        }
                        if( initial == 'selling_price' ){
                            return model[key].selling_price ? model[key].selling_price : 0;
                        }
                    }
                }
                return 0;
            },
            ChooseProvider(data)
            {
                this.fid_provider = data ? data.id : 'all';
                this.DataQuery();
            },
            
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'product.type', val : this.ftype},
                        {key : 'provider.id', val : this.fid_provider},
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                }
            }, 
            async QueryFaktur(initial)
            {
                let validate = false;
                if(initial)
                {
                    if(initial == 'next' )
                    {
                        if(this.offset_fkpage <  this.model_faktur.last_page){
                            this.offset_fkpage += 1;
                            validate = true;
                        }
                        document.getElementById('table-price').scrollLeft += 600;
                    }

                    if(initial == 'prev'){
                        document.getElementById('table-price').scrollLeft -= 600;
                    }

                }else{
                    validate = true;
                }
                
                if(validate){

                    let res = await this.ServiceApiDataPaging({
                        url:'price/log-detail', 
                        offset : this.offset_fkpage,
                        order: { key : 'id', value : 'desc' },
                        filter : [
                            {key : 'log_product_price.author', val : this.data_model.author},
                            {key : 'log_product_price.update_code', val : this.data_model.update_code},
                        ]
                    });
                    if(res){ 

                        this.model_faktur = res.query;

                        let faktur = this.model_faktur.data;
                        faktur.reverse();
                        for(let key in faktur){
                            this.data_faktur.push( faktur[key] );
                        }

                        this.isFirstLoad = false;
                        setTimeout(function(){
                            document.getElementById('table-price').scrollLeft += 600;
                        }, 200)
                    }
                }
            },
           
        }
    }
</script>

<style lang="scss" scoped>

.table-search .input-group-joined{
    width:100%;
}
.scrollbox, #loader-table{
    height:30em;
}

.bg-buy{background-color:#f5e5e5;}
.bg-sell{background-color:#e1f7e1;}
.bg-ganjil{background-color:#80808008;}
.bg-genap{background-color:#e1e1e1;}

.con-width{
    height:40px;
    width:100px;
    .title-global{
        font-size:14px;
        text-transform: capitalize;
    }
}
.con-title{
    width:100%;
}
table{
    border:1px solid whitesmoke;
    tr{
        td{
            height:44px;
        }
    }
}
.input-mini
{
    padding:5px;
    float:right;
    width:100%;
}
.table-product{
    float:left;
    width:100%;
    tr{
        th{border-right:1px solid #ececec;}
        td{border-right:1px solid #ececec;}
    }
}
.table-price{
    position:relative;
    scroll-behavior: smooth;
    table{
        max-width:2500px;
    }
    float:left;
    width:100%;
    overflow-x:scroll;
    tr{

        th{border-right:1px solid #ececec;}
        td{border-right:1px solid #ececec; height:43px;}
    }
}

.navigation-table{
    position:absolute;
    padding:11px;
    background-color:#ffffffad;
    z-indeX:2;
    text-align:center;
    i{
        font-size:20px;
        color:gray;
        position:relative;
        top:2px;
    }
}
.navleft{
    left:0;
}
.navright{
    right:12px;
}
#loader-faktur{
    height:200px;
    width:100%;
    text-align:center;
    padding:20px;
}
</style>