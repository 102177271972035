<template>
    <main>
        <BrowseSales v-on:closePopup="closeBrowseSales"  v-if="isEnablePopup == 'browse-sales'"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Harga Produk Reseller 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola harga promo pada produk digital.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row" >
                            <div class="col-md-8"  v-if="$store.state.privileges_modules.uam.includes('filter_reseller')">
                                <div class="card-profile-tab  mt-2">
                                    <a href="javascript:;" @click="isActiveFilterTab = 'search'" :class="isActiveFilterTab == 'search' ? 'active' : ''">Search</a>
                                    <a href="javascript:;" @click="isActiveFilterTab = 'input'" :class="isActiveFilterTab == 'input' ? 'active' : ''">Input</a>
                                </div>
                                <div class="card-profile" >
                                    <ul v-if="isActiveFilterTab == 'search'">
                                        <li  v-if="model_upline.name">
                                            <ImageSmCircle :photo="model_upline.photo_selfi_id" />
                                        </li>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="mb-0">{{ model_upline.name ? model_upline.name :  'Semua Reseller' }}</span><br/>
                                                <span class="text-secondary">{{ model_upline.code_otmx_reseller ? model_upline.code_otmx_reseller + '-' +model_upline.level_code :  'Gunakan "Filter Reseller" untuk melihat transaksi reseller.'}} </span>
                                            </div>
                                            <div class="float-left border-left" v-if="model_upline.name">
                                                <span class="mb-0"><b>Status Member</b></span><br/>
                                                <span class="text-secondary">{{ model_upline.status !== null ? 'Tervalidasi : ' + model_upline.status :  '! Data belum tervalidasi.'}} </span>
                                            </div>
                                            <div class="float-left border-left pt-2" >
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Reseller'"  @click="isEnablePopup = 'browse-sales'"/>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul v-else>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="text-secondary">Ketikan "Kode Reseller" untuk melihat transaksi reseller</span>
                                                <input type="search" class="form-control" placeholder="..." v-model="input_code_reseller"/>
                                            </div>
                                            <div class="float-left border-left pt-3" >
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Reseller'"  @click="InputFilterReseller()"/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <ul class="data-list">
                                    <li>
                                        <span class="float-left">Total Kategori</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryProduct"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(model_product.category) }}</span>
                                    </li>
                                    <li>
                                        <span class="float-left">Total Sub-Kategori</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryProduct"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(model_product.sub_category) }}</span>
                                    </li>
                                    <li>
                                        <span class="float-left">Total Produk</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryProduct"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(model_product.product) }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div class="card-body">
                        <!-- component filter table -->
                        <div v-if="isEnableFilter">
                            <div class="slide-right-filter" >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau dending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="product.id">ID</option>
                                            <option value="customer_register.counter_name">Reseller</option>
                                            <option value="customer_register.code_otmx_reseller">Kode Reseller</option>
                                            <option value="product_category.category_name">Kategori</option>
                                            <option value="product_subcategory.name">Sub-Kategori</option>
                                            <option value="product.name">Nama Produk</option>
                                            <option value="product.selling_price">Harga</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="product.id">ID</option>
                                            <option value="customer_register.counter_name">Reseller</option>
                                            <option value="customer_register.code_otmx_reseller">Kode Reseller</option>
                                            <option value="product_category.category_name">Kategori</option>
                                            <option value="product_subcategory.name">Sub-Kategori</option>
                                            <option value="product.name">Nama Produk</option>
                                            <option value="product.selling_price">Harga</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th :class="mixmeta_data.okey == 'product_category.category_name' ? 'active' : ''">Kategori</th>
                                        <th :class="mixmeta_data.okey == 'product_subcategory.name' ? 'active' : ''">Sub-Kategori</th>
                                        <th :class="mixmeta_data.okey == 'product.name' ? 'active' : ''">Produk</th>
                                        <th :class="mixmeta_data.okey == 'product.selling_price' ? 'active' : ''">Harga ( IDR )</th>
                                    </tr>
                                </thead>
                                <tbody  v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="200">{{ items.category_name }}</td>
                                        <td width="200">{{ items.subcategory_name }}</td>
                                        <td >
                                            {{ items.name }}<br/>
                                            <span class="text-secondary">{{ items.product_code }}</span>
                                        </td>
                                        <td width="100">
                                            Rp {{ $filters.formatNumber(items.price) }} 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import BrowseSales from '../Resellers/BrowseSales';
    export default {
        components: {
            BrowseSales,
        },
        data() {
            return {
                isFirstLoad : true,
                isEnableFilter : false,
                isEnablePopup : false,
                isActiveFilterTab : 'search',
                model_upline : {},
                isLoadSummaryProduct : true,
                freseller : localStorage.getItem('user-code'),
                fprivileges : localStorage.getItem('user-privileges'),
                input_code_reseller : '',

            }
        },
        created()
        {
            this.mixmeta_data.okey = 'product.id';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
            this.QuerySummaryProduct();
        },
        methods:
        {
            
            InputFilterReseller()
            {
                if( this.input_code_reseller == '' ){
                    alert('Silahkan masukan kode reseller.');
                    return;
                }
                this.freseller = this.input_code_reseller;
                this.DataQuery();
            },
            closeBrowseSales(data)
            {
                this.isEnablePopup = false;
                if(data)
                {
                    this.freseller = data.code_otmx_reseller;
                    this.DataQuery();
                }
            },
           
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_digital/price/'+this.freseller, 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                   
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.model_upline = res.reseller;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }, 

            async QuerySummaryProduct()
            {
                this.isLoadSummaryProduct = true;
                let res = await this.ServiceApiPost({
                    url:'product_digital/summary', 
                });
                if(res && res.result){ 
                    this.model_product = res.query;
                    this.isLoadSummaryProduct = false;
                }
            },
           
        }
    }
</script>