import axios from 'axios';


// axios configure base url
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = process.env.VUE_CONFIG_REQUEST_TIMEOUT;


// axios manage request
axios.interceptors.request.use(
    
    (config) => 
    {
        if( config.url !== 'auth/login' )
        {
            config.headers['Accept'] = 'application/json'; 
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt-token'); 
        }
        return config;
    }, 
    (error) => {
        return Promise.reject(error);
    },
); 


// axios manage response
axios.interceptors.response.use(response => {
    if( response.data.status == 'token_expired' )
    {
        localStorage.clear();
        window.location.href = '/';
    }
    return response;
});

