<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Group Produk 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data produk digital berdasarkan group operator.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="alert alert-light" role="alert">
                            <h4><i class="mdi mdi-information-outline text-info"></i> <span>Informasi</span></h4>
                            <hr/>
                            <p>
                                - Data yang ditampilkan berdasaran group produk.<br/>
                                - Klik sub-produk untuk melihat detail produk
                            </p>
                        </div>
                       
                    </div>
                    <div class="card-body">
                        <!-- component filter table -->
                        <div v-if="isEnableFilter">
                            <div class="slide-right-filter" >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="product.id">ID</option>
                                            <option value="operator.kode">Kode Operator</option>
                                            <option value="operator.nama">Produk Operator</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="operator.kode">Kode Operator</option>
                                            <option value="operator.nama">Produk Operator</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'product_subcategory.created_at' || mixmeta_data.skey == 'product_subcategory.updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="col-md-12">
                            <div class="table-info">
                                <div class="float-right">
                                    <ul>
                                        <li>
                                            <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                        </li>
                                        <li class="border-left">
                                            <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th>Kategori</th>
                                        <th>Sub-Kategori</th>
                                        <th :class="mixmeta_data.okey == 'operator.kode' ? 'active' : ''">Kode Operator</th>
                                        <th :class="mixmeta_data.okey == 'operator.nama' ? 'active' : ''">Produk Operator</th>
                                        <th class="no-filter">Sub Produk</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="250">{{ items.category_name }} </td>
                                        <td width="200">{{ items.subcategory_name }}</td>
                                        <td width="150">{{ items.kode }}</td>
                                        <td>{{ items.nama }}</td>
                                        <td width="150">
                                            <a href="javascript:;" class="btn btn-sm btn-light" @click="DataListing(items.kode, items.nama)">
                                                <span class="text-danger">{{ $filters.formatNumber(items.total_listing) }}</span>
                                                Sub-Produk
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    
    export default {
        data() {
            return {
                isEnableFilter : false,
                isFirstLoad : true,
                model : { id_product_subcategory : 0 },
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'kode';
            this.mixmeta_data.oval = 'DESC';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            DataListing(code, name)
            {
                localStorage.setItem('QUERY_ID', code);
                localStorage.setItem('QUERY_NAME', name);
                this.$router.push({name : 'product_digital-data'});
            },
          
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;

                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_digital/operator-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'listing_status', val : 'yes'},
                    ]
                });
                if(res){ 

                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }, 
           
        }
    }
</script>

<style lang="scss" scoped>
.btn{
    .mdi-gesture-double-tap{
        font-size:19px;
        color:#b7b7b7;
    }
}
</style>