<template>
    <div>
        <label><i class="spinner-mini" v-if="isLoading"></i> Privileges Group  <small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select  class="form-control" v-model="selected"  @change="ChooseData()" >
            <option value="all" v-if="mode == 'form'">--  --</option>
            <option value="all" v-else>-- Semua Group Privileges --</option>
            <option v-for="(items, idx) in model" :key="idx" :value="idx">{{ items.name }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectPrivileges',
    props : ['mode', 'id'],
    
    data() {
        return {
            isLoading : false,
            selected : 'all',
            model : []
        }
    },
    created(){
        this.DataQuery();
    },
    methods:
    {
        async DataQuery()
        {
            this.isLoading = true;
            const query = await this.ServiceApiPost({url:'privileges/data/all'});
            if(query){ 
                this.model = query; 
                this.isLoading = false;

                if(this.id){
                    for(let key in this.model){
                        if(this.model[key].id == this.id){
                            this.selected = key;
                        }
                    }
                }
            }
        }, 
        ChooseData()
        {
            let model = this.model[this.selected];
            this.$emit('ChooseData', model)
        }
    }
}
</script>

