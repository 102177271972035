<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Master Produk
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data produk digital.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <router-link :to="{ name: 'product_digital_group-data' }" class="nav-module" ><i class="mdi mdi-arrow-left mdi-icon"></i></router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header" v-if="operator_code !== 'all'">
                        <div class="alert alert-light alert-dismissible fade show" role="alert">
                            <h4><i class="mdi mdi-information-outline text-danger"></i> <span>Informasi</span></h4>
                            <hr/>
                            <p>
                                Seluruh data produk yang ditampilkan berdasarkan kode operator ( <b class="text-danger">{{ operator_code +'-'+ operator_name }}</b> )
                            </p>
                        </div>
                    </div>
                            
                    <div class="card-body">
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover"  v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th data-filter="product.operator_code">Kode Operator</th>
                                        <th data-filter="product_category.category_name">Kategori</th>
                                        <th data-filter="product_subcategory.name">Sub-Kategori</th>
                                        <th data-filter="product.product_code">Kode Produk</th>
                                        <th data-filter="product.name">Nama Produk</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="130"><i class="mdi mdi-tag-plus text-success"></i> {{ items.operator_code }}</td>
                                        <td width="150">{{ items.category_name }}</td>
                                        <td width="150">{{ items.subcategory_name }}</td>
                                        <td width="150">{{ items.product_code }}</td>
                                        <td>{{ items.name }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    
    export default {
        data() {
            return {
                isFirstLoad : true,

                operator_code : localStorage.getItem('QUERY_ID'),
                operator_name : localStorage.getItem('QUERY_NAME'),
                model : { id_product_subcategory : 0 },
            }
        },
        created()
        {
            // Global Search
            if( localStorage.getItem('global-search') )
            {
                let model = JSON.parse(localStorage.getItem('global-search'));
                this.mixmeta_data.skey = model.search_key;
                this.mixmeta_data.sval = model.search_val;
                this.operator_code = 'all';
                localStorage.removeItem('global-search');
            }

            this.mixmeta_data.okey = 'id';
            this.mixmeta_data.oval = 'DESC';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_digital/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'operator_code', val : this.operator_code},
                    ]
                });
                if(res){ 

                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }, 
           
        }
    }
</script>
