<template>
     <div class="popup-modal">
       <div class="modal-dialog modal-md" >
           <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Faktur Pembelian </h5>
                    <a href="javascript:;" class="nav-module link-close" @click="CloseModal()"><i class="mdi mdi-close mdi-icon"></i> </a>
                </div>
                <div class="modal-body">
                    <div className="row">
                        <div class="col-md-12 mt-4">
                            <div class="supplier" v-if="!isFirstLoad">
                                <span class=""><b>Faktur No</b> : {{ model.faktur_number }}</span><br/>
                                <span class=""><b>Faktur Date</b> : {{ $filters.formatDate(model.faktur_date, 'DD/MM/YYYY') }}</span><br/>
                                <span class=""><b>Supplier</b> : {{ model.supplier_name }}</span>,  <span class="text-secondary">{{ model.supplier_address }}</span>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="s-table table-cover">
                                <table :class="isFirstLoad ? 'table-load' : '' ">
                                    <thead>
                                        <tr>
                                            <th class="no-filter">Nama Produk</th>
                                            <th class="no-filter text-center">Qty</th>
                                            <th class="no-filter">Harga Beli</th>
                                            <th class="text-right">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(items, idx) in model_faktur" :key="idx">
                                            <td width="400">{{ items.product_name }}</td>
                                            <td class="text-center" width="150">
                                                <span :id="'item-qty-'+idx">{{ model_faktur[idx].qty }} pcs</span>
                                            </td>
                                            <td width="150">
                                                <span class="item-price" :id="'item-price-'+idx">{{ $filters.formatNumber(model_faktur[idx].price) }}</span>
                                            </td>
                                            <td class="text-right"><span class="" :id="'item-price-total-'+idx">Rp {{ $filters.formatNumber(model_faktur[idx].qty * model_faktur[idx].price) }}</span></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"><b>Total Pembelian</b></td>
                                            <td class="text-right" width="200">
                                                Rp <b>{{ $filters.formatNumber(total_faktur_amount) }}</b>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr/>
                        <div class="col-md-8 mt-3" v-if="$store.state.privileges_modules.uam.includes('validate')  && model.status == 'draft'" >
                            <span class="text-secondary">Mohon periksa ulang sebelum melakukan validasi faktur. Data yang sudah di validasi tidak dapat di edit !</span>
                        </div>
                        <div class="col-md-4 mt-4 text-right" v-if="$store.state.privileges_modules.uam.includes('validate')  && model.status == 'draft'" >
                            <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Validasi Faktur'" @click="Submit()"/>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default 
    {
        name : 'DetailPurchasingFaktur',
        props : ['id'],
        data() {
            return {
                isSubmitProcess : false,
                isFirstLoad : true,
                total_faktur_amount: 0,
                model_faktur: {},
                model : {}
            };
        },
        created() 
        {
            if( this.id ){
                this.DataQuery();
            }
        },
        methods:
        {
            CloseModal()
            {
                this.$emit('closeModal');
            },
            CalculateTotalFaktur()
            {
                let total = 0;
                for(let key in this.model_faktur)
                {
                    total += this.model_faktur[key].qty * this.model_faktur[key].price
                }
                this.total_faktur_amount = total;
            },
            async DataQuery()
            {
                let query = await this.ServiceApiPost({url: 'purchasing/find', data: {id : this.id }});
                if(query)
                {
                    this.model_faktur = JSON.parse(query.product_items);
                    this.model = query;
                    this.isFirstLoad = false;

                    setTimeout(() => {
                        this.CalculateTotalFaktur();
                    }, 300);
                }
            }, 
            async Submit()
            {
                this.isSubmitProcess = true;
                
                let res = await this.ServiceApiPost({ action : 'post', url: 'purchasing/approve', data:{id : this.model.id} });
                if(res && res.result){
                    this.isSubmitProcess = false;
                    this.$emit('closeModal', 'refresh');
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>

.coloring{
    position:relative;
    float:left;
    width:100%;
    small{
        position:relative;
        top:5px;
        float:left;
        margin-left:5px;
        color:gray;
    }
    a.circle-color{
        display:block;
        float:left;
        i{
            width:20px;
            height:20px;
            border-radius:20px;
            -moz-border-radius:20px;
            -webkit-border-radius:20px;
            float:right;
            background-color:#000;
            padding:12px;
            cursor:pointer;
            border:1px solid #ececec;
        }
    }
}

.supplier{
    margin-top:10px;
    padding-top:15px;
    border-top:1px solid rgb(222, 222, 222);
    span{
        line-height:25px;
        font-size:13px;
    }
}
</style>
