<template>
    <div class="circle-images">
        <img :src="path_assets + photo" v-if="photo && photo !== null">
        <div class="img" v-else><i class="mdi mdi-image-filter"></i></div> 
    </div>
</template>

<script>
export default {
    name: 'ImageSmCircle',
    props: ['photo', 'initial', 'path'],
    data() {
        return {
            isFirstLoad : true,
            path_assets : this.$store.state.api_resource
        }
    },
    created()
    {
        if(this.path){
            this.path_assets = this.path;
        }
    },
}
</script>

<style lang="scss" scoped>

.circle-images{
    width:50px;
    height:50px;
    border-radius:50px;
    -webkit-border-radius:50px;
    -moz-border-radius:50px;
    margin:0 auto;
    overflow:hidden;
    border:3px solid #dddddd;
    text-align:center;
    
    img{
        width:auto;
        height:100%;
    }
    .img{
        i{
            position:relative;
            top:13px;
            font-size:20px;
            color:#da9b8d;
        }
    }

    .avatar-xxxl{
        margin-right:15px;
        margin-left:7px;
        img{
            position:relative;
            top:7px;
            height:4.5rem !important;
        }
    }
}
</style>