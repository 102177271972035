<template>
    <main>
        <header class="">
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Broadcast Pesan
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola konten pesan singkat ( pendek ) yang akan di broadcast ke aplikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="form-group">
                                <label class=" p-0">Simpan ke Outbox  <small class="text-danger">*</small></label>
                                <small>Pilih "Yes" jika ingin menyimpan pesan broadcast pada aplikasi dan pilih "No" jika tidak ingin menyimpan pesan broadcast</small>
                                <select class="form-control" v-model="model.save_outbox">
                                    <option value="yes">Yes</option>
                                    <option value="yes">No</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.save_outbox">{{ service_form_error.save_outbox[0] }}</small>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col">
                                        <SelectLevelCode v-on:ChooseData="ChooseLevelCode" :strict="'true'"  :id="model.target_level" v-if="isFinishLoad"/>
                                        <small class="text-danger" v-if="service_form_error.target_level">{{ service_form_error.target_level[0] }}</small>
                                    </div>
                                    <div class="col">
                                        <label class="">Tanggal Blasting <small class="text-danger">*</small></label>
                                        <input type="date" id="startDate" class="form-control" v-model="model.execute_date" />
                                        <small class="text-danger" v-if="service_form_error.execute_date">{{ service_form_error.execute_date[0] }}</small>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="form-group">
                                <label class=" p-0">Judul  <small class="text-danger">*</small></label>
                                <input type="text" class="form-control"  placeholder="...." v-model="model.title">
                                <small class="text-danger" v-if="service_form_error.title">{{ service_form_error.title[0] }}</small>
                            </div>
                            <div class="form-group mt-4">
                                <label>Pesan ( Deskripsi ) <small class="text-danger">*</small></label>
                                <textarea class="form-control" rows="10" v-model="model.message"></textarea>
                                <small class="text-danger" v-if="service_form_error.message">{{ service_form_error.message[0] }}</small>
                            </div> 
                            <div class="form-group mt-5 text-center">
                                <a href="javascript:;" class="btn btn-sm btn-light mr-3" @click="$router.go(-1)">CANCEL</a>
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Perubahan'" @click="Save()"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";

    export default 
    {   
        data() {
            return {
                isFinishLoad : false,
                isSubmitProcess : false,
                model: {},
                model_digital : {}
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
           
            ChooseLevelCode(level_code)
            {
                this.model.target_level = level_code;
            },
            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'broadcast_msg/find', data: {id : localStorage.getItem('QUERY_ID')}});
                if(res)
                {
                    this.model = res.data;
                    $('.ql-editor').html(this.model.message);

                    // model_digital
                    this.model.execute_date = moment(String(this.model.execute_date)).format('YYYY-MM-DD'); 
                    this.isFinishLoad = true;
                }
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                // Execute Date
                var exdate = document.getElementById('startDate').value;
                if( exdate !== '' ){
                    this.model.execute_date = moment(exdate).format("DD/MM/YYYY HH:mm:ss");
                }

                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'broadcast_msg/update', data :this.model, callback_url : 'broadcast_msg-data' });
                if(res)
                {
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
<style>
.groupbox{
    background-color:#fafafa;
    border:1px solid #ececec;
    padding:10px;
    float:left;
    width:100%;
}
</style>