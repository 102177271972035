<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Berita Informasi ( News ) 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola konten blog berita / informasi pada aplikasi mobile.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="form-group">
                                <label class="">Target Aplikasi Mobile <small class="text-danger">*</small></label>
                                <select class="form-control col-md-6" v-model="model.target_app">
                                    <option value="sales">App.Sales</option>
                                    <option value="customer">App.Customer</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.target_app">{{ service_form_error.target_app[0] }}</small>
                            </div>
                            <div class="form-group " >
                                <label>Upload Image</label>
                                <div class="row">
                                    <div class="col-md-6 pl-3 p-0">
                                        <UploadSingleImage v-on:processFile="handleImage"  :layout_height="'200px'"/>
                                    </div>
                                </div>
                                <div>
                                    <small>Please click or Drop to area box !</small><br/>
                                    <small>File images support (Jpg, Jpeg, Png) and maximum upload file size is 2MB.</small><br/>
                                    <small class="text-danger" v-if="service_form_error.path_image">{{ service_form_error.path_image[0] }}</small>
                                </div>
                            </div>  
                            <div class="form-group mt-5">
                                <label >Title Artikel <small class="text-danger">*</small></label>
                                <input type="text" class="form-control"  placeholder="...." v-model="model.title">
                                <small class="text-danger" v-if="service_form_error.title">{{ service_form_error.title[0] }}</small>
                            </div>
                            <div class="form-group">
                                <label>Description <small class="text-danger">*</small></label>
                                <QuillEditor  theme="snow" output="html" style="height: 400px; background-color:#fff;"/>
                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                            </div>
                            <div class="form-group text-center mt-5">
                                <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Save()"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import $ from 'jquery';
    export default 
    {   
        components: {
            QuillEditor
        },
        data() {
            return {
                isSubmitProcess : false,
                model: {}
            };
        },
       
        methods: 
        {
            handleImage(data)
            {
                this.model.photo = data;
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;


                // processing rewrite value
                this.model.description = $('.ql-editor').html();

                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'news/store', data :this.model, callback_url : 'news-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>