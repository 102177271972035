<template>
    <main>
        <header class="">
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Config - Sms Gateway
                    </h1>
                    <div class="page-header-subtitle text-secondary">Setting konfigurasi format pesan melalui SMS - Gateway.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
            <div class="col-xl-12" v-else>
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12 " v-for="(items, idx) in data" :key="idx">
                                    <ul class="data-list" v-if="items.type == 'sms_gateway'">
                                        <li class="name">
                                            {{ items.name }}
                                        </li>
                                        <li class="parameter">
                                            <textarea rows="5" class="form-control" v-model="data[idx].parameter"></textarea>
                                        </li>
                                        <li class="desc">
                                            <span class="text-secondary">{{ items.description }}</span>
                                        </li>
                                    </ul>
                                </div>

                                
                                <div class="col-md-12 " v-for="(items, idx) in data" :key="idx">
                                    <ul class="data-list" v-if="items.type == 'content'">
                                        <li class="name">
                                            {{ items.name }}
                                        </li>
                                        <li class="parameter">
                                            <textarea rows="5" class="form-control" v-model="data[idx].parameter"></textarea>
                                        </li>
                                        <li class="desc">
                                            <span class="text-secondary">{{ items.description }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row" >
                                <div class="col-md-12">
                                    <div class="text-center mt-5 mb-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Konfigurasi'" @click="Save()"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                isSubmitProcess : false,
                data : [],
                model: {},
            };
        },
        created() 
        {
            this.DataQuery();
        },
        methods: 
        {
            HandleMainImage(data)
            {
                this.model.photo = data;
            },
            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'config/sms_data'});
                if(res && res.result)
                {
                    this.data = res.query;
                    this.isFirstLoad = false;
                }
            },
            
            async Submit(e) 
            {
                e.preventDefault();

                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'config/sms_update', data : { data : this.data } });
                if(res && res.result)
                {
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.config-title{
    padding:10px;
    background-color:#ececec;
    span{
        font-size:16px;
    }
}

ul.data-list{
    padding:0;
    margin:0;
    margin-top:10px;
    margin-bottom:10px;
    width:100%;
    float:left;
    border-bottom:1px solid whitesmoke;

    li{
        float:left;
        width:100%;
        padding:10px;
        margin:0;
        border:none;
    }
    li.name{width:20%;}
    li.parameter{width:50%;}
    li.desc{width:30%;}
}
</style>