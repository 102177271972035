<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Supplier <br/> <span class="text-secondary">Pilih salah satu untuk import data.</span></h5>
                    <a href="javascript:;" class="nav-module link-close" @click="CloseModal()"><i class="mdi mdi-close mdi-icon"></i> </a>
                </div>
                <div class="modal-body">
                    <hr/>
                    <div class="row">
                        <div class="col-md-12 mt-3">
                            <div class="table-info">
                                <div class="float-left">
                                    <ul>
                                        <li class="">
                                            <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                        </li>
                                        <li class="border-left">
                                            <i class="mdi mdi-cloud-download text-success"></i> 
                                        </li>
                                    </ul>
                                </div>
                                <div class="group-search">
                                    <input type="text" class="form-control" placeholder="Cari Kode Item, Nama Produk ...." v-model="mixmeta_data.sval"/>
                                    <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                    <a href="javascript:;" @click="ServiceSearchData()" class="search"><i class="mdi mdi-magnify"></i></a>
                                </div>
                            </div>
                            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                            <div class="table-cover scrollbox" v-else>
                                <table class="table s-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th data-filter="supplier_name">Nama </th>
                                            <th data-filter="address">No. Telepon & Email</th>
                                            <th data-filter="address">Alamat</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="mixmodel.total > 0">
                                        <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                            <td width="50">
                                                <a href="javascript:;" class="btn btn-sm btn-light" @click="ChooseData(idx)"  data-dismiss="modal"><i class="mdi mdi-check text-success"></i></a>
                                            </td>
                                            <td>{{ items.supplier_name }}</td>
                                            <td>{{ items.phone_number }}<br/><span class="text-secondary">{{items.email}}</span></td>
                                            <td width="300"><span class="text-secondary">{{ items.address }}</span></td>
                                        </tr>
                                    </tbody>
                            </table>
                            </div>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
               
            </div>
        </div>
    </div>
</template>


<script>
    export default 
    {
        name : 'ImportSupplier',
        data() {
           return {
                error_msg: '',
                collection : [],
                model_items : [],
            }
        },
        created() {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            CloseModal(){
                this.$emit('closeModal');
            },
            ChooseData(idx)
            {
                this.$emit('applyData', this.mixmodel.data[idx]);
                this.$emit('closeModal');
            },
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'supplier/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res){
                    this.mixmodel = res.query;
                    console.log(this.mixmodel);
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>
.scrollbox{
    height:25rem;
}
.table-info{
    background-color:#f9f9f9fc;
}
</style>