<template>
    <main>
        <Detail :data_model="model_detail" v-on:closePopup="closePopupDetail" v-if="isEnablePopup"/>
        
        <header class="">
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Broadcast Pesan
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola konten pesan singkat ( pendek ) yang akan di broadcast ke aplikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'broadcast_msg-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="alert alert-light">
                            <h4><i class="mdi mdi-information-outline text-info"></i> <span>Informasi</span></h4>
                            <hr/>
                            <p>
                                - Pesan akan di blasting mulai pukul 06.00 dan tanggal eksekusi menentukan kapan pesan akan di blasting.<br/>
                                - Status blasting terdiri dari 3, diantaranya sebagai berikut :
                            </p>
                            <ul>
                                <li>( Queue ) : Pesan dalam antrian atau akan di eksekusi pada urutan berikutnya.</li>
                                <li>( Process ) : Pesan dalam pengerjaan atau sedang dalam proses blasting.</li>
                                <li>( Done ) : Pesan telah selesai di blasting.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">

                         <!-- component filter table -->
                         <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter" >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="status">Status</option>
                                            <option value="title">Title</option>
                                            <option value="message">Pesan</option>
                                            <option value="target_level">Level</option>
                                            <option value="execute_date">Tgl.Eksekusi</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-filter">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Filter <br/><small class="text-secondary">Filter data berdasarkan pilihan group.</small></label>
                                    <div class="form-group">
                                        <label>Blasting Status</label>
                                        <select class="form-control" v-model="meta_filter.status" >
                                            <option value="all">Semua Status Blasting</option>
                                            <option value="queue">Queue ( Antrian )</option>
                                            <option value="process">Proses</option>
                                            <option value="done">Done ( Selesai di eksekusi )</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="status">Status</option>
                                            <option value="title">Title</option>
                                            <option value="message">Pesan</option>
                                            <option value="target_level">Level</option>
                                            <option value="execute_date">Tgl.Eksekusi</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox slide-right ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover"  v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter">Action</th>
                                        <th data-filter="status">Status</th>
                                        <th data-filter="title">Title</th>
                                        <th data-filter="message">Pesan</th>
                                        <th data-filter="target_level">Level</th>
                                        <th data-filter="execute_date">Tgl.Eksekusi</th>
                                        <th data-filter="created_at">Tgl.Dibuat</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center" >
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="DataUpdate(items.id)" v-if="$store.state.privileges_modules.uam.includes('update')">Edit</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)" v-if="$store.state.privileges_modules.uam.includes('delete')">Remove</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="150">
                                            <span :class="GenerateStatus(items.status, items.updated_at)">{{ items.status == 'queue' && items.updated_at !== null ? 'process' : items.status }}</span>
                                        </td>
                                        <td width="250">{{ items.title }}</td>
                                        <td width="450">{{ $filters.limit(items.message, 70, '...')  }}</td>
                                        <td width="100">{{ items.target_level }}</td>
                                        <td width="150">{{ $filters.formatDate(items.execute_date, 'DD/MM/YYYY') }}</td>
                                        <td width="150">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>

                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isEnableFilter : false,
                isEnablePopup : false,
                isFirstLoad : true,
                meta_filter :{ status : 'all' }
            };
        },
        created()
        {
            this.ServiceGlobalSearch();
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {   
            GenerateStatus(status, updated_date)
            {
                if( status == 'queue' && updated_date !== null ){
                    return 'bg-border-warning';
                }else{
                    if( status == 'queue' ){
                        return 'bg-border-secondary';
                    }
                    if( status == 'done' ){
                        return 'bg-border-success';
                    }
                }
            },
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'broadcast_msg-update'});
            },
            async DataRemove(id)
            {
                if( confirm( 'Apakah anda yakin ingin menghapus data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url:  'broadcast_msg/destroy', data:{id : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();
                    }
                }
            },
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'broadcast_msg/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'status', val:this.meta_filter.status},
                    ]
                });

                if(res)
                {
                    this.mixmodel = res.query;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
           
        }
    }
</script>
