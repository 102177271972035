<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-xl" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Stock Barang</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div v-if="isFirstLoad" class="page-loader page-loader-blank">
                    <lottie-player src="assets/img/lotties/72785-searching-orange.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/>
                    <span class="text-secondary">Searching for product, Please Wait ...</span>
                </div>
                <div class="modal-body" v-else>
                    <div class="row">
                        <div class="col-md-12">
                             <div class="table-filter">
                                <div class="row">
                                     <div class="col-md-8">
                                         <ul class="data-list">
                                            <li>
                                                <div class="float-start">
                                                    <ImageSmCircle :photo="data_model.photo_owner" v-if="data_model.photo_owner !== null"/>
                                                    <div class="avatar avatar-xl" v-else>
                                                        <img class="avatar-img img-fluid" src="assets/img/avatar.png" />
                                                    </div>
                                                </div>
                                                <div class="float-start ml-2 pt-2">
                                                    <span> <b class="text-danger">{{ data_model.name }}</b></span><br/>
                                                    <span>{{ data_model.code_otmx_reseller  }}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="float-end">
                                            <span class=""><b>Total Stock</b></span> <br/>
                                            <span class="float-end pt-2"> {{ total_stock }} Pcs</span>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div class="table-filter">
                                <div class="row">
                                    <div class="col-md-8 mt-2">
                                        <div class="form-group mr-2 float-start">
                                            <label>Tanggal Awal</label>
                                            <input type="date" class="form-control"  v-model="startdate"  :max="max_date"  id="startDate" />
                                        </div>
                                         <div class="form-group mr-2 float-start">
                                            <label>Tanggal Akhir</label>
                                            <input type="date" class="form-control"  v-model="enddate"  :max="max_date" id="endDate" />
                                        </div>
                                        <div class="form-group float-start">
                                            <label>&nbsp;</label>
                                            <ButtonProcess :classes="'btn btn-sm btn-primary p-2 mt-1 '" :type="'Submit'" :title="'FILTER DATE'" @click="FilterDate()"/>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-2">
                                        <div class="form-group float-end">
                                            <SelectProvider v-on:ChooseData="ChooseProvider"/>
                                        </div>
                                         <div class="form-group mr-2 float-end">
                                             <label>Tipe Produk</label>
                                             <select class="form-control"  v-model="ftype" @change="DataQuery()">
                                                 <option value="voucher">Voucher</option>
                                                 <option value="digital">Digital</option>
                                             </select>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div class="table-responsive scrollbox" v-if="mixmodel.total > 0">
                                <table class="table s-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center no-filter" v-if="$store.state.privileges_modules.uap">Action</th>
                                            <th>Produk</th>
                                            <th>Nama Produk</th>
                                            <th>Harga</th>
                                            <th>Qty</th>
                                            <th>Tgl.Dibuat</th>
                                            <th>Tgl.Update</th>
                                            <th class="text-center" v-if="isEnableEditStock !== 0">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-if="$store.state.loading.status">
                                            <td colspan="9">
                                                <div id="loader-table"><span></span><small></small></div>
                                            </td>
                                        </tr>
                                        <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                            <td width="80" class="text-center" v-if="$store.state.privileges_modules.uap">
                                                <div class="dropdown">
                                                    <button class="btn btn-sm btn-secondary dropdown-toggle" id="dropdownMenuButton" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                        <a href="javascript:;" class="dropdown-item"  @click="EditInputStock(idx)" v-if="$store.state.privileges_modules.uam.includes('update_stock')">Edit</a>
                                                        <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id, items.id_purchasing_detail)" v-if="$store.state.privileges_modules.uam.includes('delete_stock')">Hapus</a>
                                                    </div>
                                                </div>
                                            </td>

                                            <td>{{ items.type }}</td>
                                            <td width="250">{{ items.product_name }}
                                                <br/><span class="text-secondary">{{ items.sku }}</span>
                                            </td>
                                            <td>{{ $filters.formatNumber(items.price) }}</td>
                                            <td width="200">
                                                <div v-if="isEnableEditStock == items.id"> 
                                                    <input type="number" class="input-qty form-control" v-model="model_update.qty"  @keyup="ValidateStock(idx)"/>
                                                    <small class="text-danger">{{ items.msg_error }}</small>
                                                </div>
                                                <span  v-else><b :class="items.qty > 0 ? 'text-success' : 'text-danger' ">{{ items.qty }}</b> pcs</span>
                                            </td>
                                            <td>{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                            <td>
                                                <span v-if="items.updated_at !== null">
                                                    {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.updated_at, 'LT') }}</small>
                                                </span>
                                                <span class="text-secondary" v-else>-- --</span>
                                            </td>
                                            <td class="text-center" v-if="isEnableEditStock !== 0">
                                                <a href="javascript:;" class="btn btn-sm btn-outline-orange" @click="DataUpdate(idx)" v-if="isEnableEditStock == items.id"><i class="fas fa-check mr-1 text-success"></i> SIMPAN</a>
                                                <span v-else>-- --</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="page-loader page-loader-blank" v-else>
                                <lottie-player src="assets/img/lotties/not-found.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/><br/>
                                <span class="text-secondary">Opps, Sorry data not found !</span>
                            </div>
                            <v-pagination
                                v-if="mixmodel.total > 0"
                                v-model="page"
                                :class="'pagination'"
                                :pages="parseInt(mixmodel.last_page)"
                                :range-size="1"
                                active-color="#ffffff"
                                @update:modelValue="DataQuery"
                            />
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    </div>

</template>
<script>
    import moment from "moment";

    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,

                total_stock : 0,
                isEnableEditStock :0,
                model_update : {},
                startdate : '',
                enddate : '',

                fid_provider : 'all',
                ftype : 'voucher',
            };
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            Choose(idx){
                let model = this.mixmodel.data[idx];
                this.$emit('closePopup', model);
            },
            ValidateStock(idx)
            {
                this.mixmodel.data[idx].msg_error = '';
                setTimeout(() => 
                {
                    if( this.mixmodel.data[idx].qty > this.mixmodel.data[idx].max_qty )  {
                        this.mixmodel.data[idx].qty = 0;
                        this.mixmodel.data[idx].msg_error = '! Maks Qty : '+this.mixmodel.data[idx].max_qty;
                    }      

                }, 500);
            },
            EditInputStock(idx)
            {
                this.model_update = this.mixmodel.data[idx];
                this.isEnableEditStock = this.mixmodel.data[idx].id;
            },
            ChooseProvider(data)
            {
                this.fid_provider = data ? data.id : 'all';
                this.DataQuery();
            },
            FilterDate()
            {
                var sdate = document.getElementById('startDate').value;
                var edate = document.getElementById('endDate').value;

                if( sdate == '' && edate == '' ){
                    alert('Silahkan tentukan Tanggal Awal & Tanggal Akhir');
                    return;
                }
                if( sdate == ''  ){
                    alert('Silahkan tentukan Tanggal Awal');
                    return;
                }
                if( edate == ''  ){
                    alert('Silahkan tentukan Tanggal Awal');
                    return;
                }

                this.DataQuery();
            },
            async DataRemove(id, id_purchasing_detail)
            {
                if( id_purchasing_detail == null ){
                    alert('Maaf data belum bisa di hapus, Hubungi Developer !');
                    return;
                }
                
                if( confirm( 'Apakah anda yakin ingin menghapus data ini ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url:  'product_distribution-destroy', data:{id : id, id_purchasing_detail : id_purchasing_detail}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        var sdate = document.getElementById('startDate').value;
                        var edate = document.getElementById('endDate').value;
                        if( sdate == '' && edate == '' ){
                            this.isFirstLoad = true;
                        }

                        this.DataQuery();
                    }   
                }
            },
            async DataQuery(pageNum)
            {

                var query_startdate = 'all';
                var query_enddate   = 'all';

                if(! this.isFirstLoad)
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    if( sdate !== '' && edate !== '' )
                    {
                        query_startdate = moment(sdate).format("DD-MM-YYYY");
                        query_enddate   = moment(edate).format("DD-MM-YYYY");
                    }
                }
                

                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product_distribution-detail', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'product_distribution.code_otmx_reseller', val : this.data_model.code_otmx_reseller},
                        {key : 'product.type', val : this.ftype},
                        {key : 'provider.id', val : this.fid_provider},
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query;
                    this.total_stock = res.total_stock; 
                    this.isFirstLoad = false;
                }
            }, 
            async DataUpdate(idx) 
            {
                let model = this.mixmodel.data[idx];
                let res = await this.ServiceApiPost({  action : 'post', url:'product_distribution-update', data: model });
                
                if(res && res.result)
                {
                    this.DataQuery();
                    this.isEnableEditStock = 0;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.scrollbox, #loader-table{
    height:28em;
}
.input-qty{
    padding:5px;
}
</style>