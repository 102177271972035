import { createWebHistory, createRouter } from "vue-router";

import Store from '../store';
import NotificationSound from '../application/NotificationSound';
import Profile from "../application/Profile";
import Account from "../application/Account";
import Dashboard from "../application/Dashboard";
import Errors404 from '../application/Errors404';
import UserData from '../application/User/Data';
import UserUpdate from '../application/User/Update';
import UserDetail from '../application/User/Detail';
import PrivilegesData from '../application/Privileges/Data';
import PrivilegesStore from '../application/Privileges/Store';
import PrivilegesUpdate from '../application/Privileges/Update';
import PrivilegesAccess from '../application/Privileges/Access';
import Documentation from '../application/Documentation';
import NewsData from '../application/News/Data';
import NewsStore from '../application/News/Store';
import NewsUpdate from '../application/News/Update';

import ResellerData from '../application/Resellers/Data';
import ResellerDownline from '../application/Resellers/Downline';
import ResellerLoans from '../application/Transactions/ResellerLoans';



import SalesDownline from '../application/Resellers/Downline';
import SalesModal from '../application/Sales/Modal';
import SalesManageModal from '../application/Sales/ManageModal';


import ReceivePaymentLoans from '../application/ReceivePayment/Data';
import SummaryLoans from '../application/Transactions/SummaryLoans';
import InquiryLoans from '../application/Transactions/InquiryLoans';
import InquiryCutoffSummary from '../application/Transactions/InquiryCutoffSummary';
import Transaction from '../application/Transactions/Transaction';

import ProviderData from '../application/Provider/Data';
import ProviderStore from '../application/Provider/Store';
import ProviderUpdate from '../application/Provider/Update';

import ProductPriceData from '../application/Price/Data';
import ProductPriceLog from '../application/Price/Log';
import ProductNonDigitalData from '../application/ProductNonDigital/Data';
import ProductNonDigitalStore from '../application/ProductNonDigital/Store';
import ProductNonDigitalUpdate from '../application/ProductNonDigital/Update';
import ProductDigitalGroupData from '../application/ProductDigital/GroupProductOperator';
import ProductDigitalData from '../application/ProductDigital/Product';
import ProductDigitalPrice from '../application/ProductDigital/Price';
import ProductDigitalPromo from '../application/ProductDigital/Promo';

import ProductCategoryData from '../application/ProductCategory/Data';
import ProductCategoryStore from '../application/ProductCategory/Store';
import ProductCategoryUpdate from '../application/ProductCategory/Update';
import ProductSubCategoryData from '../application/ProductSubcategory/Data';
import ProductSubCategoryStore from '../application/ProductSubcategory/Store';
import ProductSubCategoryUpdate from '../application/ProductSubcategory/Update';

import ProductDistributionData from '../application/ProductDistribution/Data';

import SupplierData from '../application/Supplier/Data';
import SupplierStore from '../application/Supplier/Store';
import SupplierUpdate from '../application/Supplier/Update';

import PurchasingData from '../application/Purchasing/Data';
import PurchasingStore from '../application/Purchasing/Store';
import PurchasingUpdate from '../application/Purchasing/Update';

import PromoData from '../application/Banners_Promo/Data';
import PromoStore from '../application/Banners_Promo/Store';
import PromoUpdate from '../application/Banners_Promo/Update';
import BannersData from '../application/Banners/Data';
import BannersStore from '../application/Banners/Store';
import BannersUpdate from '../application/Banners/Update';

import TestCase from '../application/TestCase';
import FeedbackData from '../application/Feedback/Data';
import BroadcastMsgData from '../application/BroadcastMsg/Data';
import BroadcastMsgStore from '../application/BroadcastMsg/Store';
import BroadcastMsgUpdate from '../application/BroadcastMsg/Update';

import FormatReplyData from '../application/Config/FormatReply/Data';
import FormatReplyStore from '../application/Config/FormatReply/Store';
import FormatReplyUpdate from '../application/Config/FormatReply/Update';
import FormatGateway from "../application/Config/FormatGateway/Data";

import ReportTransaction from "../application/Report/Transaction";


const access_guard = function(to, from, next)
{
    if( localStorage.getItem('jwt-token') )
    {
        const permission_access = Store.state.ValidatePermissionAccess(to.name);
        if( permission_access.length ){

            Store.state.privileges_modules.uap = permission_access.length > 1 ? true : false;
            Store.state.privileges_modules.uam = JSON.stringify(permission_access);
            next();

        }else{

            Store.state.privileges_modules.uam = JSON.stringify([]);
            //window.location.href = '404';
        }
        
    }else
    {
        localStorage.clear();
    }
};

const routes = [
   
    {
        path : '/404',
        name : '404',
        component : Errors404,
    },
    {
        path : '/',
        name : 'dashboard',
        component : Dashboard,
       // beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/notification-sound',
        name : 'notification-sound',
        component : NotificationSound,
    },
    {
        path : '/format_gateway-data',
        name : 'format_gateway-data',
        component : FormatGateway,
    },
    {
        path : '/documentation',
        name : 'documentation',
        component : Documentation,
    },
    {
        path : '/profile',
        name : 'profile',
        component : Profile,
    },
    {
        path : '/account',
        name : 'account',
        component : Account,
    },
    {
        path : '/user-data',
        name : 'user-data',
        component : UserData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/user-update',
        name : 'user-update',
        component : UserUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/user-detail',
        name : 'user-detail',
        component : UserDetail,
       // beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/privileges-data',
        name : 'privileges-data',
        component : PrivilegesData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/privileges-insert',
        name : 'privileges-insert',
        component : PrivilegesStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/privileges-update',
        name : 'privileges-update',
        component : PrivilegesUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/privileges-access',
        name : 'privileges-access',
        component : PrivilegesAccess,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/news-data',
        name : 'news-data',
        component : NewsData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/news-insert',
        name : 'news-insert',
        component : NewsStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/news-update',
        name : 'news-update',
        component : NewsUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/reseller-data',
        name : 'reseller-data',
        component : ResellerData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/reseller-downline',
        name : 'reseller-downline',
        component : ResellerDownline,
    },
    {
        path : '/sales-downline',
        name : 'sales-downline',
        component : SalesDownline,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/summary-loans',
        name : 'summary-loans',
        component : SummaryLoans,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/inquiry-data',
        name : 'inquiry-data',
        component : InquiryLoans,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/inquiry_cutoff-detail',
        name : 'inquiry_cutoff-detail',
        component : InquiryCutoffSummary,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
  
    {
        path : '/transaction',
        name : 'transaction',
        component : Transaction,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/rpt-reseller-loans',
        name : 'rpt-reseller-loans',
        component : ResellerLoans,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/receive-payment-loans',
        name : 'receive-payment-loans',
        component : ReceivePaymentLoans,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_price-data',
        name : 'product_price-data',
        component : ProductPriceData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_price-log',
        name : 'product_price-log',
        component : ProductPriceLog,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-data',
        name : 'product_non_digital-data',
        component : ProductNonDigitalData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-insert',
        name : 'product_non_digital-insert',
        component : ProductNonDigitalStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-update',
        name : 'product_non_digital-update',
        component : ProductNonDigitalUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_digital_group-data',
        name : 'product_digital_group-data',
        component : ProductDigitalGroupData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_digital-data',
        name : 'product_digital-data',
        component : ProductDigitalData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-data',
        name : 'product_non_digital-data',
        component : ProductNonDigitalData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-store',
        name : 'product_non_digital-store',
        component : ProductNonDigitalStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_non_digital-update',
        name : 'product_non_digital-update',
        component : ProductNonDigitalUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_digital_price-data',
        name : 'product_digital_price-data',
        component : ProductDigitalPrice,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_digital_promo-data',
        name : 'product_digital_promo-data',
        component : ProductDigitalPromo,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
   
    {
        path : '/product_distribution-data',
        name : 'product_distribution-data',
        component : ProductDistributionData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/supplier-data',
        name : 'supplier-data',
        component : SupplierData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/supplier-insert',
        name : 'supplier-insert',
        component : SupplierStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/supplier-update',
        name : 'supplier-update',
        component : SupplierUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/purchasing-data',
        name : 'purchasing-data',
        component : PurchasingData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/purchasing-insert',
        name : 'purchasing-insert',
        component : PurchasingStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/purchasing-update',
        name : 'purchasing-update',
        component : PurchasingUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/sales-modal',
        name : 'sales-modal',
        component : SalesModal,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/sales-manage-modal',
        name : 'sales-manage-modal',
        component : SalesManageModal,
        //beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/banners-data',
        name : 'banners-data',
        component : BannersData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/banners-insert',
        name : 'banners-insert',
        component : BannersStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/banners-update',
        name : 'banners-update',
        component : BannersUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/promo-data',
        name : 'promo-data',
        component : PromoData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/promo-insert',
        name : 'promo-insert',
        component : PromoStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/promo-update',
        name : 'promo-update',
        component : PromoUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_category-data',
        name : 'product_category-data',
        component : ProductCategoryData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_category-insert',
        name : 'product_category-insert',
        component : ProductCategoryStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_category-update',
        name : 'product_category-update',
        component : ProductCategoryUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },

    {
        path : '/product_subcategory-data',
        name : 'product_subcategory-data',
        component : ProductSubCategoryData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_subcategory-insert',
        name : 'product_subcategory-insert',
        component : ProductSubCategoryStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/product_subcategory-update',
        name : 'product_subcategory-update',
        component : ProductSubCategoryUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },

    {
        path : '/provider-data',
        name : 'provider-data',
        component : ProviderData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/provider-insert',
        name : 'provider-insert',
        component : ProviderStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/provider-update',
        name : 'provider-update',
        component : ProviderUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/feedback-data',
        name : 'feedback-data',
        component : FeedbackData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/broadcast_msg-data',
        name : 'broadcast_msg-data',
        component : BroadcastMsgData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/broadcast_msg-insert',
        name : 'broadcast_msg-insert',
        component : BroadcastMsgStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/broadcast_msg-update',
        name : 'broadcast_msg-update',
        component : BroadcastMsgUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/test-case',
        name : 'test-case',
        component : TestCase,
       // beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/format_reply-data',
        name : 'format_reply-data',
        component : FormatReplyData,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/format_reply-insert',
        name : 'format_reply-insert',
        component : FormatReplyStore,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
    {
        path : '/format_reply-update',
        name : 'format_reply-update',
        component : FormatReplyUpdate,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },

    {
        path : '/report-transaction',
        name : 'report-transaction',
        component : ReportTransaction,
        beforeEnter: (to, from, next) => { access_guard(to, from, next); }
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;