<template>
     <div class="popup-modal">
        <div class="modal-dialog modal-lg" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Cut Off Summary</h5>
                    <button class="btn btn-sm btn-close" type="button" @click="CloseModal()"></button>
                </div>
                 <div v-if="isFirstLoad" class="page-loader page-loader-blank">
                    <lottie-player src="assets/img/lotties/72785-searching-orange.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/>
                    <span class="text-secondary"> Please Wait ...</span>
                </div>
                <div class="modal-body" v-else>
                    <div className="row">
                        <div class="col-md-12 mb-3">
                            <div class="table-search mt-4">
                                <a href="javascript:;" class=" ml-2">
                                    <div class="input-group input-group-joined input-group-solid">
                                        <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                        <div class="input-group-text">
                                            <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                            <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                        </div>
                                        <div class="box" v-if="mixsearch">
                                            <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                            <label class="text-secondary">Pilih Target Pencarian</label>
                                            <select v-model="mixmeta_data.skey">
                                                <option value="">-- Query Search Field --</option>
                                                <option value="inquiry_cutoff.code_otmx_reseller">Kode Sales</option>
                                                <option value="users.name">Nama Sales</option>
                                                <option value="inquiry_cutoff.code_otmx_reseller_2">Kode Reseller</option>
                                                <option value="inquiry_cutoff.code_otmx_reseller_2_nama">Nama Reseller</option>
                                            </select>
                                            <div class="group-input">
                                                <label class="text-secondary">Masukan Keyword Pencarian</label>
                                                <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                                <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                                <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                            </div> 
                                        </div>
                                    </div>
                                </a>  
                            </div>
                        </div>
                       
                        <div class="table-responsive scrollbox" >
                            <table class="table s-table">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>Sales</th>
                                        <th>Reseller</th>
                                        <th>Total Summary</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="150">
                                            {{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small>
                                        </td>
                                        <td>
                                            {{ items.sales_name }}<br/>
                                            <small class="text-secodary">{{ items.code_otmx_reseller }}</small>
                                        </td>
                                        <td>
                                            {{ items.code_otmx_reseller_2_name }}<br/>
                                            <small class="text-secodary">{{ items.code_otmx_reseller_2 }}</small>
                                        </td>
                                        <td>Rp {{ $filters.formatNumber(items.total_amount) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-if="mixmodel.total < 1">
                                    <tr>
                                        <td colspan="4">
                                                <div class="page-loader page-loader-blank">
                                                <lottie-player src="assets/img/lotties/not-found.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/><br/>
                                                <span class="text-secondary">Opps, Maaf data tidak ditemukan !</span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default 
    {
        props: ['id_task'],
        name : 'CutOffDetail',
        data() {
           return {
                isFirstLoad : true,
            }
        },
        created() 
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:    
        {
            CloseModal(){
                this.$emit('closeModal');
            },
            
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'cutoff/inquiry-detail-task', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'inquiry_cutoff.id_task', val : this.id_task },
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>
.scrollbox{
    height:30rem;
}
</style>