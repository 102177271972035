<template>

    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"><i class="fas fa-user"></i></div>
                                Test Case
                            </h1>
                            <div class="page-header-subtitle">Development Test Case</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <form @submit="Submit">
            <div class="container-xl px-4 mt-n10">
                <div class="row">
                    
                    <div class="col-xl-23">
                        <!-- Account details card-->
                        <div class="card mb-4">
                            <div class="card-header">Special Product Price</div>
                            <div class="card-body">
                             
                                <div class="form-group mt-2">
                                    <label class=""> Parameter</label>
                                    <span>Gunakan format berikut "kode_reseller#kode_produk" </span><br/>
                                    <span>contoh : DS017570#IDF1</span>
                                    <input type="text" class="form-control"  placeholder="...." v-model="model.collection">
                                    <small class="text-danger" v-if="service_form_error.collection">{{ service_form_error.collection[0] }}</small>
                                </div>
                                <div class="form-group mt-2">
                                    <label class=""> Hasil Output</label>
                                   <div id="output">{{ msg_info }}</div>
                                </div>
                               
                            </div>
                        </div>
                        <div class="form-group mt-5">
                            <div class="text-center">
                                <ButtonProcess :type="'Submit'" :title="'Submit Test'" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </main>
</template>


<script>
    export default 
    {   
        data() {
            return {
                isFinishLoad : false,
                model:{},
                msg_info : ''
              
            };
        },
    
        methods: 
        {
            async Submit(e) 
            {
                e.preventDefault();

                let model = this.model.collection;
                let collection = model.split(',');
                let data_model = {collection : collection};
                const res = await this.ServiceApiPost({  action : 'post', url:'test-case', data: data_model });
                if(res)
                {
                    let result = res.data;
                    this.msg_info = result.data;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
#output{
    height:200px;
    overflow-y:scroll;
    width:100%;
    padding:20px;
    border:1px solid #ececec;
}
</style>