<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Privileges Group 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Pengaturan privileges group akan menentukan fitur / modul yang dapat di akses oleh pengguna aplikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'privileges-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                           <!-- component filter table -->
                           <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter " >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="id">ID</option>
                                            <option value="name">Privileges Group</option>
                                            <option value="description">Deskripsi</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                            <option value="updated_at">Tgl.Update</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="name">Privileges Group</option>
                                            <option value="description">Deskripsi</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                            <option value="updated_at">Tgl.Update</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter" v-if="$store.state.privileges_modules.uap">Action</th>
                                        <th data-filter="name">Privileges Group</th>
                                        <th data-filter="description">Deskripsi</th>
                                        <th data-filter="created_at">Tgl.Dibuat</th>
                                        <th data-filter="updated_at">Tgl.Update</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" v-if="$store.state.privileges_modules.uap">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="DataAccess(items.id, items.name)" > Akses Kontrol</a>
                                                    <a href="javascript:;" class="dropdown-item" @click="DataUpdate(items.id)" v-if="$store.state.privileges_modules.uam.includes('update') && items.is_lock == 'no'">Edit</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)" v-if="$store.state.privileges_modules.uam.includes('delete') && items.is_lock == 'no'">Remove</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="200"><i :class="items.is_lock == 'yes' ? 'mdi mdi-lock text-danger icon-circle-mini' : 'mdi mdi-lock-open text-success icon-circle-mini'"></i> {{ items.name }}</td>
                                        <td >{{ $filters.limit(items.description, 120, ' ...') }}</td>
                                        <td width="100">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        <td width="100">
                                            <span v-if="items.updated_at !== '0000-00-00 00:00:00'">
                                                {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.updated_at, 'LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    export default {
        data() {
            return {
                isFirstLoad : true,
                isEnableFilter : false,
                model_privileges : [],
                meta_filter :{ id_privileges : 'all' }
            }
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {

            DataAccess(id, name)
            {
                localStorage.setItem('QUERY_ID', id);
                localStorage.setItem('QUERY_NAME', name);
                this.$router.push({name : 'privileges-access'});
            },
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'privileges-update'});
            },
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let query = await this.ServiceApiDataPaging({
                    url: 'privileges/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(query){
                    this.mixmodel = query;
                    this.isRefreshTable = false;
                    this.isFirstLoad = false;
                }
            }, 
            async DataRemove(id)
            {
                if( confirm( 'Apakah anda yakin ingin menghapus data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url: 'privileges/destroy', data:{id_privileges : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();

                    }
                }
            }
        }
    }
</script>