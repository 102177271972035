import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import colorPicker from "vue3-colorpicker";
import "vue3-colorpicker/bundle.css";

import filters from "./filters";
import Authenticate from './service/Authenticate';
import ServerDateService from './service/ServerDateService';
import RequestApiService from './service/RequestApiService';
import BindDataService from './service/BindDataService';
import ActionTriggerService from './service/ActionTriggerService';
import PartComponent from './components/index';

require('./axios.config');
const app = createApp(App);
export const bus = app;


// global properties
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$filters = filters;


// app use
app.use(router);
app.use(store);
app.use(colorPicker);


// app mixin
app.mixin(Authenticate);
app.mixin(ServerDateService);
app.mixin(RequestApiService);
app.mixin(BindDataService);
app.mixin(ActionTriggerService);


// register parts component
PartComponent.forEach(component => {
	app.component(component.name, component);
});


// app mounting
app.mount('#app');