<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Produk Digital Promo 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola promo produk digital yang akan ditampikan di apps.</div>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="alert alert-light">
                            <h4><i class="mdi mdi-information-outline text-info"></i> <span>Informasi</span></h4>
                            <hr/>
                            <p>
                                - Masukan "Harga Promo"  sesuai kode level yang ada pada tiap row / baris produk. <br/>
                                - Sebagai indikator naik atau turun nya harga produk inputan dapat berupa nilai positif atau negatif, misalkan : 1000 atau -1000 <br/>
                                - Edit dan perbaharui kode level jika ingin menambah atau mengurangi kode level  dan gunakan separator koma( , ) untuk menambahkan.
                            </p>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-info">
                                        <div class="float-right">
                                            <ul>
                                                <li>
                                                    <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                                </li>
                                                <li class="border-left">
                                                    <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                                    <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group mr-1">
                                        <SelectProductCategory v-on:ChooseData="FilterCategory" />
                                    </div>
                                </div>
                                <div class="col-md-3 pl-0">
                                    <div class="form-group mr-1">
                                        <SelectProductSubCategory v-on:ChooseData="FilterSubCategory" :id_category="meta_filter.id_category" v-if="meta_filter.id_category !== 'all'"/>
                                        <div v-else>
                                            <label>Sub-Kategori Produk </label>
                                            <select  class="form-control" >
                                                <option value="all">--  --</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 pl-0">
                                    <div class="form-group">
                                        <label>Kode Level</label>
                                        <input type="text" class="form-control" v-model="model_level_str" :disabled="isEditLevel ? false : true"/>
                                    </div>
                                </div>
                                <div class="col-md-2 pl-0">
                                    <a href="javascript:;" id="submit-level-code"  class="btn btn-sm btn-warning" data-toggle="tooltip" data-placement="left" title="Edit Kode Level"  v-if="!isEditLevel" @click="isEditLevel = true">Edit</a>
                                    <a href="javascript:;" id="submit-level-code"  class="btn btn-sm btn-warning" data-toggle="tooltip" data-placement="left" title="Simpan Kode Level" v-else @click="UpdateLevelCode()">Simpan</a>
                                </div>
                            </div>

                            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                            <div class="row" v-else>
                                <div class="col-md-6 pr-0"  >
                                    <table class="table s-table table-product">
                                        <thead>
                                            <tr>
                                                <th colspan="2" class="border-right bg-white">PRODUK DIGITAL</th>
                                            </tr>
                                            <tr>
                                                <th class=" border-top border-bottom">Kategori</th>
                                                <th class=" border-top border-bottom">Produk</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="(mixmodel.total > 0)">
                                            <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                <td width="200" class="border-bottom">
                                                    {{ $filters.ucwords(items.category_name) }}<br/>
                                                    <span class="text-secondary">{{ $filters.ucwords(items.subcategory_name) }}</span>
                                                </td>
                                                <td width="350" class="border-bottom">
                                                    <span>{{ $filters.limit($filters.ucwords(items.name), 30, ' ...') }}</span><br/>
                                                    <span class="text-secondary">{{ items.product_code }}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="float-left mt-3">
                                        <span class="text-secondary">Produk Records  {{ mixmodel.current_page }} &nbsp;/&nbsp; {{ mixmodel.last_page }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6 pl-0" style="position:relative;"  v-if="(mixmodel.total > 0)">
                                    <div class="table-price" id="table-price">
                                        <table class="table s-table ">
                                            <thead>
                                                <tr>
                                                    <template v-for="(items, idx) in model_level" :key="idx">
                                                        <th  :class="(idx % 2) == 0 ? 'no-filter text-center bg-ganjil' : 'no-filter text-center bg-genap'" width="60">{{ items }}</th>
                                                    </template>
                                                </tr>
                                                <tr class="border-top">
                                                    <template v-for="(items, idx) in model_level" :key="idx">
                                                        <th class="text-center border">Harga Promo</th>
                                                    </template>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr v-for="(dt, idx_dt) in mixmodel.data" :key="idx_dt">
                                                    <template v-for="(lv, idx_set) in model_level" :key="idx_set">
                                                        <td>
                                                            <div class="con-width text-center pt-2">
                                                                <input type="text" class="form-control input-mini" placeholder="0" :value="GetPricePromo(dt.product_code, lv)"  @keyup="UpdatePromoPrice($event, dt.product_code, lv)" />
                                                            </div>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="float-right mt-4">
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Perubahan'" @click="Submit()"/>
                                    </div>
                                    <div class="navigation-table navleft">
                                        <a href="javascript:;" @click="ScrollFrame('prev')"><i class="mdi mdi-chevron-left"></i></a>
                                    </div>
                                    <div class="navigation-table navright">
                                        <a href="javascript:;" @click="ScrollFrame('next')"><i class="mdi mdi-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isSubmitProcess : false,
                isFirstLoad : true,
                isEditLevel : false,
                model_level_str : '',
                model_level : [],
                collection : [],                
                meta_filter :{ id_category : 'all', id_subcategory : 'all' }
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {   
            ScrollFrame(initial)
            {
                if(initial == 'next' ){
                    document.getElementById('table-price').scrollLeft += 600;
                }

                if(initial == 'prev'){
                    document.getElementById('table-price').scrollLeft -= 600;
                }
            },
            FilterCategory(data)
            {
                this.meta_filter.id_category = 'all';
                this.meta_filter.id_subcategory = 'all';

                setTimeout(() => {
                    if( data.id ){
                        this.meta_filter.id_category = data.id;
                    }
                    this.DataQuery();
                }, 200);
               
            },
            FilterSubCategory(data)
            {
                this.meta_filter.id_subcategory = 'all';
                if( data.id ){
                    this.meta_filter.id_subcategory = data.id;
                }
                this.DataQuery();
            },
            
            GetPricePromo(product_code, level_code)
            {
                if( this.collection.length > 0 )
                {
                    for(let key in this.collection)
                    {
                        if( this.collection[key].product_code == product_code && this.collection[key].level_code == level_code){
                            return this.collection[key].price_promo;
                        }
                    }

                    return '';
                }
            },
            UpdatePromoPrice(e, product_code, level_code)
            {
                const val = e.target.value;
                let model = {};
                if( this.collection.length > 0 )
                {
                    for(let key in this.collection)
                    {
                        if( this.collection[key].product_code == product_code && this.collection[key].level_code == level_code){
                            model = this.collection[key];
                            break;
                        }
                    }
                }

                if( model.product_code ){
                    model.price_promo = val;
                }else{
                    this.collection.push({product_code : product_code, level_code : level_code, price_promo : val});
                }


                // reset remove null
                for(let key in this.collection)
                {
                    if( this.collection[key].price_promo == ''){
                        this.collection.splice(key, 1)
                    }
                }
            },

            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'product_digital/promo', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'product_category.id', val:this.meta_filter.id_category},
                        {key : 'product_subcategory.id', val:this.meta_filter.id_subcategory},
                    ]
                });
                if(res)
                {
                    let model_promo = res.db_promo;
                    this.mixmodel = res.query;
                    
                    this.model_level = res.dblevel_code;
                    this.model_level_str = this.model_level.toString();
                    
                    if( model_promo.length > 0 )
                    {
                        for(let key in model_promo)
                        {
                            this.collection.push({ product_code : model_promo[key].product_code, level_code : model_promo[key].level_code, price_promo : model_promo[key].price_promo});
                        }
                    }
                    this.isFirstLoad = false;
                }
            },
            async UpdateLevelCode()
            {
                if(confirm('Apakah anda yakin ingin memperbaharui kode level'))
                {
                    const model = {level_code : this.model_level_str}
                    const res = await this.ServiceApiPost({ action : 'post', url: 'product_digital/levelcode', data : model });
                    if(res && res.result)
                    {
                        this.DataQuery();
                        this.isEditLevel = false;
                    }
                }
            },
            async Submit() 
            {
                this.isSubmitProcess = true;

                let model = {data : this.collection};
                let res = await this.ServiceApiPost({ action : 'post', url: 'product_digital/promo-store', data : model, callback_url : 'product_digital_promo-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>


<style lang="scss" scoped>
.bg-buy{background-color:#f5e5e5; font-weight: 300;}
.bg-sell{background-color:#e1f7e1; font-weight: 300;}
.bg-ganjil{background-color:#f6bfaa85;}
.bg-genap{background-color:#f0c53426;}
.bg-white{background-color:#f9f9f9;}

#submit-level-code{
    display:block;
    position:relative;
    top:42px;
    font-size:12px;
}


.basic_price{
    padding:5px;
    background-color:whitesmoke;
    border-radius:6px;
    position:relative;
    top:-2px;
}
.con-width{
    height:42px;
    width:130px;
    .title-global{
        font-size:14px;
        text-transform: capitalize;
    }
}
.con-title{
    width:100%;
}
table{
    border:1px solid whitesmoke;
   
}
.input-mini
{
    padding:5px;
    float:right;
    width:100%;
}
.table-product{
    float:left;
    width:100%;
    thead{
        tr{
            th{border-right:1px solid #ececec;}
            td{
                border-right:1px solid #ececec;
                span{font-size:14px;}
            }
        }
    }
   
}
.table-price{
    position:relative;
    scroll-behavior: smooth;
    float:left;
    width:100%;
    border-right:1px solid #ececec;
    overflow-x:scroll;
    table{
        max-width:2500px;
    }
    tr{

        th{border-right:1px solid #ececec;}
        td{border-right:1px solid #ececec; }
    }  
}


.navigation-table{
    position: absolute;
    padding: 3px;
    background-color: #ffffffad;
    z-index: 1;
    text-align: center;
    top: 0px;
    border-radius: 2px;
    i{
        font-size:20px;
        color:gray;
        position:relative;
        top:2px;
    }
}
.navleft{
    left:0px;
}
.navright{
    right:15px;
}
#loader-faktur{
    height:200px;
    width:100%;
    text-align:center;
    padding:20px;
}
</style>