<template>
    <div>
        <div class="box-video mb-2" >
            <video autoplay="" loop="" :src="data_video" v-if="data_video !== ''" width="100%" muted></video>
            <input type="file" class="input-video" accept="video/*"  @change="processFile($event)">
            <br/>
        </div>
    </div>   
</template>

<script>
    export default
    {
        name: 'UploadSingleVideo',
        props: ['default_video'],
        data () {
            return { 
                isFinishLoad : false,
                max_image_size : 10097152, // 2 MB;
                data_video: '',
            }
        },
        mounted() 
        {
            if( this.default_video !== '' )
            {
                this.data_video = this.default_video;
                if( this.image_padding ){
                    this.styleObject.img_padding = this.image_padding;
                }
            }
        },
        methods: 
        {
            processFile(event)
            {
                this.file = event.target.files[0];
                if (event.target.files && event.target.files[0])
                {
                    let fileSize = event.target.files[0].size;
                    if( parseInt(fileSize) > this.max_image_size ){
                        alert('File image cannot more than 2 MB');
                    }else
                    {
                        let reader = new FileReader();
                        reader.onload = (event) =>
                        {
                            this.data_video = event.target.result;
                            this.$emit('processFile', this.data_video, this.index, this.file);

                        }
                        reader.readAsDataURL(event.target.files[0]);
                    }
                }
            },
        }
    }
</script>

<style lang='scss' scoped>
    .box-video {
        color: dimgray;
        overflow:hidden;
        position: relative;
        text-align:center;
        color:#fff;
        height:200px;
        background-color:#252525d1;
        .material-icons{color:#fff;}
        .group-input-file{
            width:100%;
            float:left;
            border:1px solid red;
            position:relative;
            z-index: 999;
            display: inline;
        }
       
        .input-video{
            position:absolute;
            z-index: 999;
            width:100%;
            height:40px;
            cursor: pointer;
            opacity: 1 !important;
            float:left;
            bottom:0;
            left:10px;
        }
      
        video{
            width:100% !important;
            height:200px !important;
            position: relative;
            z-index: 2;
            cursor:auto;
        }
        p {
            font-size: 1em;
            text-align: center;
            padding: 50px 0;
        }
        i{
            font-size:50px;
            top:50px;
            position: absolute;
            z-index: 9;
            left:0;
            right:0;
            margin:0 auto;
            opacity:.5;
            color:#bbbbbb;
        }
    }
    
</style>