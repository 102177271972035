<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-md" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Assign Area Sales</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="float-sm-start mr-2">
                                <ImageSmCircle :photo="$store.state.api_resource + data_model.photo" v-if="data_model.photo !== null"/>
                                <div class="avatar avatar-xl" v-else>
                                    <img class="avatar-img img-fluid" src="assets/img/avatar.png" />
                                </div>
                            </div>
                             <div class="float-sm-start">
                                <span class="fs-6">{{ $filters.ucwords(data_model.name) }}</span><br/>
                                <span class="text-secondary">{{  $filters.ucwords(data_model.owner_name) }}</span>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="p-0">Propinsi <small class="text-danger">*</small></label>
                                <select v-model="model.id_propinsi"  @change="QueryKota()"  class="form-control" :disabled="isDisable">
                                    <option disabled value="">-- Pilih Propinsi --</option>
                                    <option v-for="(items, idx) in model_propinsi" :value="items.id" :key="idx">{{ items.nama_provinsi }}</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.id_propinsi">{{ service_form_error.id_propinsi[0] }}</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="p-0">Kota/Kabupaten <small class="text-danger">*</small></label>
                                <select v-model="model.id_kota"  @change="QueryKecamatan()"  class="form-control" :disabled="isDisable">
                                    <option disabled value="">-- Pilih Kota/Kabupaten --</option>
                                    <option v-for="(items,idx) in model_kota" :value="items.id" :key="idx">{{ items.tipe }} {{items.nama_kota }}</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.id_kota">{{ service_form_error.id_kota[0] }}</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="p-0">Kecamatan <small class="text-danger">*</small></label>
                                <select v-model="model.id_kecamatan"  @change="QueryKelurahan()"  class="form-control" :disabled="isDisable">
                                    <option disabled value="">-- Pilih Kecamatan --</option>
                                    <option v-for="(items,idx) in model_kecamatan" :value="items.id" :key="idx">{{ items.nama_kecamatan }}</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.id_kecamatan">{{ service_form_error.id_kecamatan[0] }}</small>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="p-0">Kelurahan <small class="text-danger">*</small></label>
                                <select v-model="model.id_kelurahan"  class="form-control" :disabled="isDisable">>
                                    <option disabled value="">-- Pilih Kelurahan --</option>
                                    <option v-for="(items,idx) in model_kelurahan" :value="items.id" :key="idx">{{ items.nama_kelurahan }}</option>
                                </select>
                                <small class="text-danger" v-if="service_form_error.id_kelurahan">{{ service_form_error.id_kelurahan[0] }}</small>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" >
                    <ButtonProcess :classes="'btn btn-sm btn-primary'" :type="'Submit'" :title="'Save Changes'" @click="Submit()"/>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                isDisable : false,
                model: { id_propinsi : '', id_kota : '', id_kecamatan:'', id_kelurahan : '' },

                model_propinsi : [],
                model_kota : [],
                model_kecamatan : [],
                model_kelurahan : [],
            };
        },
        created()
        {
            this.QueryProvince();

        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            async QueryProvince() 
            {
                let res = await this.ServiceApiPost({
                    url:'location/propinsi/data/all'
                });
                if(res){

                    this.model_propinsi = res;
                    if( this.isFirstLoad && this.data_model.id_propinsi !== null ){
                        this.isDisable = true;
                        this.model.id_propinsi = this.data_model.id_propinsi;
                        this.QueryKota();
                    }
                }
            },
            async QueryKota() 
            {
                this.model.id_kota = '';
                this.model.id_kecamatan = '';
                this.model.id_kelurahan = '';
                this.model_kota = [];
                this.model_kecamatan = [];
                this.model_kelurahan = [];
                
                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'provinsi_id', value : this.model.id_propinsi  }
                    },
                    url:'location/kota/data/all'
                });
                if(res){

                    this.model_kota = res;
                    if( this.isFirstLoad && this.data_model.id_kota !== null ){
                        this.model.id_kota = this.data_model.id_kota;
                        this.QueryKecamatan();
                    }
                }
            },
            async QueryKecamatan() 
            {
                this.model.id_kecamatan = '';
                this.model.id_kelurahan = '';
                this.model_kecamatan = [];
                this.model_kelurahan = [];

                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'kota_id', value : this.model.id_kota  }
                    },
                    url:'location/kecamatan/data/all'
                });
                if(res){

                    this.model_kecamatan = res;
                    if( this.isFirstLoad && this.data_model.id_kecamatan !== null ){
                        this.model.id_kecamatan = this.data_model.id_kecamatan;
                        this.QueryKelurahan();
                    }
                }
            },
            async QueryKelurahan() 
            {
                this.model.id_kelurahan = '';
                this.model_kelurahan = [];
                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'kecamatan_id', value : this.model.id_kecamatan  }
                    },
                    url:'location/kelurahan/data/all'
                });
                if(res){

                    this.model_kelurahan = res;
                    if( this.isFirstLoad && this.data_model.id_kelurahan !== null ){
                        this.model.id_kelurahan = this.data_model.id_kelurahan;
                        this.isDisable = false;
                        this.isFirstLoad = false;
                    }
                }
            },
            
            async Submit() 
            {
                this.model.id_user_information = this.data_model.id;
                let res = await this.ServiceApiPost({ action : 'post', url: 'user/sales/set-area', data :this.model });
                if(res && res.result){
                    this.$emit('closePopup', 'success');
                }
            }
        }
    }
</script>
