<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Sub-Kategori Produk 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola sub-kategori produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <div class="col">
                                            <label for="">Listing Type <small class="text-danger">*</small></label>
                                            <select v-model="model.listing_type" class="form-control">
                                                <option value="">-- --</option>
                                                <option value="group">Group Product</option>
                                                <option value="direct">Direct to Product</option>
                                            </select> 
                                            <small class="text-danger" v-if="service_form_error.listing_type">{{ service_form_error.listing_type[0] }}</small>
                                        </div>
                                        <div class="col">
                                            <label for="">Action Type <small class="text-danger">*</small></label>
                                            <select v-model="model.action_type" class="form-control">
                                                <option value="">-- --</option>
                                                <option value="pay">Pay</option>
                                                <option value="check">Check</option>
                                            </select>  
                                            <small class="text-danger" v-if="service_form_error.action_type">{{ service_form_error.action_type[0] }}</small>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col">
                                            <SelectProductCategory v-on:ChooseData="ChooseCategory" :type="'digital'" :id="model.id_category" :mode="'form'" v-if="isFinishLoad"/>
                                            <small class="text-danger" v-if="service_form_error.id_category">{{ service_form_error.id_category[0] }}</small>
                                        </div>
                                        <div class="col">
                                            <label>Nama Sub-Kategori <small class="text-danger">*</small></label>
                                            <input type="text" class="form-control" placeholder="...." v-model="model.name">
                                            <small class="text-danger" v-if="service_form_error.name">{{ service_form_error.name[0] }}</small>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <div class="col-md-6">
                                            <label>Target Kode Catatan <small class="text-danger">*</small></label>
                                            <input type="text" class="form-control" placeholder="...." v-model="model.kode_catatan">
                                            <small class="text-danger" v-if="service_form_error.kode_catatan">{{ service_form_error.kode_catatan[0] }}</small>
                                        </div>
                                    </div>
                                    <div class="form-group row mt-3">
                                        <div class="col">
                                            <label>Notes <br/><small class="text-secondary">Masukan tambahan note jika diperlukan.</small></label>
                                            <QuillEditor  theme="snow" output="html" style="height: 200px; background-color:#fff;"/>
                                            <small class="text-danger" v-if="service_form_error.notes">{{ service_form_error.notes[0] }}</small>
                                        </div>
                                        <div class="col">
                                            <label>Photo Icon  <small class="text-danger">*</small><br/><small class="text-secondary">Upload gambar dalam format (.png)</small></label>
                                            <UploadSingleImage :default_image="model.photo !== '' ?  model.photo : ''" :layout="'potrait'"  v-on:processFile="HandleMainImage" :layout_height="'240px'" v-if="isFinishLoad"/>
                                            <small class="text-danger" v-if="service_form_error.photo">{{ service_form_error.photo[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <hr/>
                                    <div class="text-center mt-5 mb-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Update Data'"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import { QuillEditor } from '@vueup/vue-quill'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    import $ from 'jquery';

    export default 
    {   
        components: {
            QuillEditor
        },
        data() {
            return {
                isFinishLoad : false,
                isSubmitProcess : false,
                model: {},
            };
        },
        created() 
        {
            this.DataQuery();
        },
        methods: 
        {
            ChooseCategory(data)
            {
                this.model.id_category = data ? data.id : 'all';
            },
            HandleMainImage(data)
            {
                this.model.photo = data;
            },
            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'product_subcategory/find', data: {id : localStorage.getItem('QUERY_ID')}});
                if(res)
                {
                    this.model = res.data;
                    $('.ql-editor').html(this.model.notes);

                    if(this.model.photo !== null){
                        this.model.photo = process.env.VUE_APP_API_RESOUCE + this.model.photo;
                    }
                    this.isFinishLoad = true;
                }
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                if( this.model.photo !== null ){
                    this.model.photo = this.model.photo.replace(process.env.VUE_APP_API_RESOUCE, '');
                }
                
                let res = await this.ServiceApiPost({ action : 'post', url: 'product_subcategory/update', data :this.model, callback_url : 'product_subcategory-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
