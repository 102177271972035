<template>
    <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="navbar-menu-wrapper d-flex align-items-stretch">
          <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span class="mdi mdi-chevron-double-left"></span>
          </button>
          <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a class="navbar-brand brand-logo-mini" href="index.html"><img src="assets/images/logo-mini.svg" alt="logo" /></a>
          </div>
          <ul class="navbar-nav">
            <li class="nav-item dropdown global-search">
                <div class="input-group ">
                    <input type="search" id="input-search" class="form-control" v-model="search_keyword"  @keyup="QuerySearch()"  placeholder="Search Data or Jump to ....."  />
                    <div class="input-group-append">
                        <div class="spinner-border  spinner-border-sm" role="status"  v-if="isProcessQuery"></div>
                        <i class="mdi mdi-magnify" v-else></i>
                    </div>
                    <div class="query-search" v-if="model_search.length > 0">
                      <ul>
                          <li v-for="(items, idx) in model_search" :key="idx">
                              <a href="javascript:;" @click="EntryKeyword(items.skey, items.sval, items.source_url, items.title)">
                                  <div class="desc">
                                      <span class="title"><i :class="items.icon"></i> {{ items.module }}<br/>
                                      <span class="text">{{items.desc}}</span></span>
                                  </div>
                              </a>
                          </li>
                      </ul>
                  </div>
                </div>
            </li>
          </ul>
          <ul class="navbar-nav navbar-nav-right">
            <!--<li class="nav-item nav-logout d-none d-md-block">
              <div class="dateclock"><span class="fas fa-clock"></span>  <span>{{ date.day }}/{{ date.month }}/{{ date.year }}  &nbsp;  </span><span id="init-clock"></span></div>
            </li>-->
            <li class="nav-item dropdown">
              <a class="nav-link" id="notificationDropdown" href="#" data-toggle="dropdown">
                <i class="mdi mdi-bell-outline"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                <h6 class="px-3 py-3 font-weight-semibold mb-0">Notifications</h6>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-success">
                      <i class="mdi mdi-calendar"></i>
                    </div>
                  </div>
                  <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 class="preview-subject font-weight-normal mb-0">New order recieved</h6>
                    <p class="text-gray ellipsis mb-0"> 45 sec ago </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-warning">
                      <i class="mdi mdi-settings"></i>
                    </div>
                  </div>
                  <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 class="preview-subject font-weight-normal mb-0">Server limit reached</h6>
                    <p class="text-gray ellipsis mb-0"> 55 sec ago </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-info">
                      <i class="mdi mdi-link-variant"></i>
                    </div>
                  </div>
                  <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                    <h6 class="preview-subject font-weight-normal mb-0">Kevin karvelle</h6>
                    <p class="text-gray ellipsis mb-0"> 11:09 PM </p>
                  </div>
                </a>
                <div class="dropdown-divider"></div>
                <h6 class="p-3 font-13 mb-0 text-primary text-center">View all notifications</h6>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click="MaximizeWindow()">
                <i class="mdi mdi-move-resize-variant" v-if="isEnableFullScreen"></i>
                <i class="mdi mdi-arrow-expand-all" v-else></i>
              </a>
            </li>
          
            <li class="nav-item">
              <a class="nav-link" href="#" >
                <i class="mdi mdi-book-open-page-variant" @click="InitializeCommand()"></i>
              </a>
            </li>
            <li class="nav-item nav-profile dropdown ml-3 d-none d-md-block">
              <a class="nav-link" href="#" @click="ServiceAuthenticateLogout()">
                  <i class="mdi mdi-logout"></i>  
              </a>
            </li>
          </ul>
          <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span class="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
</template>


<script>
 
  import $ from 'jquery';
  export default 
  {   
      data() {
          return {
              isProcessQuery : false,

              isActiveCommand : '',

              search_keyword : '',
              model_search : [],
              isEnableFullScreen : false,
              sound_notification : '',
              fprivileges : localStorage.getItem('user-privileges'),
              date : { year:'', month:'', day:''},
              
          };
      },
      created()
      {
          this.IdentifiedUserQuery();
          this.$nextTick(function()
          {
              this.InitServerDate();
              this.InitClock();
              
          });
      },
      methods: 
      {
          IdentifiedUserQuery()
          {
              if( this.fprivileges == 'all' ){
                  this.freseller = 'all';
              }else{
                  this.freseller = localStorage.getItem('user-code');
              }
          },
          InitServerDate()
          {
              this.year = [];
              this.date.year = this.ServerDateService('active_year');
              this.date.month = parseInt(this.ServerDateService('active_month'));
              this.date.day = this.ServerDateService('active_day');
          },
          InitClock() 
          {
              var today = new Date();
              var h = today.getHours();
              var m = today.getMinutes();
              var s = today.getSeconds();
              m = this.checkTime(m);
              s = this.checkTime(s);
              $('#init-clock').html(h + ":" + m + ":" + s);
              setTimeout(this.InitClock, 500);
          },
          checkTime(i) 
          {
              if (i < 10) {i = "0" + i}  // add zero in front of numbers < 10
              return i;
          },
         
          async MaximizeWindow()
          {
              this.isEnableFullScreen =! this.isEnableFullScreen;

              var elem = document.documentElement;
              if(this.isEnableFullScreen)
              {
                if (elem.requestFullscreen) {
                  elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                  elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                  elem.msRequestFullscreen();
                }
              }else{
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                  document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                  document.msExitFullscreen();
                }
              }
          },
          EntryKeyword(skey, sval, source_url, title)
          {
              this.model_search = [];
              this.search_keyword = title;

              localStorage.setItem("global-search", JSON.stringify({search_key :skey, search_val : sval}));
              window.location.href = source_url;
          },
          async QuerySearch()
          {
              if( this.search_keyword !== '' )
              {
                  this.isProcessQuery = true;
                  const query = await this.ServiceApiPost({url:'search/global', data : {
                      freseller : this.freseller,
                      keyword: this.search_keyword,
                  }});
                  if(query)
                  { 
                    this.model_search = query;
                    this.isProcessQuery = false;
                  }
              }else{
                this.model_search = [];
              }
          }
      },
  }
</script>
<style lang="scss" scoped>

.dateclock{
  position: relative;
  padding:3px 10px 3px 10px;
  font-size:12px;
  background-color:#f1f1f1;
  color:rgb(110, 110, 110);
  border-radius:3px;
  -moz-border-radius:3px;
  -webkit-border-radius:3px;
  margin-right:10px;
  .fas{color : #f37979;}
}
.input-group{
  width:25rem;
  margin-right:15px;
  input[type="search"]{
      border-radius:25px;
      height:36px;
      padding:10px;
      padding-right:50px;
      background-color:#fff;
  }
  .input-group-append{
    position: relative;
    z-index: 99;
    left: -34px;
    top: 0px;
    width: 35px;
    height: 35px;
    text-align: center;
    background-color: #f9f9f9;
    border-radius: 50%;
    padding: 0px;
      i{
          position:relative;
          color:red;
          font-size:20px;
          left:8px;
      }
      div.spinner-border{
        position:relative;
        left:10px;
        top:10px;
      }
  }
}
.query-search{
    border:1px solid whitesmoke;
    position: absolute;
    z-index: 99;
    top: 37px;
    left: 7px;
    width: 88%;
    height: 300px;
    overflow-y: scroll;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);

    ul{
        padding:0px;
        margin:0;
        width:100%;
        float:left;
        li{
            list-style:none;
            float:left;
            width:100%;
            padding:0px;
            border-bottom:1px solid whitesmoke;
            span,b{
                font-size:12px;
                position:relative;
            }
            div.img{
                float:left;
                width:40px;
                height:40px;
                overflow:hidden;
                img{
                    width:auto;
                    height:100%;
                }
            }
            div.desc{
                padding:10px;
                float:left;
                width:100%;
                span.text{
                    color:#2f2f2f;
                    font-size:13px;
                }
                span.title{
                    color:#989898;
                    font-weight:200;
                }
                span.module{
                  color:#2f2f2f;
                  font-weight:300;
                }
                i.mdi{
                  font-size: 14px;
                  color: #ff3e00;
                  width: 25px;
                  height: 25px;
                  position: relative;
                  display: block;
                  float: left;
                  border-radius: 50%;
                  text-align: center;
                  background-color: #f1f1f1;
                  margin-right: 7px;
                }
                
            }
            &:hover{
                background-color:whitesmoke;
            }
        }
        li:last-child{
            border-bottom:none;
        }
    }
}

</style>