import { createStore } from "vuex";

const state = {

    // api
    api_resource : process.env.VUE_APP_API_RESOUCE,

    // application
    app_enable : localStorage.getItem('enable-app') ? localStorage.getItem('enable-app') : 'login',
    app_msg : {status : false, title:'', text: '', type:''},
    loading : {status : false, progress :20, msg : 'Please Wait ...'},

    privileges_modules : {
        uam : [],
        uap : false
    },
    ValidatePermissionAccess : (router_next) => {

        var reg_mkey    = '';
        var reg_action  = '';
        var user_action = [];

        let modules = require('./json/reg.json');
        for(let key in modules)
        {
            // parent have child
            if( modules[key].child  !== undefined )
            {
                let model_child = modules[key].child;
                for(let kchild in model_child)
                {
                    let child_action = model_child[kchild].action;
                    for(let key in child_action)
                    {
                        if( child_action[key] == router_next )
                        {
                            reg_mkey = model_child[kchild].mkey;
                            reg_action = key;
                            break;
                        }
                    }
                }
            }

            // parent doesnt have child
            if( modules[key].parent )
            {
                let model_parent = modules[key].parent;
                if(model_parent.action)
                {
                    let parent_action = model_parent.action;
                    for(let key in parent_action)
                    {
                        if( parent_action[key] == router_next )
                        {
                            reg_mkey = model_parent.mkey;
                            reg_action = key;
                            break;
                        }
                    }
                }
            }
        }

        
        let user_access = JSON.parse(localStorage.getItem('user-modules'));
        for(let key in user_access)
        {
            if( user_access[key].mkey == reg_mkey)
            {
                user_action = JSON.parse(user_access[key].action);
                break;
            }
        }


        if( user_action.includes(reg_action) ){
            return user_action;
        }else{
            return false;
        }
    }
}


export default createStore({
    state
})