<template>
     <div class="popup-modal">
        <div class="modal-dialog modal-md" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">SQL - Code</h5>
                    <button class="btn btn-sm btn-close" type="button" @click="CloseModal()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="data-list">
                                <li><a href="javascript:;" :class="active_command == 'delete' ? 'active' : ''" @click="active_command = 'delete'">Delete</a></li>
                                <li><a href="javascript:;" :class="active_command == 'summary' ? 'active' : ''" @click="active_command = 'summary'">Query Summary</a></li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <textarea  class="sql-code" id="input-code" rows="5" v-model="sql_delete" v-if="active_command == 'delete'" disabled></textarea>
                            <textarea  class="sql-code" id="input-code" rows="5" v-model="sql_summary" v-if="active_command == 'summary'" disabled></textarea>
                        </div>
                        <hr/>
                        <div class="col-md-12 mt-3">
                            <span class="text-secondary">Klik "copy code" kemudian paste pada console command sql server di otomax.</span>
                            <a href="javascript:;" class="btn btn-sm btn-success float-end" @click="Copy()">COPY CODE</a>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default 
    {
        name : 'SqlCommand',
        props : ['data_model', 'end_date'],
        data() {
            return {
                sql_delete : '',
                sql_summary  : '',
                active_command : 'delete'
            };
        },
        created()
        {
            if(this.data_model)
            {
                this.GenerateSqlDelete();
            }
        },
        methods:
        {
            CloseModal()
            {
                this.$emit('closeModal');
            },
            GenerateSqlDelete()
            {
                let model = JSON.parse(this.data_model);
                let output = [];
                for(let key in model){
                    output.push(`'${model[key]}'`);
                }
                this.sql_delete = `DELETE FROM dbo.mutasi WHERE kode_reseller_2 IN ( ${output} ) AND tanggal <= '${this.end_date} 23:59:00';`;
                this.sql_summary  = `SELECT kode_reseller_2, SUM (jumlah) as total_jumlah FROM dbo.mutasi WHERE kode_reseller_2 IN (${output} ) AND jenis = '1' AND tanggal <= '${this.end_date} 23:59:00' GROUP BY kode_reseller_2;`;
            },
            Copy(){

                var copyText = document.getElementById("input-code");
                copyText.select();

                navigator.clipboard.writeText(copyText.value);
            },
            
        }
    }
</script>

<style lang="scss" scoped>
.sql-code{
    position:relative;
    top:-5px;
    padding:20px;
    background-color:#1d1d1d;
    color:#fff;
    border-radius:3px;
    height:300px;
    overflow-y:scroll;
    float:left;
    width:100%;
    border:none;
    p{
        word-break:break-word;
    }
}
ul.data-list{
    width:100%;
    float:left;
    
    li{
        border-bottom:none;
        float:left;
        width:auto;
        padding:0;
        margin-right:3px;
        a{
            display:block;
            background-color:#898989;
            padding:10px;
            color:#fff;
            border-radius:3px;
            text-decoration:none;
        }
        a.active{
            background-color:#000;
        }
    }
}
</style>
