<template>
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
        <ul class="nav">
          <li class="nav-item nav-profile">
            <router-link class="nav-link flex-column" :to="{ name: 'profile'}" >
              <div class="nav-profile-image ">
                <img :src="'assets/images/avatar/'+model.avatar+'.jpg'" alt="profile" />
              </div>
              <div class="nav-profile-text d-flex ml-0 mb-3 flex-column">
                <span class="text-white mb-1 mt-2 text-center"><b>{{ $filters.ucwords(model.name) }}</b></span>
              </div>
            </router-link>
          </li>
          <li class="nav-item box-logo ">
              <a class="nav-link d-block" href="index.html">
                <img class="sidebar-brand-logo" src="assets/images/logo-company.svg" alt="" />
                <img class="sidebar-brand-logomini" src="assets/images/logo-company-simple.png" alt="" />
                <div class="small font-weight-light pt-1 text-white">{{ model.privileges_name }} Dashboard</div>
              </a>
          </li>
          <!-- Aplikasi -->
          <li class="pt-2 pb-1" v-if="ValidateMenuAccess('aplikasi')">
            <span class="nav-item-head">Aplikasi</span>
          </li>
          
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'dashboard'}" >
                <i class="mdi mdi-chart-bar-stacked menu-icon"></i>
                <span class="menu-title">Dashboard </span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'profile'}" >
                <i class="mdi mdi-face menu-icon"></i>
                <span class="menu-title">Profile Info</span>
            </router-link>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'aplikasi' && items.parent.access !== 'block'">
              
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child" @click="ActivateStyle($event)">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
            
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" aria-controls="ui-basic" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>

          <!-- Aplikasi Android -->
          <li class="pt-2 pb-1" v-if="ValidateMenuAccess('aplikasi_android')">
            <span class="nav-item-head">Mobile App</span>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'aplikasi_android' && items.parent.access !== 'block'">
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" :aria-controls="items.parent.mkey" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>

          <!-- Produk -->
          <li class="pt-2 pb-1" v-if="ValidateMenuAccess('product')">
            <span class="nav-item-head">Produk</span>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'product' && items.parent.access !== 'block'">
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" :aria-controls="items.parent.mkey" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>


          <!-- Keuangan -->
          <li class="pt-2 pb-1" v-if="ValidateMenuAccess('keuangan')">
            <span class="nav-item-head">Keuangan</span>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'keuangan' && items.parent.access !== 'block'">
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" :aria-controls="items.parent.mkey" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>



          <!-- Report -->
          <li class="pt-2 pb-1" v-if="ValidateMenuAccess('report')">
            <span class="nav-item-head">Report</span>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'report' && items.parent.access !== 'block'">
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" :aria-controls="items.parent.mkey" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>

           <!-- Developer -->
           <li class="pt-2 pb-1" v-if="ValidateMenuAccess('developers')">
            <span class="nav-item-head">Developers</span>
          </li>
          <template v-for="(items, idx) in data_model" :key="idx">
            <li class="nav-item" v-if="items.parent.group == 'developers' && items.parent.access !== 'block'">
              <router-link class="nav-link" :to="{ name: items.parent.action.view }" v-if="! items.child">
                  <i :class="items.parent.icon"></i>
                  <span class="menu-title">{{ items.parent.name }} </span>
              </router-link>
              <a class="nav-link" data-toggle="collapse" :href="'#'+items.parent.mkey" aria-expanded="false" :aria-controls="items.parent.mkey" v-if="items.child  && items.parent.access !== 'block' ">
                <i :class="items.parent.icon"></i>
                <span class="menu-title">{{ items.parent.name }}</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="collapse" :id="items.parent.mkey"  v-if="items.child">
                <ul class="nav flex-column sub-menu">
                  <template v-for="(row, index) in items.child" :key="index" >
                      <li class="nav-item">
                        <router-link class="nav-link" :to="{ name: row.action.view }" v-if="row.access !== 'block'" >
                            {{ row.name }}
                        </router-link>
                      </li>
                  </template>
                </ul>
              </div>
            </li>
          </template>
        </ul>

        <div id="app-version">Beta Version <span>1</span>.<span>0</span></div>
      </nav>
</template> 

<script>
    export default 
    {   
        data() {
            return {
                ping:{
                    status : 'off',
                    ms : 0,
                    classes : 'fas fa-circle text-secondary'
                },
                data_model : [],
                model: {
                    privileges_name : '',
                    name : '',
                    avatar : '',
                    email : ''
                },
            };
        },
        created()
        {
            this.SetupMenu();
            this.model.privileges_name = localStorage.getItem("user-privileges-name");
            this.model.name = localStorage.getItem("user-name");
            this.model.avatar = localStorage.getItem("user-avatar");
          
           
           /* let self = this;
            setInterval(function(){ 
                self.InitPingCheck();
            }, 7000);*/
        },
        methods: 
        {
            ActivateStyle(event)
            {
              console.log(event.target);
            },

            async InitPingCheck()
            {
                let req = await this.ServiceApiPing({ url:'sys/ping' });
                if( req && req.result )
                {
                    this.ping.status = 'on';
                    this.ping.ms = req.time;

                    if(this.ping.ms == 0){
                        this.ping.classes = 'fas fa-circle  text-secondary';
                    }else
                    {
                        if(this.ping.ms < 100){
                            this.ping.classes = 'fas fa-circle  text-success';
                        }else{
                            if(this.ping.ms > 100){
                                this.ping.classes = 'fas fa-circle text-warning';
                            }
                            if(this.ping.ms > 200){
                                this.ping.classes = 'fas fa-circle text-danger';
                            }
                        }
                    }
                }else{
                    this.ping.status = 'off';
                }
            },
           
            ValidateMenuAccess(initial)
            {
                let data_model = this.data_model;
                for(let key in data_model){
                    if( data_model[key].parent.group == initial && data_model[key].parent.access !== 'block' ){
                        return true;
                    }
                }
                return false;
            },
            SetupMenu()
            {
                this.data_model = require('../../json/reg.json');

                for(let key in this.data_model)
                {
                    if( this.data_model[key].child !== undefined )
                    {   
                        var mchild = this.data_model[key].child;
                        var ct = 0;
                        for(let key_child in mchild)
                        {
                            if( this.FindAccessMenu(mchild[key_child].mkey) == 'block' ){
                                this.data_model[key].child[key_child].access = 'block';
                                ct++;
                            }

                            if( ct == mchild.length  ){
                                this.data_model[key].parent.access = 'block';
                            }
                        }

                    }else{
                        this.data_model[key].parent.access = this.FindAccessMenu(this.data_model[key].parent.mkey);
                    }
                }
            },

            FindAccessMenu(mkey)
            {
                let modules_access = JSON.parse(localStorage.getItem('user-modules'));
                for(let key in modules_access)
                {
                    var module_key = modules_access[key].mkey;
                    if( module_key == mkey ){
                        return 'unblock';
                    }
                }

                return 'block';
            },
        },
    }
</script>

<style lang="scss" scoped>

.box-logo{
  background:#3a3a3a;
  text-align:center;

  .sidebar-brand-logo{
    width:130px;
  }
  .sidebar-brand-logomini{
    width:35px;
  }
}
#bg-panorama{
  background:url('/assets/images/bg-panorama.jpg')no-repeat;
  background-size:cover;
  width:100%;
  height:300px;
  position:absolute;
  left:0;
  bottom:0;
}


</style>