<template>
    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"><i class="fas fa-lock"></i></div>
                                Account
                            </h1>
                            <div class="page-header-subtitle">Example dashboard overview and content summary</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Main page content-->
        <div class="container-xl px-4 mt-n10">
            <div class="row">
                <div class="col-xl-12">
                    <!-- Account details card-->
                    <div class="card mb-4">
                        <div class="card-header">Account Details</div>
                        <div class="card-body">
                            <form @submit="Submit">
                                <div class="form-group">
                                    <label class=""> Privileges Group</label>
                                    <input type="text" class="form-control"  placeholder="...." v-model="model.privileges_name" readonly>
                                </div>
                                <div class="form-group">
                                    <label class=""> Default Password</label>
                                    <input type="password" class="form-control" v-model="model.password"/>
                                    <small class="text-danger" v-if="service_form_error.password">{{ service_form_error.password[0] }}</small>
                                </div>
                                <div class="form-group">
                                    <label class=""> Confirm Default Password</label>
                                    <input type="password" class="form-control" v-model="model.password_confirmation"/>
                                    <small class="text-danger" v-if="service_form_error.password_confirmation">{{ service_form_error.password_confirmation[0] }}</small>
                                </div>
                                <!-- Submit button-->
                                <div class="form-group mt-5 text-right">
                                    <ButtonProcess :classes="'btn btn-primary float-right'" :type="'Submit'" :title="'SAVE CHANGES'" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                model:{
                    password :''
                }
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
            async DataQuery()
            {
                let req = await this.ServiceApiPost({url:'user/profile', data:{userid :localStorage.getItem('user-id') }});
                if(req && req.result)
                {
                    this.model = req.query;
                }
            },
            async Submit(e) 
            {
                e.preventDefault();
                let req = await this.ServiceApiPost({ url:'user/account', data: this.model });
                if( req && req.result )
                {
                    this.ServiceApiMessage('Congratulations', req.msg);
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                }
            }
        }
    }
</script>