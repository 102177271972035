<template>
    <main>
        <DetailLog v-on:closePopup="closePopup"  :data_model="model_detail" v-if="isEnablePopup"/>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"><i class="fas fa-users"></i></div>
                                Log Perubahan Harga
                            </h1>
                            <div class="page-header-subtitle">Total Data Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-xl px-4 mt-n10">
            <div class="row">
                <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                <div class="col-xl-12" v-else>
                    <div class="card mb-4">
                        <div class="card-header">
                            <div class="table-search">
                                 <a href="javascript:;" class=" ml-2">
                                    <div class="input-group input-group-joined input-group-solid">
                                        <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                        <div class="input-group-text">
                                            <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                            <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                        </div>
                                        <div class="box" v-if="mixsearch">
                                            <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                            <label class="text-secondary">Pilih Target Pencarian</label>
                                            <select v-model="mixmeta_data.skey">
                                                <option value="product.sku">SKU Produk</option>
                                                <option value="product.name">Nama Produk</option>
                                            </select>
                                            <div class="group-input">
                                                <label class="text-secondary">Masukan Keyword Pencarian</label>
                                                <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                                <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                                <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                            </div> 
                                        </div>
                                    </div>
                                </a>  
                            </div>
                        </div>
                        <div class="card-body">
                            <div v-if="mixmodel.total > 0">
                                <div class="table-responsive">
                                    <table class="table s-table">
                                        <thead>
                                            <tr>
                                                <th class="text-center no-filter">Action</th>
                                                <th>Author Name</th>
                                                <th>Log.Code</th>
                                                <th>Note</th>
                                                <th>Tgl.Dibuat</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr v-if="$store.state.loading.status">
                                                <td colspan="9">
                                                    <div id="loader-table"><span></span><small></small></div>
                                                </td>
                                            </tr>
                                            <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                <td width="120" class="text-center">
                                                    <a href="javascript:;" class="btn btn-sm btn-outline-orange" @click="DataDetail(idx)"><i class="fas fa-file text-success mr-1"></i> Detail Log</a>
                                                </td>
                                                <td>
                                                    {{ items.author_name }}<br/>
                                                    <span class="text-secondary">{{ items.code_otmx_reseller }}</span>
                                                </td>
                                                <td>{{ items.update_code }}</td>
                                                <td width="400">{{ items.note }}</td>
                                                <td>{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <v-pagination
                                    v-if="mixmodel.total > 0"
                                    v-model="page"
                                    :class="'pagination'"
                                    :pages="parseInt(mixmodel.last_page)"
                                    :range-size="1"
                                    active-color="#ffffff"
                                    @update:modelValue="DataQuery"
                                />
                            </div>
                           <div class="page-loader page-loader-blank" v-else>
                                <lottie-player src="assets/img/lotties/not-found.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/><br/>
                                <span class="text-secondary">Opps, Sorry data not found !</span>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import DetailLog from './DetailLog';

    export default {
        components: {
            DetailLog,
        },
        data() {
            return {
                isEnablePopup : false,
                isFirstLoad : true,
                model_detail : {}
            }
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            closePopup(){
                this.isEnablePopup = false;
            },
            DataDetail(idx)
            {
                this.model_detail = {
                    author_name :  this.mixmodel.data[idx].author_name,
                    author : this.mixmodel.data[idx].author,
                    update_code : this.mixmodel.data[idx].update_code
                }
                this.isEnablePopup = true;
            },
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'price/log', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                   
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                }
            }, 
        }
    }
</script>
