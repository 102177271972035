<template>
    <main>
        <div class="login-container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="box-container">
                        <div class="card-login">
                            
                            <div class="card-body p-0">
                                <form @submit="SubmitLogin">
                                    <div class="row justify-content-md-center">
                                        <div class="col-md-6 login-left p-0">
                                            <div id="bg-images"></div>
                                        </div>
                                        <div class="col-md-6 login-right">
                                            <h3 class="fw-light text-center mt-4">Welcome to Dashboard</h3>

                                            <div class="mb-1 mt-4">
                                                <label >Phone Number</label><br/>
                                                <input class="form-control" id="inputPhone" placeholder="..." type="text" v-model="model.phonex" />
                                                <small class="text-error" v-if="error_msg.handphone">! {{ error_msg.handphone[0] }}</small>
                                            </div>
                                            <div class="mb-1">
                                                <label  for="inputPassword">ID-Reseller</label>
                                                <input class="form-control" id="inputPassword" type="text" placeholder="..." v-model="model.id_reseller" />
                                                <small class="text-error" v-if="error_msg.id_reseller">! {{ error_msg.id_reseller[0] }}</small>
                                            </div>
                                            <div class="mb-1">
                                                <label  for="inputPassword">Pin-Code</label>
                                                <input class="form-control" id="inputPassword" type="password" placeholder="****" v-model="model.pincode" />
                                                <small class="text-error" v-if="error_msg.pincode">! {{ error_msg.pincode[0] }}</small>
                                                <small class="text-error" v-if="error_msg.password">! {{ error_msg.password[0] }}</small>
                                            </div>
                                            <hr/>
                                            <div class="text-center mt-3 mb-0">
                                                <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'CONTINUE LOGIN'" />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="login-footer" >
               
                <span>Copyright &copy; 2023 Dflash - All Rights Reserved.</span>
                  
            </footer>
        </div>
    </main>
</template>
<script>

    export default 
    {
        data() {
            return {
                error_msg : {},
                model :{  phonex : '', id_reseller : '', pincode : '' },
                isSubmitProcess  : false,
            }
        },
        methods:
        {
            async SubmitLogin(e) 
            {
                e.preventDefault();
                this.error_msg = {};
                this.isSubmitProcess = true;
                
                this.model.handphone = this.model.phonex;
                if( this.model.phonex !== '' )
                {
                    let phone = this.model.phonex;

                    if( phone.charAt(0) == 6 && phone.charAt(1) == 2 ){
                        this.model.handphone = '+'+phone;
                    }else{
                        if( phone.charAt(0) == 0 ){
                            this.model.handphone = '+62'+phone.substring(1);
                        }else{
                            this.model.handphone = phone;
                        }
                    }
                }
                
                const req = await this.ServiceAuthenticateLogin(this.model);
                if(req)
                {
                    if( req.result )
                    {
                       this.configuration();
                    }else{
                        this.isSubmitProcess = false;
                        this.error_msg = req.msg;
                    }
                }
            },
            async configuration()
            {
                const req = await this.ServiceApiPost({url:'auth/initialize', data:{
                    user_id     : localStorage.getItem('user-id'),
                }});
                if( req && req.result )
                {
                    let model = req.query;

                    localStorage.setItem('user-modules', JSON.stringify(model.modules));
                    localStorage.setItem('server-date', JSON.stringify(model.date));
                    localStorage.setItem('notification_sound', model.notification);

                    localStorage.setItem('user-name', model.name);
                    localStorage.setItem('user-photo', model.photo_owner );
                    localStorage.setItem('user-privileges-name', model.privileges_name);
                    localStorage.setItem('enable-app', 'ready');

                    setTimeout(() => {
                       window.location.reload();
                    }, 1000);

                }else{
                    this.task_progress = 100;
                    this.task_status = 'Permission Denied !!, You dont have any access to application. Please contact administrator! ';
                }   
            },
        }
    }
</script>


<style lang="scss" scoped>

.login-container{
    background: -moz-linear-gradient(261deg, rgba(248,158,35,1) 0%, rgba(226,68,151,1) 100%)!important;
    background: -webkit-linear-gradient(261deg, rgba(248,158,35,1) 0%, rgba(226,68,151,1) 100%)!important;
    background: linear-gradient(261deg, rgba(248,158,35,1) 0%, rgba(226,68,151,1) 100%)!important;
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

.box-container{
    width:800px;
    position:relative;
    display:block;
    margin:0 auto;
    left:0;
    right:0;
    top:30%;
}

.login-left, .login-right{
    height:430px;
}

.login-left{
    text-align:center;
    padding:10px;
    img{
        position:relative;
        margin:0 auto;
        width:70%;
    }
    #bg-images{
        position:relative;
        width:100%;
        height:100%;
        float:left;
        background : url('/assets/images/konter.png')no-repeat;
        background-size:cover;
    }
}
.login-right{
    padding:25px;
    background-color:#d2d2d21f;
    
    h3, span{
        color:#fff;
    }
    h3{
        margin-bottom:20px;
    }
    span b{
        color:#a12c09;
    }
}


@media screen and (min-width: 1400px) {
    .box-container{ top: 50%; }
}
@media screen and (min-width: 1600px) {
    .box-container{ top: 50%; }
}
@media screen and (min-width: 1900px) {
    .box-container{ top: 50%; }
}



.card-login{
    position:relative;
    padding:5px;

    .card-body{
        background-color:none !important;
        a{
            color:#fff;
        }
        .text-error{color:#340202;}
        label, small{
            font-weight:200;
            color:#fff;
            font-size:14px;
        }
        small{
            font-size:12px;
        }
        .btn-full{
            padding:10px;
        }

    }
}
.login-footer{
    position:fixed;
    bottom:20px;
    left:0;
    right:0;
    margin:0 auto;
    width:280px;
    span{
        color:#fff;
        font-size:12px;
    }
}


.process{
    position:relative;
    float:left;
    width:100%;
    h5{
        margin-top:30px;
        color:#000;
    }
    small{
        color:gray;
        font-size:12px;
    }
    .form-group{
        margin-top:30px;
    }
    .progress-bar{
        position:relative;
        height:2px;
        width:100%;
        margin:0 auto;
        background-color:#e9e9e92b;
        span{
            background-color:#f62d00bd;
            width:20%;
            height:4px;
        }
    }
    span.progress-status{
        position:relative;
        top:10px;
        color:#fff;
        font-size:11px;
        float:right;
    }
}
</style>