<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Provider 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola provider telekomunikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="col">
                                            <label>Photo Icon  <small class="text-danger">*</small>  <br/><small class="text-secondary">Upload gambar dalam format (.png)</small></label>
                                            <UploadSingleImage v-on:processFile="HandleMainImage" :layout_height="'230px'"/>
                                            <small class="text-danger" v-if="service_form_error.photo">{{ service_form_error.photo[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label>Nama  Provider <small class="text-danger">*</small> <br/><small class="text-secondary">Masukan nama kategori produk.</small></label>
                                        <input type="text" class="form-control" placeholder="...." v-model="model.name">
                                        <small class="text-danger" v-if="service_form_error.name">{{ service_form_error.name[0] }}</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Kode HLR <small class="text-danger">*</small> <br/><small class="text-secondary">Penyesuaian Kode HLR di sesuaikan dengan data otomax.</small></label>
                                        <input type="text" class="form-control" placeholder="...." v-model="model.hlr_code">
                                        <small class="text-danger" v-if="service_form_error.hlr_code">{{ service_form_error.hlr_code[0] }}</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group text-center mt-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Save()"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {

            return {
                isSubmitProcess  : false,
                model: {},
            };
        },
        methods: 
        {
            HandleMainImage(data)
            {
                this.model.photo = data;
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                let res = await this.ServiceApiPost({ action : 'post', url: 'provider-store', data :this.model, callback_url : 'provider-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
