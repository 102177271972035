<template>
    <main>
        <div class="slide-right-box" v-if="isEnableParameterInfo">
            <span class="exit"><i class="mdi mdi-close" @click="isEnableParameterInfo = !isEnableParameterInfo"></i></span>
            <div class="mt-5 float-left" v-if="parameter_format_struk_wajib.length > 0">
                <h6>Parameter Wajib</h6>
                <ul class="format-struk">
                    <li  v-for="(items, idx) in parameter_format_struk_wajib" :key="idx" >{{ items }}</li>
                </ul>
            </div>
            <div class="mt-3 float-left" v-if="parameter_format_struk_optional.length > 0">
                <h6>Parameter Optional</h6>
                <ul class="format-struk">
                    <li  v-for="(items, idx) in parameter_format_struk_optional" :key="idx" >{{ items }}</li>
                </ul>
            </div>
        </div>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-9 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Format Reply
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola & setting format reply pada sms gateway.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="form-group">
                                                <label for="">Jenis Format Reply</label>
                                                <select class="form-control" v-model="idx_format_struk" @change="ChooseFormatStruk()" >
                                                    <option value="" >-- Pilih --</option>
                                                    <option  v-for="(items, idx) in model_struk" :key="idx" :value="items.id">{{ items.name }}</option>
                                                </select>  
                                                <small class="text-danger" v-if="service_form_error.id_format_struk">Format struk is required.</small>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="float-right" v-if="parameter_format_struk !== ''">
                                                <br/>
                                                <a href="javascript:;" class="mt-4 bg-border-secondary float-right" @click="GenerateFormatStruk()"><i class="mdi mdi-information-outline"></i> &nbsp; Info Parameter</a>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Kode Modul <br/><small>Gunakan delimiter (,) jika inputan lebih dari satu</small></label>
                                                <input type="text" class="form-control" v-model="model.modul_code"/>
                                                <small class="text-danger" v-if="service_form_error.modul_code">{{ service_form_error.modul_code[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="">Produk <br/><small>Gunakan delimiter (,) jika inputan lebih dari satu</small></label>
                                                <input type="text" class="form-control" v-model="model.product"/>
                                                <small class="text-danger" v-if="service_form_error.product">{{ service_form_error.product[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="">Reply Supplier</label>
                                                <div class="box-listing" id="box-sample">
                                                    <div class="loader-box" v-if="isResultSn"><i class="fas fa-spinner fa-spin" aria-hidden="true"></i></div>
                                                    <textarea class="form-control" placeholder="Masukan / Paste kode reply supplier" v-model="model.reply"></textarea>
                                                </div>
                                                <small class="text-danger" v-if="service_form_error.reply">{{ service_form_error.reply[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <div class="box mt-3 mb-3">
                                                    <label for="">Masukan <b>Regex</b></label>
                                                    <textarea class="form-control" rows="7" v-model="model.format_regex" ></textarea>
                                                    <small class="text-danger" v-if="service_form_error.format_regex">{{ service_form_error.format_regex[0] }}</small>
                                                </div>
                                                <div class="box" id="box-convert" v-if="isResultParams">
                                                    <div class="loader-box" v-if="isLoadParams"><i class="fas fa-spinner fa-spin" aria-hidden="true"></i></div>
                                                    <ul class="data-list">
                                                        <li v-for="(items, idx) in model.params" :key="idx"><span>{{ idx }} : </span><span class="float-right text-secondary">{{items}}</span></li>
                                                    </ul>
                                                    <small class="text-danger" v-if="service_form_error.params">{{ service_form_error.params[0] }}</small>
                                                </div>
                                                <div class="box" id="box-desc" v-if="isResultParams" >
                                                    <p>Setiap nilai yang berubah ditiap reply sukses harus diganti dengan parameter yang di definisikan.</p>
                                                </div>
                                                <div class="box mt-1" v-if="model.sn !== ''">
                                                    <hr/>
                                                    <a href="javascript:;" class="btn btn-fullwidth" @click="GenerateParams()">{{ isLoadParams ? 'PLEASE WAIT ...' : 'GENERATE PARAMETER'}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div class="form-group pt-3 mt-2 text-center">
                                <a href="javascript:;" class="btn btn-sm btn-light mr-3" @click="$router.go(-1)">Cancel</a>
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Save()"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    export default 
    {   
      
        data() {
            return {
                isSubmitProcess : true,
                isEnableParameterInfo : false,
                isResultParams : false,
                isLoadParams :false,

                idx_format_struk : null,
                parameter_format_struk_wajib : '',
                parameter_format_struk_optional : '',
                model_struk : [], 
                model : {
                    reply :'',
                    format_regex : '',
                    params : {},
                },
            };
        },
        created() 
        {
            this.QueryFormatStruk();
            this.DataQuery();
        },
        methods: 
        {
            async GenerateParams() 
            {
                this.isLoadParams = true;
                let res = await this.ServiceApiPost({url: 'format_reply/generate', data : {
                    str_reply : this.model.reply,
                    str_format_regex : this.model.format_regex
                } });
                
                if(res)
                {
                    if(res.result){
                        this.model.params = res.data;
                        this.isResultParams = true;
                        this.isLoadParams = false;
                    }else{
                        this.service_form_error = {format_regex : [res.msg]};
                    }
                }
            },
            ChooseFormatStruk()
            {
                this.model.id_format_struk = this.model_struk[this.idx_format_struk].id;
                this.parameter_format_struk_wajib = this.model_struk[this.idx_format_struk].parameter_wajib;
                this.parameter_format_struk_optional = this.model_struk[this.idx_format_struk].parameter_optional;
            },
            ClearRegex()
            {
                this.model.params = '';
                this.isResultParams = false;
                this.isLoadParams = false;
            },
            GenerateFormatStruk()
            {
                if( typeof this.parameter_format_struk_wajib == 'string' )
                {
                    this.parameter_format_struk_wajib = this.parameter_format_struk_wajib.split(',');
                }

                if( typeof this.parameter_format_struk_optional == 'string' )
                {
                    this.parameter_format_struk_optional = this.parameter_format_struk_optional.split(',');
                }
                
                this.isEnableParameterInfo = true;
            },
            async QueryFormatStruk()
            {
                let res = await this.ServiceApiGet({url: 'format_struk/data'});
                if(res)
                {
                    this.model_struk = res.data;
                }
            },

            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'format_reply/find', data: {id : localStorage.getItem('QUERY_ID')}});
                if(res)
                {
                    this.model = res.data;

                    for(let key in this.model_struk)
                    {
                        if( this.model_struk[key].id == this.model.id_format_struk )
                        {
                            this.parameter_format_struk_wajib = this.model_struk[key].parameter_wajib;
                            this.parameter_format_struk_optional = this.model_struk[key].parameter_optional;
                        }
                    }

                    this.idx_format_struk = this.model.id_format_struk;
                    this.isFinishLoad = true;
                    this.isResultParams = true;
                }
            },

            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess  = true;

                // processing rewrite value
                let res = await this.ServiceApiPost({ action : 'post', url: 'format_reply/update', data :this.model, callback_url : 'format_reply-data' });
                if(res){
                    this.isSubmitProcess  = false;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>

ul.format-struk{
    padding:0;
    margin:0;
    li{
        width:100%;
        float:left;
        list-style:none;
        padding:10px;
        border-bottom:1px solid whitesmoke;
    }
}
.btn-fullwidth{
    width:100%;
    padding:10px;
    background-color:#e6e6e6;
    text-align:center;
    color:rgb(60, 60, 60);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.box{
    width:100%;
    float:left;
}
.box-listing{
    position:relative;
    width:100%;
    float:left;
    border:1px solid rgb(210, 210, 210);
    font-size:14px;
    padding:10px;
}
#box-sample{
    padding:0;
    textarea{
        border:none;    
        width:100%;
        height:425px;
        resize: none;
        text-align:left;
        line-height: 20px;
    }
}
#box-sn{
    background-color:rgb(242, 242, 242);
}
.box-parameter{
    float:left;
    width:100%;
    margin-bottom:30px;
    label{
        background-color:#ececec;
        padding:5px;
    }
}

#box-convert{
    position:relative;
    padding-right:10px;
    max-height: 200px;
    overflow-y:scroll;
    width:60%;
    border-right:1px solid rgb(230, 230, 230);
}
#box-desc{
    padding-left:10px;
    width:40%;
}
</style>