<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-xl" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Distribusi Barang</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body" >
                    <hr/>
                    <div class="row">
                        <div class="col-md-7">
                            <label>Master Produk <br/><span class="text-secondary">Pilih produk yang akan di distribusi.</span></label>
                            <hr/>
                            <div class="scrollbox">
                                <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                                <div class="s-table table-cover" v-else>
                                    <table :class="isRefreshTable ? 'table-load' : '' ">
                                        <thead>
                                            <tr>
                                                <th class="text-center">--</th>
                                                <th>SKU</th>
                                                <th>Nama Produk</th>
                                                <th>Harga Jual</th>
                                                <th>Total Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                <td width="50">
                                                    <div class="dropdown" v-if="items.selling_price > 0 && items.qty > 0">
                                                        <a href="javascript:;" class="btn btn-sm btn-light" @click="CollectItem('add', items.id, idx)"><i class="mdi mdi-plus text-success"></i></a>
                                                    </div>
                                                    <div v-else>--</div>
                                                </td>
                                                <td>{{ items.sku }}</td>
                                                <td>{{ items.name }}</td>
                                                <td width="120"> Rp {{ $filters.formatNumber(items.selling_price) }}</td>
                                                <td width="100"><b :class="items.qty > 0 ? 'text-success' : 'text-danger' " >{{ $filters.formatNumber(items.qty) }}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <v-pagination
                                    v-if="mixmodel.total > 0"
                                    v-model="page"
                                    :class="'pagination'"
                                    :pages="parseInt(mixmodel.last_page)"
                                    :range-size="1"
                                    active-color="#ffffff"
                                    @update:modelValue="DataQuery"
                                />
                            </div>
                            <hr/>
                            <div>
                                <span class=""><b>!</b> Mohon periksa kembali jumlah quantity dan produk sebelum anda menyimpan data.</span>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <label>Distribusi Produk <br/><span class="text-secondary">Tentukan jumlah quantity produk.</span></label>
                            <hr/>
                            <div class="scrollbox">
                                <table class="cart">
                                    <thead>
                                        <tr>
                                            <th>Produk</th>
                                            <th class="text-center">Quantity</th>
                                            <th class="text-center">--</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  v-for="(items, idx) in collection" :key="idx">
                                            <td>
                                                <span>{{ items.name }}</span><br/>
                                                <span class="text-secondary"> {{ items.sku }}</span>
                                            </td>
                                            <td width="90">
                                                <input type="number" placeholder="0"  v-model="collection[idx].topup_qty"  @keyup="ValidateStock(idx)" style="width:100px;"/>
                                                <div class="msg-error" v-if="items.msg_error">
                                                    <small class="text-danger">{{ items.msg_error }}</small>
                                                </div>
                                            </td>
                                            <td width="50" class="text-center">
                                                <a href="javascript:;"  @click="CollectItem('remove', items.id, idx)"><i class="mdi mdi-delete-circle mt-3 ml-2 text-danger"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                            <hr/>
                            <div class="mt-1 float-right" v-if="collection.length > 0">
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Submit()"/>
                            </div>
                            <div v-else>
                                <span>Produk belum terpilih, Silahkan pilih produk !</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                isSubmitProcess : false,
                isRefreshTable : false,

                isEnableEditStock : 0,
                collection : []
            };
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            Choose(idx){
                let model = this.mixmodel.data[idx];
                this.$emit('closePopup', model);
            },
            ValidateStock(idx)
            {
                this.collection[idx].msg_error = '';
                setTimeout(() => 
                {
                    if( this.collection[idx].topup_qty > this.collection[idx].qty )  {
                        this.collection[idx].topup_qty = 0;
                        this.collection[idx].msg_error = '! Jumlah stock barang tidak mencukupi, Maksimum transfer stock barang '+this.collection[idx].qty;
                    }      

                }, 500);
            },
           
            CollectItem(initial, id, idx)
            {
                if(initial == 'add')
                {
                    let collection = this.collection;
                    for(let key in collection)
                    {
                        if( collection[key].id == id ){
                            alert('Product sudah ada !');
                            return;
                        }
                    }
                    this.collection.push( this.mixmodel.data[idx] );
                }else{
                    this.collection.splice(idx, 1);
                }
            },
           
            async DataQuery(pageNum)
            {
                this.isRefreshTable = false;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'purchasing/detail', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res){ 

                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
            async Submit()
            {
                this.isSubmitProcess = true;
                let model = {
                    code_otmx_reseller : this.data_model.code_otmx_reseller,
                    collection : this.collection,
                };
                let res = await this.ServiceApiPost({ url:'product_distribution-store', data: model});
                if(res)
                {
                    this.isSubmitProcess = false;
                    this.$emit('closePopup', 'refresh');
                    this.ServiceApiMessage(
                        'Congratulations',
                        res.msg, 
                        'default'
                    );
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.msg-error{
    width:100%;
    float:left;
    margin-top:5px;
}
.scrollbox, #loader-table{
    height:18rem;
}

.cart{
    width:100%;
    border:1px solid rgb(242, 242, 242);
    thead{

        tr > th{
            padding:10px;
            font-size:13px;
            background-color:#effff1 !important;
        }
    }
    tbody{
        tr > td{
            padding:10px;
            input[type="number"]{
                border:1px solid rgb(218, 218, 218);
                padding:5px;
                border-radius:350px;
            }
            i{font-size:18px;}
        }
    }
}
</style>