<template>
    <div  v-if="$store.state.app_msg.status !== false">
        <div id="black-screen"></div>
        <div id="alert-msg" class="alert alert-light alert-with-icon animated fadeInDown col-md-4" >
            <div class="row">
                <div class="col-md-3">
                    <lottie-player src="/assets/images/lotties/15426-warning-animation.json"  background="transparent" class="lottie-alert" autoplay v-if="$store.state.app_msg.type == 'alert'"></lottie-player> 
                    <lottie-player src="/assets/images/lotties/20899-trash-clean.json"  background="transparent" class="lottie-trash" autoplay v-if="$store.state.app_msg.type == 'trash'"></lottie-player> 
                    <lottie-player src="/assets/images/lotties/77684-check-orange.json"  background="transparent" class="lottie-checked" autoplay v-if="$store.state.app_msg.type == 'default'"></lottie-player> 
                </div>
                <div class="col-md-9">
                <div class="">
                        <h6>{{ $store.state.app_msg.title }}</h6 >
                        <p class="text-msg">{{ $store.state.app_msg.text }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
#black-screen{
    position: fixed; 
    width:100%;
    height:100%;
    z-index: 9999999; 
    top: 0px; 
    left: 0px;
    right: 0px;
    padding:10px;
    background-color:#000;
    opacity:.5;
}
#alert-msg{
    margin: 0 auto; 
    position: fixed; 
    z-index: 999999999; 
    top: 80px; 
    left: 0px;
    right: 0px;
    padding:10px;
    border:1px solid whitesmoke;
    background-color:#ffffff;
    h6{
        margin-top:20px;
        font-weight: 500;
    }
    p{
        font-weight: 300;
        font-size:14px;
    }
}
lottie-player{
    position:relative;
    width:90px;
    float:left;
}
.lottie-trash{
    width:100px;
}

</style>