<template>
    <div>
        <label>Identified User As  <small class="text-danger" >*</small></label>
        <select  class="form-control" v-model="selected"  @change="ChooseData()" >
            <option value="all" >--  --</option>
            <option v-for="(items, idx) in model" :key="idx" :value="idx">{{ items.name }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectIdentity',
    props : ['id'],
    
    data() {
        return {
            selected : 'all',
            model : []
        }
    },
    created(){
        this.DataQuery();
    },
    methods:
    {
        async DataQuery()
        {
            const query = await this.ServiceApiPost({url:'privileges/data/all'});
            if(query){ 
                this.model = query; 
                if(this.id){
                    for(let key in this.model){
                        if(this.model[key].id == this.id){
                            this.selected = key;
                        }
                    }
                }
            }
        }, 
        ChooseData()
        {
            let model = this.model[this.selected];
            this.$emit('ChooseData', model)
        }
    }
}
</script>