<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Supplier
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data supplier produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Nama Supplier <small class="text-danger">*</small></label>
                                        <input type="text" class="form-control" placeholder="...." v-model="model.supplier_name">
                                        <small class="text-danger" v-if="service_form_error.supplier_name">{{ service_form_error.supplier_name[0] }}</small>
                                    </div>
                                    <div class="form-group row ">
                                        <div class="col">
                                            <label>No Telepon  <small class="text-danger">*</small></label>
                                            <input type="text" class="form-control" placeholder="...." v-model="model.phone_number">
                                            <small class="text-danger" v-if="service_form_error.phone_number">{{ service_form_error.phone_number[0] }}</small>
                                        </div>
                                        <div class="col">
                                            <label>Email </label>
                                            <input type="text" class="form-control" placeholder="...." v-model="model.email">
                                            <small class="text-danger" v-if="service_form_error.email">{{ service_form_error.email[0] }}</small>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Address  <small class="text-danger">*</small></label>
                                        <textarea class="form-control" rows="5" placeholder="...." v-model="model.address"></textarea>
                                        <small class="text-danger" v-if="service_form_error.address">{{ service_form_error.address[0] }}</small>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <hr/>
                                    <div class="form-group text-center mb-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isSubmitProcess  : false,
                model: {},
            };
        },
        methods: 
        {
            ChooseProductCategory(data)
            {
                if( data.id ){
                    this.model.id_category = data.id;
                }
            },
            ChooseProductType(data)
            {
                this.model.type = data;
            },
            HandleMainImage(data)
            {
                this.model.images = data;
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'supplier/store', data :this.model, callback_url : 'supplier-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
