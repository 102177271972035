<template>
    <div>
        <label><i class="spinner-mini" v-if="isLoading"></i> Pilih Kode Level<small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select  class="form-control" v-model="level_code"  @change="ChooseData()" >
            <option value="" v-if="mode == 'form'">--  --</option>
            <option value="all" v-else>-- Semua Kode Level --</option>
            <option v-for="(items, idx) in model" :key="idx" :value="items.level_code">{{ items.level_code }}</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectLevelCode',
    props : ['mode', 'id'],
    
    data() {
        return {
            isLoading : false,
            level_code : '',
            model : []
        }
    },
    created(){
        this.DataQuery();
    },
    methods:
    {
        async DataQuery()
        {
            this.isLoading = true;
            const res = await this.ServiceApiPost({url:'user/group_level'});
            if(res)
            { 
                this.model = res.query; 
                this.isLoading = false;

                if(this.id)
                {
                    for(let key in this.model)
                    {
                        if(this.model[key].level_code == this.id){
                            this.level_code = this.model[key].level_code;
                        }
                    }
                }
            }
        }, 
        ChooseData()
        {
            this.$emit('ChooseData', this.level_code)
        }
    }
}
</script>