<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-sm" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Detail Transaksi</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="data-list">
                                <li><span class="text-secondary">Tgl.Transaksi</span><br/>  <span class=""> {{ $filters.formatDate(data_model.tgl_entri, 'DD/MM/YYYY LT') }}</span></li>
                                <li><span class="text-secondary">Kode Transaksi</span><br/> <span class=""># {{ data_model.kode }}</span></li>
                                <li><span class="text-secondary">Status </span><br/> <span :class="GenerateClassStatus(data_model.status)">{{ data_model.status }}</span></li>
                                <li><span class="text-secondary">Produk </span><br/> <span class="">{{ $filters.ucwords(data_model.nama_produk) }}</span></li>
                                <li><span class="text-secondary">Harga </span><br/> <span class="">Rp {{ $filters.formatNumber(data_model.harga) }}</span></li>
                                <li><span class="text-secondary">Kirim Ke (No.Handphone)</span><br/> <span class="">{{ $filters.formatPhone(data_model.tujuan) }}</span></li>
                                <li v-if="data_model.keterangan !== null"><span class="text-secondary">Keterangan</span><br/> <span class="pl-2">{{ data_model.keterangan }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                model : [],
                total_loans : 0,
                total_paid : 0
            };
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
            GenerateClassStatus(status)
            {
                let class_style = '';
                if( status == 'Sukses' ){
                    class_style = 'text-success';
                }
                else if( status == 'Gagal' ){
                    class_style = 'text-danger';
                }
                else{
                    class_style = 'text-warning';
                }

                return class_style;
            },
        }

    }
</script>