<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Downline 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola downline reseller.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
            <div class="col-xl-12" v-else>
                <div class="card mb-4">
                    

                    <!--<div class="card-header">
                        <div class="table-search">
                            <a href="javascript:;" class=" ml-2">
                                <div class="input-group input-group-joined input-group-solid">
                                    <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                    <div class="input-group-text">
                                        <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                        <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                    </div>
                                    <div class="box" v-if="mixsearch">
                                        <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                        <label class="text-secondary">Pilih Target Pencarian</label>
                                        <select v-model="mixmeta_data.skey">
                                            <option value=""> -- --</option>
                                            <option value="reseller.kode">Kode Sales</option>
                                            <option value="reseller.nama">Nama</option>
                                            <option value="reseller.nama_pemilik">Nama Pemilik</option>
                                            <option value="reseller.kode">Alamat</option>
                                            <option value="pengirim.pengirim">No.Tlp</option>
                                        </select>
                                        <div class="group-input">
                                            <label class="text-secondary">Masukan Keyword Pencarian</label>
                                            <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                            <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                            <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                        </div> 
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>-->
                    <div class="card-body">
                        <div class="card-custom">
                            <div class="profile">
                                <ul>
                                    <li>
                                        <ImageSmCircle :photo="model_upline.photo_selfi"/>
                                    </li>
                                    <li>
                                        <div class="ml-2">
                                            <span class="text-secondary">Reseller</span><br/>
                                            <span><b>{{ model_upline.code_otmx_reseller }}</b></span> - <span>{{ model_upline.name }}</span>
                                        </div>
                                    </li>
                                    <li class="border-left">
                                        <div class="ml-4 ">
                                            <span class="text-secondary">Status</span><br/>
                                            <span>{{ model_upline.status }} - {{ model_upline.data_status }}</span>
                                        </div>
                                    </li>
                                </ul>
                                <div class="line" v-if="data_bind.length > 0"></div>
                            </div>
                            <div class="desc">
                                <ul>
                                    <li>
                                        <span>Downline</span>
                                        <div id="reseller"><i class="mdi mdi-account-check"></i> {{ $filters.formatNumber(data_bind.length) }} Resellers</div>
                                    </li>
                                    <li>
                                        <span>Saldo</span>
                                        <div id="saldo"><i class="mdi mdi mdi-coin"></i> {{ model_upline.saldo }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="cover-tree">
                            <ul class="tree"  v-if="data_bind.length > 0">
                                <li  v-for="(items, idx) in data_bind" :key="idx" :id="'li-'+items.code_otmx_reseller">
                                    <details>
                                        <div class="spinner-border spinner-border-sm" role="status"></div>
                                        <summary class="tg-click" :id="items.code_otmx_reseller">
                                            <span>({{ items.code_otmx_reseller }})</span> <span>{{ items.name }}</span>
                                            -
                                            <span class="text-secondary">Saldo</span> <span>(Rp {{ items.saldo }})</span>
                                            -
                                            <span class="text-secondary">Level</span> : <span>{{ items.level_code }}</span>
                                            -
                                            <span class="text-secondary">Downline</span> : <span class="text-success"> {{ items.total_downline }} </span>

                                        </summary>
                                    </details>
                                </li>
                            </ul>
                            <div class="p-3" v-else>
                                <hr/>
                                <span><i class="mdi mdi-alert-circle-outline text-danger"></i> Data downline kosong / tidak ditemukan.</span>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    export default 
    {   
       
        data() {
            return {
                isFirstLoad : true,
                isEnablePopup : false,

                isAlreadyClick : [],
                data_bind : [],
                model_upline : {},

                // identified users special
                freseller : localStorage.getItem('QUERY_ID'),
                fprivileges : localStorage.getItem('user-id-privileges'),
            };
        },
        created()
        {
            this.UplineQuery();
        },
        methods: 
        {
            async DownlineQuery(code_otmx_reseller)
            {

                if( ! this.isAlreadyClick.includes(code_otmx_reseller) )
                {
                    let req = await this.ServiceApiPost({
                        url:'reseller-downline', 
                        data : {
                            offset : 0,
                            upline : code_otmx_reseller
                        },
                    });
                    if(req && req.result)
                    {
                        let profile = req.profile;  
                        let model = req.query;
                        var output = '';

                        if( model.length > 0 )
                        {
                            output = '<details open><summary><span>('+profile.code_otmx_reseller+')</span>  <span>'+profile.name+'</span> - <span class="text-secondary">Saldo</span> <span>(Rp '+profile.saldo+')</span> - <span class="text-secondary">Level</span> : <span>'+profile.level_code+'</span></summary>';
                            output += '<ul>';
                            for(let key in model)
                            {
                                output += '<li id="li-'+model[key].code_otmx_reseller+'"><details><div class="spinner-border spinner-border-sm" role="status"></div><summary class="tg-click" id="'+model[key].code_otmx_reseller+'"><span>('+model[key].code_otmx_reseller+')</span>  <span>'+model[key].name+'</span> - <span class="text-secondary">Saldo</span> <span>(Rp '+model[key].saldo+')</span> - <span class="text-secondary">Level</span> : <span>'+model[key].level_code+'</span> - <span class="text-secondary">Downline</span> : <span class="text-success">'+model[key].total_downline+'</span> </summary></detail></li>';
                            }
                            output += '</ul></details>';

                            this.isAlreadyClick.push(code_otmx_reseller);
                            $('#li-'+code_otmx_reseller).html(output);

                            setTimeout(() => {
                                this.InitialTrigger();
                            }, 500);
                            
                        }else{
                            $('#li-'+code_otmx_reseller).find('.spinner-border').hide();
                        }
                    }
                }
            },
           
            InitialTrigger()
            {
                let self = this;
                $('.tg-click').click(function()
                {
                    let  code_otmx_reseller = $(this).attr('id');
                    self['DownlineQuery'].call(window, code_otmx_reseller);
                });
            },
            async UplineQuery()
            {
                let res = await this.ServiceApiPost({
                    url:'reseller-downline', 
                    data : {
                        offset : 0,
                        upline : this.freseller
                    },
                });
                if(res && res.result){
                     
                    this.model_upline   = res.profile;
                    this.data_bind      = res.query; 
                    this.isFirstLoad    = false;
                    setTimeout(() => {
                        this.InitialTrigger();
                    }, 500);
                }
            },
        }
    }
</script>


<style lang="scss">

.card-custom{
    position:relative;
   
   .profile{
        float:left;
        padding-bottom:10px;
        ul{
            width: 100%;
            float: left;
            margin: 0;
            padding: 0;
            li{
                float: left;
                list-style: none;
                
                .circle-images {
                    border:3px solid #ff9898;
                    div.img i{
                        top:4px;
                    }
                }
            }
            li.border-left{
                border-left:1px dashed #cdcdcd !important;
                margin-left:22px;
            }
        }   
        .line{
            position:absolute;
            left:25px;
            top:50px;
            height:50px;
            width:5px;
            border-left:2px dashed #bebebe;
        }
    }

    .desc{
        float:right;
        position:relative;
        top:-5px;
        ul{
            padding:0;
            margin:0;
            li{
                float:left;
                list-style:none;
                padding-left:20px;
                
                div#reseller, div#saldo{
                    padding:2px 10px 2px 10px;
                }
                div#reseller{
                    background-color:#fce7b8;
                    border-left:2px solid #f0d08a;
                }
                div#saldo{
                    background-color:#d6ecce9c;
                    border-left:2px solid #d1cda0;
                }
               
            }
        }
    }

}


.cover-tree{
    width:100%;
    height:500px;
    overflow:scroll;
    float:left;
    padding-bottom:50px;

    .tree{
        --spacing : 1.5rem;
        --radius  : 10px;
        position:relative;
        left:-13px;
        top:40px;
        
        li{
            padding      : 0px 0px 10px 0px;
            display      : block;
            position     : relative;
            padding-left : calc(2 * var(--spacing) - var(--radius) - 2px);

            .spinner-border-sm{
                position: absolute;
                z-index: 2;
                left: 15px;
                top: 4px;
                width:16px;
                height:16px;
                color:#fff;
            }
        }
        ul{
            margin-left  : calc(var(--radius) - var(--spacing));
            padding-left : 0;
            li{
                border-left : 2px solid #bebebe;
            }
            li:last-child{
                border-color : transparent;
            }
            li::before{
                content      : '';
                display      : block;
                position     : absolute;
                top          : calc(var(--spacing) / -2);
                left         : -2px;
                width        : calc(var(--spacing) + 2px);
                height       : calc(var(--spacing) + 1px);
                border       : solid #bebebe;
                border-width : 0 0 2px 2px;
            }
        }

        li::after,
        summary::before{
            content       : '';
            display       : block;
            position      : absolute;
            top           : calc(var(--spacing) / 2 - var(--radius));
            left          : calc(var(--spacing) - var(--radius) - 1px);
            width         : calc(2 * var(--radius));
            height        : calc(2 * var(--radius));
            border-radius : 50%;
            background    : #bebebe;
        }
        summary::before{
            z-index    : 1;
            background : #ee7047 url('/assets/images/expand-collapse.svg') 0 0;
        }

        details[open] > summary::before{
            background-position : calc(-2 * var(--radius)) 0;
        }
        summary{
            display : block;
            cursor  : pointer;
            padding-left: 10px;
            margin-bottom: 10px;
            .delimiter{
                width:1px;
                border-left: 1px dashed rgb(193, 193, 193);
                margin-left:15px;
                margin-right:15px;
            }
        }
        summary::marker,
        summary::-webkit-details-marker{
            display : none;
        }
        summary:focus{
            outline : none;
        }
        summary:focus-visible{
            outline : 1px dotted #000;
        }
    }
    
}

/*
ul.tree{
    float:left;
    width:100%;
    position:relative;
    left: -27px;
    top: -10px;
    li{
        list-style:none;
        padding:10px;
        font-size:0.875rem;
        font-weight:500;
        float:left;
        width:100%;
        max-height:400px;
        overflow-y:scroll;
        .circle-images {
            float:left;
            text-align:center;
            div.img {
                text-align:center;
                i{
                    top:4px;
                }
            }
        }
        .desc{
            float:left;
            margin-left:10px;
            padding-right:15px;
            border-right:1px dashed #d5d5d5;
            width:30%;

        }
        .subdesc{
            float:left;
            padding-left:10px;
        }
    }
    
}
*/
</style>
