<template>
    <main>
        <Detail :data_model="model_detail" v-on:closePopup="closePopup" v-if="isEnablePopup == 'detail'"/>
        <Privileges :data_model="model_detail" v-on:closePopup="closePopup" v-if="isEnablePopup == 'privileges'"/>
        <header class="">
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        User Aplikasi
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola pengguna aplikasi dan mengatur privileges group ( Hak akses terhadap fitur aplikasi ).</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <!-- component filter table -->
                        <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter " >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="customer_register.status">Status Member</option>
                                            <option value="users.name">Nama & Level</option>
                                            <option value="privileges.name">Privileges Access</option>
                                            <option value="users.created_at">Tgl.Dibuat</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-filter">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Filter <br/><small class="text-secondary">Filter data berdasarkan pilihan group.</small></label>
                                    <div class="form-group">
                                        <SelectPrivileges v-on:ChooseData="ChoosePrivileges"  />
                                    </div>
                                    <div class="form-group">
                                        <SelectStatusCustomer v-on:ChooseData="ChooseStatusCustomer"  />
                                    </div>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="users.name">Nama</option>
                                            <option value="users.code_otmx_reseller">Kode Reseller</option>
                                            <option value="users.created_at">Tgl.Dibuat</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>

                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center">Action</th>
                                        <th class="text-center">Photo</th>
                                        <!--<th :class="mixmeta_data.okey == 'customer_register.status' ? 'active' : ''">Status Member</th>-->
                                        <th :class="mixmeta_data.okey == 'users.name' ? 'active' : ''">Nama & Level</th>
                                        <th :class="mixmeta_data.okey == 'privileges.name' ? 'active' : ''">Privileges Access</th>
                                        <th :class="mixmeta_data.okey == 'users.created_at' ? 'active' : ''">Tgl.Dibuat</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="SetupPrivileges(idx)" v-if="$store.state.privileges_modules.uam.includes('setup_privileges')">Setting Privileges</a>
                                                    <a href="javascript:;" class="dropdown-item" @click="DataDetail(items.code_otmx_reseller)"  v-if="$store.state.privileges_modules.uam.includes('verifikasi')">Detail & Verifikasi</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="Block(items.id)" v-if="items.status !== 'block' && $store.state.privileges_modules.uam.includes('block_user')">Block User</a>
                                                    <a href="javascript:;" class="dropdown-item text-success" @click="UnBlock(items.id)"  v-if="items.status == 'block' && $store.state.privileges_modules.uam.includes('unblock_user')">Un-Block User</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center" width="80">
                                            <ImageSmCircle :photo="items.photo_selfi"/>
                                        </td>
                                        <!--<td width="170">
                                            <span> <i class="mdi mdi-account-settings"></i>  {{ items.status !== null ?  items.status : '-- --' }}</span><br/>
                                            <span class="">{{ items.data_status }}</span>
                                        </td>-->
                                        <td>
                                            <span class="text-secondary">{{ items.code_otmx_reseller }}</span> - {{ items.name }}<br/>
                                            Kode Level ( {{ items.level_code }} )
                                        </td>
                                        <td><b>{{ items.privileges_name !== null ? items.privileges_name : '-- --' }}</b></td>
                                        <td>{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                                <tbody v-if="mixmodel.data && mixmodel.total < 1">
                                    <tr>
                                        <td colspan="9">
                                            <div class="p-2 text-danger" >Data kosong / tidak ditemukan !</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import Detail from './Detail';
    import Privileges from './Privileges';

    export default {
        components: {Detail, Privileges},
        data() {
            return {
                isFirstLoad : true,
                isEnableFilter : false,
                isEnablePopup : false,
                model_detail  : {},
                meta_filter :{ id_privileges : 'all', status : 'all' }
            }
        },
        created()
        {
            // Global Search
            if( localStorage.getItem('global-search') )
            {
                let model = JSON.parse(localStorage.getItem('global-search'));
                this.mixmeta_data.skey = model.search_key;
                this.mixmeta_data.sval = model.search_val;
                localStorage.removeItem('global-search');
            }

            this.mixmeta_data.okey = 'users.created_at';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            closePopup(refresh_query)
            {
                this.isEnablePopup = false;
                if(refresh_query)
                {
                    this.DataQuery();
                }
            },
            ChooseStatusCustomer(data)
            {
                this.meta_filter.status = data;
            },
            ChoosePrivileges(data)
            {
                this.meta_filter.id_privileges = data ? data.id : 'all';
            },
            DataDetail(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'user-detail'});
            },
            SetupPrivileges(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'privileges';
            },
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'user-update'});
            },
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'user/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'users.id_privileges', val:this.meta_filter.id_privileges},
                        {key : 'customer_register.status', val:this.meta_filter.status }
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isRefreshTable = false;
                    this.isFirstLoad = false;
                }
            }, 
            async Block(id) 
            {
                if(confirm('Apakah anda yakin ingin melakukan block user  ? sistem akan secara auto melakukan logout aplikasi mobile dan mengirimkan fcm informasi.'))
                {
                    let res = await this.ServiceApiPost({ action : 'post', url: 'user/block', data : {userid : id}});
                    if(res && res.result){
                        this.DataQuery()
                    }
                }
            },
            async UnBlock(id) 
            {
                if(confirm('Apakah anda yakin ingin melakukan un-block user ?.'))
                {
                    let res = await this.ServiceApiPost({ action : 'post', url: 'user/un-block', data : {userid : id}});
                    if(res && res.result){
                        this.DataQuery()
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
span.app-status{
    font-size: 11px;
    padding:4px 10px 4px 10px;
    width:100%;
    margin-top:5px;
    margin-bottom:5px;
    text-align:left;
    border-radius:2px;
    i{color:#f6f6f6;}

}

span.badge-info{
    background-color:rgb(135, 218, 249);
}
span.badge-success{
    background-color:#aff990;
}
span.badge-danger{
    background-color:rgb(219, 92, 92);
}
</style>