<template>

    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"><i class="fas fa-user"></i></div>
                                User
                            </h1>
                            <div class="page-header-subtitle">Example dashboard overview and content summary</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <form @submit="Submit">
            <div class="row">
                <div class="col-xl-4">
                    <div class="card">
                        <div class="card-body text-center">
                            <ImageMdCircle :photo="model.photo" class="mt-5"/>
                            <div class="col-md-12 mt-3 text-center p-0">
                                <div class="form-group">
                                    <label for="file" class="btn btn-light">
                                        <input type="file" id="file" class="input-file" accept="image/*"  @change="ChangeImage($event)">
                                        <span><i class="mdi mdi-camera text-primary"></i> Change Image</span>
                                    </label>
                                </div>
                                <div class="form-group text-left p-0">
                                    <small class="text-secondary">- Upload file image type (jpeg, jpg, png)</small><br/>
                                    <small class="text-secondary">- Max file image size : 2MB</small><br/>
                                    <small class="text-secondary">- Minimum size of dimensions (100 x 100px)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-8">
                    <!-- Account details card-->
                    <div class="card mb-4">
                        <div class="card-header">Account Details</div>
                        <div class="card-body">
                            <div class="form-group" v-if="isFinishLoad" >
                                <SelectPrivileges v-on:ChooseData="ChoosePrivileges" :id="model.id_privileges" :mode="'form'" />
                                <small class="text-danger" v-if="service_form_error.id_privileges">{{ service_form_error.id_privileges[0] }}</small>
                            </div>
                            <div class="form-group mt-2">
                                <label class=""> Fullname</label>
                                <input type="text" class="form-control"  placeholder="...." v-model="model.fullname">
                                <small class="text-danger" v-if="service_form_error.fullname">{{ service_form_error.fullname[0] }}</small>
                            </div>
                            <div class="form-group">
                                <label class="">Email Address</label>
                                <small class="">Email address email will be use for authenticate "Username" when the user login.</small>
                                <input type="email" class="form-control"  placeholder="...." v-model="model.email">
                                <small class="text-danger" v-if="service_form_error.email">{{ service_form_error.email[0] }}</small>
                            </div>
                            <div class="form-group">
                                <label class=""> Identity Card Number (KTP)</label>
                                <input type="text" class="form-control"  placeholder="...." v-model="model.identity_card_number">
                                <small class="text-danger" v-if="service_form_error.identity_card_number">{{ service_form_error.identity_card_number[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-5">
                        <div class="text-center">
                            <a href="javascript:;" class="btn btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                            <ButtonProcess :type="'Submit'" :title="'Save Changes'" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </main>
</template>


<script>
    export default 
    {   
        data() {
            return {
                isFinishLoad : false,
                model:{},
                file :{
                    min_width :100,
                    min_height :100,
                    max_size : 2097152,
                }
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
            async DataQuery()
            {
                let req = await this.ServiceApiPost({url:'user/profile', data:{userid : localStorage.getItem('QUERY_ID')}});
                if(req && req.result)
                {
                    this.model = req.query;
                    if(this.model.photo !== null){
                        this.model.photo = this.$store.state.api_resource + this.model.photo;
                    }else{ this.model.photo  == '' }
                    this.isFinishLoad = true;
                }
            },

            ChoosePrivileges(data)
            {
                this.model.id_privileges = data.id;
            },
            ChangeImage(event)
            {
                this.file = event.target.files[0];
                if (event.target.files && event.target.files[0])
                {
                    let fileSize = event.target.files[0].size;
                    if( parseInt(fileSize) > this.file.max_size ){
                        alert('File image cannot more than 2 MB');
                    }else
                    {
                        let reader = new FileReader();
                        reader.onload = (event) =>
                        {
                            let img = new Image;
                            img.onload = () =>{

                                if( img.width < this.file.min_width && img.height < this.file.min_height )
                                {
                                    event.preventDefault();
                                    alert('Minimum image dimenssion : ' + this.file.min_width + 'x' + this.file.min_height);
                                    return false;
                                }else{
                                    // parsing   
                                    this.model.photo = event.target.result;
                                }
                            }
                            img.src = event.target.result;
                        }
                        reader.readAsDataURL(event.target.files[0]);
                    }
                }
            },
            async Submit(e) 
            {
                e.preventDefault();

                let model_photo = this.model.photo;
                if( model_photo !== null ){
                    this.model.photo = model_photo.replace(this.$store.state.api_resource, '');
                }
                await this.ServiceApiPost({  action : 'post', url:'user/update', data: this.model, callback_url : 'user-data'  });
            }
        }
    }
</script>