<template>
    <main>
        <BrowseSales v-on:closePopup="closeBrowseSales"  v-if="isEnablePopup == 'browse-sales'"/>
        <DetailLoans :data_model="model_detail" v-on:closePopup="closeDetailLoans"   v-if="isEnablePopup == 'detail-loans'"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Summary Hutang 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Data summary hutang.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'product_category-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header ">
                        <div class="row" >
                            <div class="col-md-8" v-if="$store.state.privileges_modules.uam.includes('filter_sales')">
                                <div class="card-profile-tab  mt-2" >
                                    <a href="javascript:;" @click="isActiveFilterTab = 'search'" :class="isActiveFilterTab == 'search' ? 'active' : ''">Search</a>
                                    <a href="javascript:;" @click="isActiveFilterTab = 'input'" :class="isActiveFilterTab == 'input' ? 'active' : ''">Input</a>
                                </div>
                                <div class="card-profile">
                                    <ul v-if="isActiveFilterTab == 'search'">
                                        <li  v-if="model_upline.name">
                                            <ImageSmCircle :photo="model_upline.photo_selfi_id" />
                                        </li>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="mb-0">{{ model_upline.name ? model_upline.name :  'Semua Sales' }}</span><br/>
                                                <span class="text-secondary">{{ model_upline.code_otmx_reseller ? model_upline.code_otmx_reseller + '-' +model_upline.level_code :  'Gunakan "Filter Reseller" untuk melihat transaksi reseller.'}} </span>
                                            </div>
                                            <div class="float-left border-left" v-if="model_upline.name">
                                                <span class="mb-0"><b>Status Member</b></span><br/>
                                                <span class="text-secondary">{{ model_upline.status !== null ? 'Tervalidasi : ' + model_upline.status :  '! Data belum tervalidasi.'}} </span>
                                            </div>
                                            <div class="float-left border-left pt-2" >
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Sales'"  @click="isEnablePopup = 'browse-sales'"/>
                                                &nbsp;&nbsp;
                                                <a href="javascript:;" @click="ResetFilterSales()" v-if="model_upline.name"><i class="mdi mdi-close"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul v-else>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="text-secondary">Ketikan "Kode Reseller" untuk melihat transaksi reseller</span>
                                                <input type="search" class="form-control" placeholder="..." v-model="input_code_reseller"/>
                                            </div>
                                            <div class="float-left border-left pt-3" >
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Reseller'"  @click="InputFilterReseller()"/>
                                                &nbsp;&nbsp;
                                                <a href="javascript:;" @click="ResetFilterSales()" v-if="input_code_reseller !== ''"><i class="mdi mdi-close"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <ul class="data-list">
                                    <li><span>Total Hutang</span> <span class="float-right">Rp{{ $filters.formatNumber(total_loans) }}</span></li>
                                    <li><span>Total Pembayaran</span> <span class="float-right">Rp  <span>{{ $filters.formatNumber(total_paid) }}</span></span></li>
                                    <li><span>Sisa Hutang</span> <span class="float-right">Rp <b>{{ $filters.formatNumber(total_loans - total_paid) }}</b></span></li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div class="card-body">
                          <!-- component filter table -->
                          <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter " >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="reseller.kode_upline">Kode Upline</option>
                                            <option value="reseller.kode">Kode Reseller</option>
                                            <option value="reseller.nama">Nama Downline</option>
                                            <option value="reseller.alamat">Alamat Downline</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-filter">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Filter <br/><small class="text-secondary">Filter data berdasarkan pilihan group.</small></label>
                                    <div class="form-group">
                                        <label>Tanggal Akhir</label>
                                        <input type="date" class="form-control"  v-model="enddate"  :max="max_date" id="endDate"/>
                                    </div>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="reseller.kode_upline">Kode Upline</option>
                                            <option value="reseller.kode">Kode Downline</option>
                                            <option value="reseller.nama">Nama Downline</option>
                                            <option value="reseller.alamat">Alamat Downline</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-left">
                                <span><i class="mdi mdi-calendar-range"></i> Periode s/d Tanggal :</span> <span class="text-secondary"> {{ $filters.formatDate(enddate, 'DD/MM/YYYY') }}</span>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="!isRefreshTable" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center">Action</th>
                                        <th class="text-center">Photo</th>
                                        <th :class="mixmeta_data.okey == 'reseller.kode_upline' ? 'active' : ''">Upline Reseller</th>
                                        <th :class="mixmeta_data.okey == 'reseller.nama' ? 'active' : ''">Downline Reseller</th>
                                        <th :class="mixmeta_data.okey == 'reseller.alamat' ? 'active' : ''">Alamat Downline</th>
                                        <th>Total Hutang</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="btn btn-sm btn-outline-orange" @click="DataDetail(idx)"><i class="fas fa-file-alt mr-1 text-success"></i> Detail Hutang</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <ImageSmCircle :photo="items.photo"/>
                                        </td>
                                        <td width="170">
                                            {{ items.owner_name }}<br/>
                                            <span class="text-secondary">{{ items.kode_upline }}</span>
                                        </td>
                                        <td width="170">
                                            {{ $filters.ucwords(items.name) }}<br/>
                                            <span class="text-secondary">{{ items.code }}</span>
                                        </td>
                                        <td width="400">{{ items.alamat }}</td>
                                        <td width="200">Rp <span class="text-danger">{{ $filters.formatNumber(items.total_loans) }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import BrowseSales from '../Resellers/BrowseSales';
    import DetailLoans from './DetailLoans';
    import moment from "moment";

    export default {
        components: {
            BrowseSales,
            DetailLoans
        },
        data() {
            return {
                isFirstLoad : true,
                isEnableFilter : false,
                isEnablePopup : false,
                isActiveFilterTab : 'search',
                model_detail : {},
                model_upline : {},
                total_loans : 0,
                total_paid : 0,
                freseller : localStorage.getItem('user-code'),
                fprivileges : localStorage.getItem('user-privileges'),
                input_code_reseller : '',

                enddate : new Date().toISOString().slice(0,10),
            }
        },
        created()
        {
            this.IdentifiedUserQuery()
            this.mixmeta_data.okey = 'reseller.nama';
            this.mixmeta_data.oval = 'asc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            IdentifiedUserQuery()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }
            },
            ResetFilterSales()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }

                this.input_code_reseller = '';
                this.model_upline = {};
                this.DataQuery();
            },
            InputFilterReseller()
            {
                if( this.input_code_reseller == '' ){
                    alert('Silahkan masukan kode reseller.');
                    return;
                }
                this.freseller = this.input_code_reseller;
                this.DataQuery();
            },
            closeBrowseSales(data)
            {
                this.isEnablePopup = false;
                if(data)
                {
                    this.model_upline = data;
                    this.freseller = data.code_otmx_reseller;
                    this.DataQuery();
                }
            },
            closeDetailLoans()
            {
                this.isEnablePopup = false;
            },
            
            DataDetail(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.model_detail.enddate =  this.enddate;
                this.isEnablePopup = 'detail-loans';
            },
            async DataQuery(pageNum)
            {
                this.total_loans = 0;
                this.total_paid = 0;
                this.page = pageNum == undefined ? 1 : pageNum;
                this.isRefreshTable = true;
                this.isEnableFilter = false;

                if( this.isFirstLoad )
                {
                    var query_enddate = this.enddate;
                }else
                {
                    if( document.getElementById('endDate') )
                    {
                        var edate = document.getElementById('endDate').value;
                        query_enddate = moment(edate).format("DD-MM-YYYY");
                    }else{
                        query_enddate= new Date(Date.now()).toISOString().slice(0,10);
                    }
                }

                let res = await this.ServiceApiDataPaging({
                    url:'reseller-loans', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'reseller.kode_upline', val : this.freseller},
                        {key : 'end_date', val : query_enddate}
                    ],
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.total_loans = res.total_loans;
                    this.total_paid = res.total_paid;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }
        }
    }
</script>
