<template>
    <div class="clipbox ">
        <h3><i class="mdi mdi-information-outline text-warning"></i> Download</h3>
        <span>Data akan di unduh dalam format (.csv)</span>
       <!--<ul class="mt-3 ">
            <li><a href="javascript:;" id="xlsx" :class="format == 'xlsx' ? 'active' : ''"  @click="format = 'xlsx'"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
            <li><a href="javascript:;" id="pdf" :class="format == 'pdf' ? 'active' : ''"  @click="format = 'pdf'"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
        </ul>
        <br/>-->
        <div id="sent-mail">
            <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
            <br/>
            <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
            <div>
                <i class="mdi mdi-email-open-outline"></i>
                <input type="email" class="form-control" v-model="email" placeholder="Masukan Alamat Email"/>
            </div>
        </div>
        <ButtonProcess :classes="'btn btn-full  animate__animated animate__flipInX'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'UNDUH SEKARANG'" @click="Download()"/>
    </div>
</template>
<script>
   export default 
   {
        name : 'Download',
        props : ['init_url'],
        data() {
          return {
                isSubmitProcess : false,
               format : 'csv',
               email : '',
           }
        },
        methods:
        {
            async Download()
            {
                this.isSubmitProcess = true;
                const response = await this.ServiceApiPost({url:'reseller-download', data : {
                    format : this.format
                }});
                if(response)
                { 
                    const filename = 'reseller.'+this.format;
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    this.isSubmitProcess = false;
                }
            }, 
        }
    }
</script>