<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Cut Off Mutasi 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola data cut-off mutasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <!-- component filter table -->
                        <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter " >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="inquiry_cutoff.code_otmx_reseller">Kode Sales</option>
                                            <option value="inquiry_cutoff.code_otmx_reseller_2">Kode Reseller</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="inquiry_cutoff.code_otmx_reseller">Kode Sales</option>
                                            <option value="inquiry_cutoff.code_otmx_reseller_2">Kode Reseller</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th>Photo</th>
                                        <th :class="mixmeta_data.okey == 'inquiry_cutoff.code_otmx_reseller' ? 'active' : ''">Sales</th>
                                        <th :class="mixmeta_data.okey == 'inquiry_cutoff.code_otmx_reseller_2' ? 'active' : ''">Reseller</th>
                                        <th :class="mixmeta_data.okey == 'position' ? 'active' : ''">Total Jumlah</th>
                                        <th>Tgl.Update</th>
                                    </tr>
                                </thead>
                                <tbody  v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td  width="80">
                                            <ImageSmCircle :photo="items.photo" :initial="'sales'"/>
                                        </td>
                                        <td width="120">
                                            {{ items.sales_name }}<br/>
                                            <span class="text-secondary">{{ items.code_otmx_reseller }}</span>
                                        </td>
                                        <td width="">
                                            {{ items.code_otmx_reseller_2_name }}<br/>
                                            <span class="text-secondary">{{ items.code_otmx_reseller_2 }}</span>
                                        </td>
                                        <td width="150">Rp {{ $filters.formatNumber(items.total_amount) }}</td>
                                        <td width="120">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    export default {
      
        data() {
            return {
                isEnableFilter : false,
                isFirstLoad : true,
                model : {},
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'inquiry_cutoff.code_otmx_reseller';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'cutoff/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval }
                });
                if(res)
                {
                    this.mixmodel = res.query;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
        }
    }
</script>
