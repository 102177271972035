<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-sm" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Privileges</h5>
                    <small></small>
                    <button class="btn btn-sm btn-close" type="button" @click="ClosePopup()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6">
                            <p>Perubahan group privileges akan mempengaruhi akses terhadap fitur aplikasi.</p>
                        </div>
                        <div class="col-md-6">
                                <SelectPrivileges v-on:ChooseData="ChoosePrivileges" :id="data_model.id_privileges" :mode="'form'" />
                        </div>
                        <div class="col-md-12">
                            <hr/>
                            <div class="card-profile">
                                <ul>
                                    <li>
                                        <ImageSmCircle :photo="data_model.photo_selfi"/>
                                    </li>
                                    <li>
                                        <div class="ml-2">
                                            <span class="text-secondary">Kode Reseller  : </span><span>{{ data_model.code_otmx_reseller }}</span><br/>
                                            <span class="text-secondary">Nama  : </span><span>{{ data_model.name }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                    </div>
                </div>
                <div class="modal-footer"  >
                    <ButtonProcess :classes="'btn btn-sm btn-warning float-right'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Pengaturan'" @click="Save()"/>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isSubmitProcess : false,
                id_privileges : ''
            };
        },
       
        methods: 
        {
            ClosePopup(initial = '')
            {
                this.$emit('closePopup', initial);
            },
            ChoosePrivileges(data)
            {
                this.id_privileges = data ? data.id : 'all';
            },
            async Save()
            {
                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'user/setup_privileges', data : { id_privileges : this.id_privileges, id_user : this.data_model.id }});
                if(res && res.result)
                {
                    this.isSubmitProcess = false;
                    this.ClosePopup('refresh');
                }else{
                    alert('Penolakan update sistem, privileges yang sama tidak diperbolehkan.')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.card-profile{
    width:100%;
}
#box-info{
    padding:15px;
    background-color:#e4ffef;
}
.profiles{
    overflow-y:scroll;
    height:500px;
    padding:20px;

    img{
        position:relative;
        top:15px;
        border:1px solid whitesmoke;
        width:100%;
        border-radius:3px;
    }

    ul{
        padding:0;
        margin:0;
        li{
            width:100%;
            list-style:none;
            padding:10px;
            border-bottom:1px solid whitesmoke;
            font-size:12px;
        }
    }
}
</style>
