<template>
    <div>
        <label>Produk Tipe <small class="text-danger" v-if="mode == 'form'">*</small></label>
        <select class="form-control" v-model="selected" @change="ChooseData()">
            <option value="all">-- Semua Tipe --</option>
            <option value="accessories">Accessories</option>
            <option value="voucher">Voucher</option>
        </select>
    </div>
</template>
<script>
export default {
    name: 'SelectProductTypeNonDigital',
    props:['id'],
    data() {
        return {
            selected : 'all',
        }
    },
    created(){
        if( this.id ){
            this.selected = this.id;
        }
       
    },
    methods:
    {
        ChooseData()
        {
            this.$emit('ChooseData', this.selected);
        }
    }
}
</script>