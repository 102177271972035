<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Detail Akun 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Data foto selfie, foto identitas serta informasi informasi umum.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
        <div class="row" v-else>
            <div class="col-xl-4">
                <div class="card p-4">
                    <div class="mt-3">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Foto Selfie </label>
                        <div class="pic-box"><img :src="model.photo_selfi_id !== null ? model.photo_selfi_id  : '/assets/images/no-image.png'"/></div>
                    </div>

                    <div class="mt-3">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Foto Identitas KTP</label>
                        <div class="pic-box"><img :src="model.photo_identity_id  !== null ? model.photo_identity_id  : '/assets/images/no-image.png'" /></div>
                    </div>
                </div>
                
            </div>
            <div class="col-xl-8">
                <!-- Account details card-->
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="box-data mb-2">
                            <label><i class="mdi mdi-information-outline text-warning"></i>  Informasi Umum <br/><small class="text-secondary">Informasi umum tentang akun data.</small></label>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Tgl.Daftar</span>
                            <span class="float-right">{{ $filters.formatDate(model.register_date, 'DD/MM/YYYY') }} - <span class="text-secondary">{{ $filters.formatDate(model.register_date, 'LT') }}</span></span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Member Status</span>
                            <span class="float-right">{{ model.status }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Data Status</span>
                            <span class="float-right">{{ GenerateDataStatus(model.data_status) }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Kode Level</span>
                            <span class="float-right">{{ model.level_code }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> No. Telepon</span>
                            <span class="float-right">{{ model.phone }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Kode Reseller</span>
                            <span class="float-right">{{ model.code_otmx_reseller }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Nama Konter</span>
                            <span class="float-right">{{ model.counter_name }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Alamat Konter</span>
                            <span class="float-right">{{ model.address !== null  ? model.address : '-- --'}}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary"> Nama Pemilik</span>
                            <span class="float-right">{{ model.owner_name }}</span>
                        </div>
                        <div class="box-data">
                            <span class="text-secondary">Device Mobile</span>
                            <span class="float-right">{{ $filters.ucwords(model.device_model) }}</span>
                        </div>
                    </div>
                </div>

            </div>
            <hr/>
            <div class="col-md-12">
                <div class="card p-4">
                    <div id="box-info">
                        <label><i class="mdi mdi-information-outline text-warning"></i>  Verifikasi Data !</label><br/>
                        <span class="text-secondary">Silahkan tentukan pilihan berikut berdasarkan penyesuaian data.</span>
                        <hr/>
                        <div class="mb-1"><input type="radio" value="4" v-model="verification_data"/> Data Belum Lengkap</div>
                        <div class="mb-1"><input type="radio" value="5" v-model="verification_data"/> Foto Selfie Tidak Jelas</div>
                        <div class="mb-1"><input type="radio" value="6" v-model="verification_data"/> Foto KTP Tidak Jelas</div>
                        <div class="mb-1"><input type="radio" value="7" v-model="verification_data"/> Foto Selfie & KTP Tidak Jelas</div>
                        <div class="mb-1"><input type="radio" value="3" v-model="verification_data"/> OK, Data Lengkap & Terverifikasi</div>
                    </div>
                </div>
                <div class="form-group text-center mt-5">
                    <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                    <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'" @click="Save()"/>
                </div>
            </div>
           
        </div>


    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isFirstLoad : true,
                model:{},
                
            };
        },
        created()
        {
            this.DataQuery();
        },
        methods: 
        {
            GenerateDataStatus(initial)
            {
                let idx = initial - 1;
                let status = [
                    'Belum terverifikasi',
                    'Proses pengecekan',
                    'Terverifikasi',
                    'Data belum lengkap',
                    'Foto Selfie tidak jelas',
                    'Foto KTP tidak jelas',
                    'Foto KTP & Selfie tidak jelas',
                ];

                return status[idx];
            },
            async Verification()
            {
                if(confirm('Apakah anda yakin ingin verifikasi data ?'))
                {
                    let res = await this.ServiceApiPost({ action : 'post', url: 'user/verification', data : { code_otmx_reseller : this.model.code_otmx_reseller, data_status : this.verification_data }});
                    if(res && res.result)
                    {
                       this.ClosePopup();
                    }
                }
            },
            async DataQuery()
            {
                let req = await this.ServiceApiPost({url:'user/profile', data:{code_otmx_reseller : localStorage.getItem('QUERY_ID')}});
                if(req && req.result)
                {
                    this.model = req.query;
                    this.isFirstLoad = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
.box-data{
    float:left;
    width:100%;
    padding:10px;
    border-bottom:1px solid #fcfcfc59;
}
.pic-box{
    width:100%;
    height:210px;
    overflow:hidden;
    text-align:center;
    border:2px solid #ffffff8d;
    padding:5px;
    img{
        height:100%;
    }
}
</style>