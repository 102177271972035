<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Banner App 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola konten banner berupa gambar dan teks pada aplikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'banners-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <!-- component filter table -->
                        <div v-if="isEnableFilter">
                            <div class="slide-right-filter" >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="banners.id">ID</option>
                                            <option value="banners.position">Posisi</option>
                                            <option value="banners.status">Status</option>
                                            <option value="banners.type">Tipe</option>
                                            <option value="promo.title">Promo</option>
                                            <option value="banners.created_at">Tgl.Dibuat</option>
                                            <option value="banners.updated_at">Tgl.Update</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="banners.position">Position</option>
                                            <option value="banners.status">Status</option>
                                            <option value="banners.type">Tipe</option>
                                            <option value="promo.title">Promo</option>
                                            <option value="banners.created_at">Tgl.Dibuat</option>
                                            <option value="banners.updated_at">Tgl.Update</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter" v-if="$store.state.privileges_modules.uap">Action</th>
                                        <th :class="mixmeta_data.okey == 'banners.position' ? 'active' : ''">Position</th>
                                        <th>Photo</th>
                                        <th :class="mixmeta_data.okey == 'banners.status' ? 'active' : ''">Status</th>
                                        <th :class="mixmeta_data.okey == 'banners.type' ? 'active' : ''">Tipe</th>
                                        <th :class="mixmeta_data.okey == 'promo.title' ? 'active' : ''">Promo</th>
                                        <th :class="mixmeta_data.okey == 'banners.created_at' ? 'active' : ''">Tgl.Dibuat</th>
                                        <th :class="mixmeta_data.okey == 'banners.updated_at' ? 'active' : ''">Tgl.Update</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center" v-if="$store.state.privileges_modules.uap">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="DataUpdate(items.id)" v-if="$store.state.privileges_modules.uam.includes('update')">Edit</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)" v-if="$store.state.privileges_modules.uam.includes('delete')">Remove</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="70">
                                            <select class="select-on-table"  v-if="items.type == 'promo-banner' || items.type == 'promo-h2h'" :id="items.position" @change="UpdatePositionBanner($event, items.type)" >
                                                <option  v-for="(number, idx) in GetMaxTotalBanner(items.type)" :key="idx" :value="number" :selected="number == items.position ?  true : false">{{ number }}</option>
                                            </select>
                                            <span class="text-secondary" v-else>--</span>
                                        </td>
                                        <td width="100">
                                            <div class="group-data-table">
                                                <img :src="$store.state.api_resource + $filters.GetImageThumnail(items.photo)" />
                                            </div>
                                        </td>
                                        <td  width="100">
                                            <span :class="items.status == 'active' ? 'bg-border-success' : 'bg-border-warning'">{{ $filters.ucwords(items.status) }}</span>
                                        </td>
                                        <td width="150">{{ items.type }}</td>
                                        <td><div :class="items.promo_title !== null ? '' : 'text-secondary'">{{ items.promo_title !== null ? items.promo_title : '-- --' }}</div></td>
                                        <td  width="100">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        <td  width="100">
                                            <span v-if="items.updated_at !== null">
                                                {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.updated_at, 'LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isEnableFilter : false,
                isFirstLoad : true,
                isEnablePopup : false,
                active_id : '',
                model : { id : 0 },
                total_promo_banner : 0,
                meta_filter :{ type : 'all'}
            };
        },
        created()
        {
            this.ServiceGlobalSearch();

            this.mixmeta_data.okey = 'type';
            this.mixmeta_data.oval = 'asc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {   
            closePopup()
            {
                this.isEnablePopup = false;
            },
            GetMaxTotalBanner(type){
                if(type == 'promo-banner'){
                    return 5;
                }
                if(type == 'promo-h2h'){
                    return 3;
                }
            },
            async UpdatePositionBanner(event, type)
            {
                const target_position = parseInt(event.target.value);
                const item_position = parseInt(event.target.id);
            
                let res = await this.ServiceApiPost({ action : 'post', url: 'banners/position', data:
                { 
                    type : type,
                    item_position : item_position, 
                    target_position : target_position
                } });
                if(res && res.result)
                {
                    this.DataQuery();
                }
            },
            
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'banners/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'type', val:this.meta_filter.type},
                    ]
                });
                if(res)
                {
                    this.mixmodel = res.query;
                    this.total_promo_banner = res.total_promo_banner;
                    this.isRefreshTable = false;
                    this.isFirstLoad = false;
                }
            },
    
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'banners-update'});
            },
            async DataRemove(id)
            {
                if( confirm( 'Apakah anda yakin ingin menghapus data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url: 'banners/destroy', data:{id : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
.select-on-table{
    padding:3px;
    background-color:#fefefe6e;
    border:none;
}
</style>