<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Kategori Produk 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola kategori produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group ">
                                                <label>Jenis Produk Digital <small class="text-danger">*</small>  <br/><small class="text-secondary">Tentukan jenis produk.</small></label>
                                                <select class="form-control " v-model="model.product_type">
                                                    <option value="">-- --</option>
                                                    <option value="non-digital">Non Digital</option>
                                                    <option value="digital">Digital</option>
                                                    <option value="tagihan">Tagihan</option>
                                                    <option value="deposit">Deposit</option>
                                                </select>
                                                <small class="text-danger" v-if="service_form_error.product_type">{{ service_form_error.product_type[0] }}</small>
                                            </div>
                                            <div class="form-group ">
                                                <label>Tipe Listing </label>
                                                <select class="form-control " v-model="model.listing_type">
                                                    <option value="">-- --</option>
                                                    <option value="direct">Direct</option>
                                                    <option value="group">Group</option>
                                                </select>
                                                <small class="text-danger" v-if="service_form_error.product_type">{{ service_form_error.product_type[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Nama  Kategori <br/><small class="text-secondary">Masukan nama kategori produk.</small></label>
                                                <input type="text" class="form-control" placeholder="...." v-model="model.category_name">
                                                <small class="text-danger" v-if="service_form_error.category_name">{{ service_form_error.category_name[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Photo Icon  <small class="text-danger">*</small>  <br/><small class="text-secondary">Upload gambar dalam format (.png)</small></label>
                                                <UploadSingleImage :default_image="model.photo !== '' ?  model.photo : ''"  v-on:processFile="HandleMainImage" :layout="'potrait'" :layout_height="'192px'" v-if="isFinishLoad"/>
                                                <small class="text-danger" v-if="service_form_error.photo">{{ service_form_error.photo[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <hr/>
                                    <div class="form-group text-center mb-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Perubahan'" @click="Save()"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isSubmitProcess : false,
                isFinishLoad : false,
                old_photo : '',

                model: {},
            };
        },
        created() 
        {
            this.DataQuery();
        },
        methods: 
        {
            HandleMainImage(data)
            {
                this.model.photo = data;
            },
            async DataQuery()
            {
                let res = await this.ServiceApiPost({url: 'product_category/find', data: {id : localStorage.getItem('QUERY_ID')}});
                if(res)
                {
                    this.model = res.data;
                    if(this.model.photo !== null){
                        this.model.photo = process.env.VUE_APP_API_RESOUCE + this.model.photo;
                    }

                    this.isFinishLoad = true;
                }
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                if( this.model.photo !== null ){
                    this.model.photo = this.model.photo.replace(process.env.VUE_APP_API_RESOUCE, '');
                }

                let res = await this.ServiceApiPost({ action : 'post', url: 'product_category/update', data :this.model, callback_url : 'product_category-data' });
                if( res ){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
