<template>
    <main>
        <BrowseSales v-on:closePopup="closeBrowseSales"  v-if="isEnablePopup == 'browse-sales'"/>
        <DetailTrans :data_model="model_detail" v-on:closePopup="closeDetailTrans"  v-if="isEnablePopup == 'detail-trans'"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Transaksi 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data transaksi produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'product_category-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="" v-if="isEnableFilter">
                    <div class="slide-right-filter">
                        <div class="box-sorting">
                            <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                            <div class="form-group">
                                <select class="form-control" v-model="mixmeta_data.okey">
                                    <option value="transaksi.tgl_entri">Transaksi Entri</option>
                                    <option value="transaksi.kode">Kode Transaksi</option>
                                    <option value="produk.nama">Nama Produk</option>
                                    <option value="transaksi.status">Status</option>
                                    <option value="transaksi.tujuan">No.Tujuan</option>
                                    <option value="transaksi.harga">Harga</option>
                                </select>
                                <div class="sort">
                                    <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                    <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                </div>
                            </div>
                            <br/>
                            <br/>
                        </div>
                        <div class="box-filter">
                            <label><i class="mdi mdi-information-outline text-warning"></i> Filter <br/><small class="text-secondary">Filter data berdasarkan pilihan group.</small></label>
                            <div class="form-group">
                                <label>Tanggal Laporan</label>
                                <select class="form-control" v-model="fdate" @change="FilterFDate()">
                                    <option value="0">Pilih Manual</option>
                                    <option value="1">Hari Sekarang</option>
                                    <option value="7">7 Hari Terakhir</option>
                                    <option value="30">30 Hari Terakhir</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Tanggal Mulai</label>
                                <input type="date" class="form-control"  v-model="startdate"   id="startDate" :disabled="fdate !== '0' ? true  : false"/>
                            </div>
                            <div class="form-group">
                                <label>Tanggal Akhir</label>
                                <input type="date" class="form-control"  v-model="enddate" id="endDate" :disabled="fdate !== '0' ? true  : false"/>
                            </div>
                            <div class="form-group">
                                <label>Transaksi Status</label>
                                <select class="form-control" v-model="trx_status">
                                    <option value="all">-- Semua Status --</option>
                                    <option value="0">Belum Diroses</option>
                                    <option value="1">Dalam Proses</option>
                                    <option value="2">Dalam Proses</option>
                                    <option value="22">Masuk Antrian</option>
                                    <option value="20">Sukses</option>
                                    <option value="40">Gagal</option>
                                    <option value="42">Format Salah</option>
                                    <option value="43">Saldo Kurang</option>
                                    <option value="44">Kode Salah</option>
                                    <option value="45">Stok Kosong</option>
                                    <option value="47">Produk Gangguan</option>
                                    <option value="50">Dibatalkan</option>
                                    <option value="51">Reseller Nonaktif</option>
                                    <option value="52">Nomor Salah</option>
                                    <option value="53">Diluar Wilayah</option>
                                    <option value="55">Timeout</option>
                                    <option value="56">Nomor Blacklist</option>
                                </select>
                            </div>
                        </div>
                        <div class="box-search">
                            <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                            <div class="form-group">
                                <select class="form-control" v-model="mixmeta_data.skey">
                                    <option value=""> -- Target Kolom Pencarian --</option>
                                    <option value="transaksi.kode">Kode Transaksi</option>
                                    <option value="produk.nama">Nama Produk</option>
                                    <option value="tujuan">No.Tujuan</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                <input type="search"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                            </div>
                            <hr/>
                            <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery(); QuerySummaryTrx();"/>
                        </div>
                    </div>
                    <div class="slide-center-info ">
                        <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                        <div class="clipbox ">
                            <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                            <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                            <ul class="mt-3 ">
                                <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                            </ul>
                            <br/>
                            <div id="sent-mail">
                                <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                <br/>
                                <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                <div>
                                    <i class="mdi mdi-email-open-outline"></i>
                                    <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                </div>
                            </div>
                            <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                        </div>
                    </div>
                </div>
                <!-- end component filter table -->
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8" v-if="$store.state.privileges_modules.uam.includes('filter_sales')">
                                <div class="card-profile-tab  mt-2" >
                                    <a href="javascript:;" @click="isActiveFilterTab = 'search'" :class="isActiveFilterTab == 'search' ? 'active' : ''">Search</a>
                                    <a href="javascript:;" @click="isActiveFilterTab = 'input'" :class="isActiveFilterTab == 'input' ? 'active' : ''">Input</a>
                                </div>
                                <div class="card-profile">
                                    <ul v-if="isActiveFilterTab == 'search'">
                                        <li  v-if="model_upline.name">
                                            <ImageSmCircle :photo="model_upline.photo_selfi_id" />
                                        </li>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="mb-0">{{ model_upline.name ? model_upline.name :  'Semua Reseller' }}</span><br/>
                                                <span class="text-secondary">{{ model_upline.code_otmx_reseller ? model_upline.code_otmx_reseller + '-' +model_upline.level_code :  'Gunakan "Filter Reseller" untuk melihat transaksi reseller.'}} </span>
                                            </div>
                                            <div class="float-left border-left" v-if="model_upline.name">
                                                <span class="mb-0">Status Member</span><br/>
                                                <span class="text-secondary">{{ model_upline.status !== null ? 'Tervalidasi : ' + model_upline.status :  '! Data belum tervalidasi.'}} </span>
                                            </div>
                                            <div class="float-left border-left pt-2">
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Reseller'"  @click="isEnablePopup = 'browse-sales'"/>
                                                &nbsp;&nbsp;
                                                <a href="javascript:;" @click="ResetFilterSales()" v-if="model_upline.name"><i class="mdi mdi-close"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul v-else>
                                        <li class="ml-2">
                                            <div class="float-left mr-2">
                                                <span class="text-secondary">Ketikan "Kode Reseller" untuk melihat transaksi reseller</span>
                                                <input type="search" class="form-control" placeholder="..." v-model="input_code_reseller"/>
                                            </div>
                                            <div class="float-left border-left pt-3" >
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :type="'Submit'" :title="'Filter Reseller'"  @click="InputFilterReseller()"/>
                                                &nbsp;&nbsp;
                                                <a href="javascript:;" @click="ResetFilterSales()" v-if="input_code_reseller !== ''"><i class="mdi mdi-close"></i></a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <label><i class="mdi mdi-information-outline text-danger"></i> Total (IDR) Transaksi merupakan hasil semua status transaksi sukses, gagal, proses, dll</label>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <ul class="data-list">
                                    <li>
                                        <span class="float-left">Transaksi Sukses</span> 

                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.success) }}</span>
                                    </li>
                                    <li>
                                        <span class="float-left">Transaksi Gagal</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.failed) }}</span>
                                    </li>
                                    <li>
                                        <span class="float-left">Transaksi Proses</span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        
                                        <span class="float-right" v-else>{{ $filters.formatNumber(trx.process) }}</span>
                                    </li>
                                    <li>
                                        <span class="float-left">Total Transaksi </span> 
                                        <div class="loading-dots float-right" v-if="isLoadSummaryTrx"><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div><div class="loading-dots--dot"></div></div>
                                        
                                        <span class="float-right" v-else> {{ $filters.formatNumber(trx.total) }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <hr/>
                    </div>
                    <div class="card-body">
                        <div class="table-info">
                            <div class="float-left">
                                <span><i class="mdi mdi-calendar-range"></i> Periode :</span> <span class="text-secondary"> {{ $filters.formatDate(startdate, 'DD/MM/YYYY') }} - {{ $filters.formatDate(enddate, 'DD/MM/YYYY') }}</span>
                            </div>
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery(); QuerySummaryTrx();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="table-cover">
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter">Action</th>
                                        <th :class="mixmeta_data.okey == 'transaksi.kode' ? 'active' : ''">Kode Transaksi</th>
                                        <th :class="mixmeta_data.okey == 'produk.nama' ? 'active' : ''">Nama Produk</th>
                                        <th :class="mixmeta_data.okey == 'transaksi.tujuan' ? 'active' : ''">No.Tujuan</th>
                                        <th :class="mixmeta_data.okey == 'transaksi.harga' ? 'active' : ''">Harga</th>
                                        <th :class="mixmeta_data.okey == 'transaksi.status' ? 'active' : ''">Status</th>
                                        <th :class="mixmeta_data.okey == 'transaksi.tgl_entri' ? 'active' : ''">Tgl.Transaksi</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="btn btn-sm btn-outline-orange" @click="DataDetail(idx)">Detail</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="120">{{ items.kode }}</td>
                                        <td>{{ items.kode_produk }} - {{ $filters.ucwords(items.nama_produk) }}<br/></td>
                                        <td width="150">{{ $filters.formatPhone(items.tujuan) }}</td>
                                        <td width="120">Rp {{ $filters.formatNumber(items.harga) }}</td>
                                        <td width="120"><span :class="GenerateClassStatus(items.status)">{{ items.status }}</span></td>
                                        <td width="120">{{ $filters.formatDate(items.tgl_entri, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.tgl_entri, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="7">
                                            Data kosong / tidak ditemukan.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import $ from 'jquery';
    import moment from "moment";
    import BrowseSales from '../Resellers/BrowseSales';
    import DetailTrans from './DetailTrans';

    export default {
        components: {
            BrowseSales,
            DetailTrans
        },
        data() {
            return {
                isFirstLoad : true,
                isLoadSummaryTrx : false,
                isEnableFilter : false,
                isEnablePopup : false,
                isActiveFilterTab : 'search',
                model_detail : {},
                model_upline : {},
                freseller : localStorage.getItem('user-code'),
                fprivileges : localStorage.getItem('user-privileges'),
                input_code_reseller : '',

                fdate : 7,
                trx_status : 'all',
                startdate : new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                enddate : new Date().toISOString().slice(0,10),
                trx : {
                    total : 0,
                    success : 0,
                    failed : 0,
                    process : 0
                },
            }
        },
        created()
        {
            this.IdentifiedUserQuery();
            this.mixmeta_data.okey = 'transaksi.tgl_entri';
            this.mixmeta_data.oval = 'desc';
            this.QuerySummaryTrx();
            this.ServiceInitFunction('DataQuery', 's-table');

            setTimeout(() => {
                this.InitialMaxInputDate();
            }, 200);
        },
        methods:
        {
            IdentifiedUserQuery()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }
            },
            ResetFilterSales()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }

                this.input_code_reseller = '';
                this.model_upline = {};
                this.QuerySummaryTrx();
                this.DataQuery();
            },
            InputFilterReseller()
            {
                if( this.input_code_reseller == '' ){
                    alert('Silahkan masukan kode reseller.');
                    return;
                }
                this.freseller = this.input_code_reseller;
                this.DataQuery();
            },
            closeBrowseSales(data)
            {
                this.isEnablePopup = false;
                if(data)
                {
                    this.model_upline = data;
                    this.freseller = data.code_otmx_reseller;
                    this.QuerySummaryTrx();
                    this.DataQuery();
                }
            },
           
            InitialMaxInputDate()
            {
                var today = new Date();
                var dd = today.getDate();
                var mm = today.getMonth()+1; 
                var yyyy = today.getFullYear();
                if(dd<10){
                    dd='0'+dd
                } 
                if(mm<10){
                    mm='0'+mm
                } 

                today = yyyy+'-'+mm+'-'+dd;
                $('#startDate, #endDate').prop('max', function(){
                    return today;
                });
            },
            FilterFDate()
            {
                switch(this.fdate)
                {
                    case '1': 
                        this.startdate = new Date( Date.now()).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '7': 
                        this.startdate = new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '30': 
                        this.startdate = new Date( Date.now() + -29*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    default:
                    break;
                }

                if( this.fdate !== '0' )
                {
                    document.getElementById('startDate').value = this.startdate;
                    document.getElementById('endDate').value = this.enddate;
                }
            },
            GenerateClassStatus(status)
            {
                let class_style = '';
                if( status == 'Sukses' ){
                    class_style = 'text-success';
                }
                else if( status == 'Gagal' ){
                    class_style = 'text-danger';
                }
                else{
                    class_style = 'text-warning';
                }

                return class_style;
            },
            closeDetailTrans()
            {
                this.isEnablePopup = false;
            },
            
            DataDetail(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'detail-trans';
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.isEnableFilter = false;
                this.page = pageNum == undefined ? 1 : pageNum;

                
                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') ){
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }


                let res = await this.ServiceApiDataPaging({
                    url:'transaction-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'status', val : 'all'},
                        {key : 'reseller.kode', val : this.freseller}
                    ],
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;

                }
            },
            async QuerySummaryTrx()
            {

                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') ){
                        var sdate = document.getElementById('startDate').value;
                        var edate = document.getElementById('endDate').value;
                        query_startdate = moment(sdate).format("DD-MM-YYYY");
                        query_enddate   = moment(edate).format("DD-MM-YYYY");
                }

                this.isLoadSummaryTrx = true;
                let res = await this.ServiceApiPost({
                    url:'transaction-summary', 
                    data : {
                        start_date : query_startdate,
                        end_date : query_enddate,
                        reseller_upline : this.freseller,
                        trx_status : this.trx_status
                    }
                });
                if(res){ 
                    let model = res.query;
                    this.trx.total = model.total_idr_trx; 
                    this.trx.success = model.status_success; 
                    this.trx.failed = model.status_failed; 
                    this.trx.process = model.status_process; 
                    this.isLoadSummaryTrx = false;
                }
            }

        }
    }
</script>
