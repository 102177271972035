<template>
    <main>
        <div class="popup-modal" v-if="isEnableConfirm">
            <div class="modal-dialog modal-lsm" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Konfirmasi</h5>
                        <button class="btn btn-sm btn-close" type="button" @click="isEnableConfirm = false"></button>
                    </div>
                    <div class="modal-body">
                        <div className="row">
                            <div class="col-md-12 mt-3">
                                <h6 class="">Apakah anda yakin ingin menyimpan perubahan ?</h6>
                                <span>Masukan note sebagai pengingat data perubahan.</span>
                                <div class="row mt-2">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Note <small class="text-danger">*</small></label>
                                            <textarea class="form-control" placeholder="...." v-model="model.note" rows="5"></textarea>
                                            <small class="text-danger" v-if="service_form_error.note">{{ service_form_error.note[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div class="modal-footer"><button class="btn btn-sm btn-success" type="button"  @click="SavePrice()">OK, SIMPAN</button></div>
                </div>
            </div>
        </div>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon"><i class="fas fa-users"></i></div>
                                Harga Produk
                            </h1>
                            <div class="page-header-subtitle">Total Data Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="container-xl px-4 mt-n10">
            <div class="row">
                <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                <div class="col-xl-12" v-else>
                    <div class="card mb-4">
                        <div class="card-header">
                            <div class="table-search">
                                <router-link :to="{ name: 'product-insert' }" class="btn  mr-2 float-start" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="fas fa-plus pr-2"></i>  <span class="ml-1">Create New</span> </router-link>
                                 <a href="javascript:;" class=" ml-2">
                                    <div class="input-group input-group-joined input-group-solid">
                                        <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                        <div class="input-group-text">
                                            <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                            <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                        </div>
                                        <div class="box" v-if="mixsearch">
                                            <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                            <label class="text-secondary">Pilih Target Pencarian</label>
                                            <select v-model="mixmeta_data.skey">
                                                <option value=""> -- --</option>
                                                <option value="product.sku">SKU Produk</option>
                                                <option value="product.name">Nama Produk</option>
                                            </select>
                                            <div class="group-input">
                                                <label class="text-secondary">Masukan Keyword Pencarian</label>
                                                <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                                <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                                <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                            </div> 
                                        </div>
                                    </div>
                                </a> 
                            </div>
                        </div>
                        <div class="card-body" >
                            <div class="table-filter">
                                <div class="row">
                                    <div class="col-md-12">
                                         <div class="form-group mr-2 float-start">
                                             <label>Tipe Produk</label>
                                             <select class="form-control"  v-model="ftype" @change="DataQuery()">
                                                 <option value="voucher">Voucher</option>
                                                 <option value="digital">Digital</option>
                                             </select>
                                        </div>
                                        <div class="form-group mr-2 float-start">
                                            <SelectProvider v-on:ChooseData="ChooseProvider"/>
                                        </div>
                                        <div class="form-group mr-2 float-end mt-3">
                                            <a href="javascript:;" class="btn btn-sm btn-light mt-4" @click="isEnableConfirm = true" v-if="model_faktur.total  > 0"><i class="fas fa-download text-success mr-1"></i> SIMPAN PERUBAHAN</a>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                            <div class="" v-if="model_faktur.total  > 0">
                                <div class="row">
                                    <div class="col-md-5 pr-0">
                                        <table class="table s-table table-product">
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="border-right">Produk Market</th>
                                                </tr>
                                                <tr>
                                                    <th class="border-right border-top">SKU</th>
                                                    <th class="border-right border-top">Nama Produk</th>
                                                </tr>
                                            </thead>
                                            <tbody >
                                                <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                    <td width="120"><div class="con-width con-title"><span class="title-global">{{  $filters.limit($filters.ucwords(items.sku), 15, '...') }}</span></div></td>
                                                    <td><div class="con-width con-title"><span class="title-global">{{  $filters.limit($filters.ucwords(items.name), 50, '...') }}</span></div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                         <div class="float-start">
                                            <span class="text-secondary">Produk Records  {{ mixmodel.current_page }} &nbsp;/&nbsp; {{ mixmodel.last_page }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 pl-0" style="position:relative;">
                                        <div class="table-price" id="table-price">
                                            <table class="table s-table ">
                                                <thead>
                                                    <tr>
                                                        <template v-for="(fk, id_fk) in data_faktur" :key="id_fk">
                                                            <th colspan="2" :class="(id_fk % 2) == 0 ? 'text-center bg-ganjil' : 'text-center bg-genap'" width="60"># {{ fk.faktur_number }}</th>
                                                        </template>
                                                    </tr>
                                                    <tr class="border-top">
                                                        <template v-for="(items, idx) in data_faktur" :key="idx">
                                                            <th class="text-center bg-buy">Beli</th>
                                                            <th class="text-center bg-sell">Jual</th>
                                                        </template>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="!isLoadFaktur">
                                                    <tr v-for="(dt, idx_dt) in mixmodel.data" :key="idx_dt">
                                                        <template v-for="(set, idx_set) in data_faktur" :key="idx_set">
                                                            <td>
                                                                <div class="con-width text-center pt-2"><span>{{  GetDataFaktur('price', dt.sku, set.product_items) > 0 ? $filters.formatNumber(GetDataFaktur('price', dt.sku, set.product_items))  : '--'}}</span></div>
                                                            </td>
                                                            <td>
                                                                <div class="con-width text-center pt-2">
                                                                    <input type="number" class="form-control input-mini" placeholder="0" :value="GetDataFaktur('selling_price', dt.sku, set.product_items)"  @keyup="UpdateSellingPrice($event, idx_set, dt.sku, set.product_items)" v-if="GetDataFaktur('price', dt.sku, set.product_items) > 0"/>
                                                                    <span class="text-secondary" v-else>--</span>
                                                                </div>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <span class="text-secondary float-start"><i class="fas fa-arrows-alt-h"></i> Scroll horizontal untuk melihat data lainya.</span>
                                            <span class="text-secondary float-end">Faktur Records  {{ model_faktur.current_page }} &nbsp;/&nbsp; {{ model_faktur.last_page }}</span>
                                        </div>
                                        <div class="navigation-table navleft">
                                            <a href="javascript:;" @click="QueryFaktur('prev')"><i class="fas fa-chevron-left"></i></a>
                                        </div>
                                        <div class="navigation-table navright">
                                            <a href="javascript:;" @click="QueryFaktur('next')"><i class="fas fa-chevron-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="page-loader page-loader-blank" v-else>
                                <lottie-player src="assets/img/lotties/not-found.json" background="transparent"  speed="0.5" class="lottie-processing lottie-search" loop  autoplay></lottie-player><br/><br/><br/><br/>
                                <span class="text-secondary">Opps, Sorry data not found !</span>
                            </div>
                            <v-pagination
                                v-if="mixmodel.total > 0"
                                v-model="page"
                                :class="'pagination'"
                                :pages="parseInt(mixmodel.last_page)"
                                :range-size="1"
                                active-color="#ffffff"
                                @update:modelValue="DataQuery"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>

    export default {
        data() {
            return {
                isFirstLoad : true,
                isEnableConfirm : false,

                fid_provider : 'all',
                ftype : 'voucher',
                model : {},
                data_faktur : [],
                model_faktur : [],
                offset_fkpage : 1,
                code_otmx_reseller : localStorage.getItem('user-code'),
            }
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
            this.QueryFaktur();
        },
        methods:
        {
            GetDataFaktur(initial, sku, product_items)
            {
                let model = JSON.parse(product_items);
                for(let key in model){
                    if(model[key].sku == sku){
                        if( initial == 'price' ){
                            return model[key].price;
                        }
                        if( initial == 'selling_price' ){
                            return model[key].selling_price ? model[key].selling_price : 0;
                        }
                    }
                }
                return 0;
            },
           
            ChooseProvider(data)
            {
                this.fid_provider = data ? data.id : 'all';
                this.DataQuery();
            },
            UpdateSellingPrice(event, idx, sku, product_items)
            {
                setTimeout(() => 
                {
                    let model = JSON.parse(product_items);

                    for(let key in model)
                    {
                        if(model[key].sku == sku){
                           model[key].selling_price = event.target.value == '' ? 0 :  event.target.value;
                        }
                    }

                    this.data_faktur[idx].product_items = JSON.stringify(model);
                   
                }, 1000);
            },
            
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'product-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'product.type', val : this.ftype},
                        {key : 'provider.id', val : this.fid_provider},
                    ]
                });
                if(res){ 
                    this.mixmodel = res.query; 
                }
            }, 
            async QueryFaktur(initial)
            {
                let validate = false;
                if(initial)
                {
                    if(initial == 'next' )
                    {
                        if(this.offset_fkpage <  this.model_faktur.last_page){
                            this.offset_fkpage += 1;
                            validate = true;
                        }
                        document.getElementById('table-price').scrollLeft += 600;
                    }

                    if(initial == 'prev'){
                        document.getElementById('table-price').scrollLeft -= 600;
                    }

                }else{
                    validate = true;
                }
                
                if(validate){

                    let res = await this.ServiceApiDataPaging({
                        url:'price/data', 
                        offset : this.offset_fkpage,
                        order: { key : 'id', value : 'desc' }
                    });
                    if(res){ 

                        this.model_faktur = res.query;
                        let faktur = this.model_faktur.data;
                        for(let key in faktur){
                            this.data_faktur.push( faktur[key] );
                        }

                        this.isFirstLoad = false;
                        setTimeout(function(){
                            document.getElementById('table-price').scrollLeft += 600;
                        }, 200)
                    }
                }
            },
            async SavePrice()
            {
                let data_update = {
                    note : this.model.note,
                    code : this.code_otmx_reseller,
                    data : this.data_faktur
                }

                let res = await this.ServiceApiPost({ action : 'post', url:  'price/update', data : data_update });
                if(res && res.result)
                {
                    this.isEnableConfirm = false;
                    this.model.note = '';
                }
            },
           
        }
    }
</script>

<style lang="scss" scoped>
.bg-buy{background-color:#f5e5e5;}
.bg-sell{background-color:#e1f7e1;}
.bg-ganjil{background-color:#80808008;}
.bg-genap{background-color:#e1e1e1;}


.con-width{
    height:40px;
    width:100px;
    .title-global{
        font-size:14px;
        text-transform: capitalize;
    }
}
.con-title{
    width:100%;
}
table{
    border:1px solid whitesmoke;
   
}
.input-mini
{
    padding:5px;
    float:right;
    width:100%;
}
.table-product{
    float:left;
    width:100%;
    
    tr{

        th{border-right:1px solid #ececec;}
        td{border-right:1px solid #ececec;}
    }
}
.table-price{
    position:relative;
    scroll-behavior: smooth;
    table{
        max-width:2500px;
    }
    float:left;
    width:100%;
    overflow-x:scroll;
    tr{

        th{border-right:1px solid #ececec;}
        td{border-right:1px solid #ececec; }
    }  
}


.navigation-table{
    position:absolute;
    padding:11px;
    background-color:#ffffffad;
    z-indeX:99;
    text-align:center;
    i{
        font-size:20px;
        color:gray;
        position:relative;
        top:2px;
    }
}
.navleft{
    left:0;
}
.navright{
    right:12px;
}
#loader-faktur{
    height:200px;
    width:100%;
    text-align:center;
    padding:20px;
}


</style>