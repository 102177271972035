<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Produk Non Digital
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola kategori produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <div class="form-group">
                                                <label>Status Produk <small class="text-danger">*</small></label>
                                                <select class="form-control" v-model="model.status">
                                                    <option value="1">Aktif</option>
                                                    <option value="0">Tidak Aktif</option>
                                                </select>
                                                <small class="text-danger" v-if="service_form_error.type">{{ service_form_error.type[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Product SKU <small class="text-danger">*</small> </label>
                                                <input type="text" class="form-control" placeholder="...." v-model="model.sku">
                                                <small class="text-danger" v-if="service_form_error.sku">{{ service_form_error.sku[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Kode Item <small class="text-danger">*</small></label>
                                                <input type="text" class="form-control" placeholder="...." v-model="model.kode_item">
                                                <small class="text-danger" v-if="service_form_error.kode_item">{{ service_form_error.kode_item[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Photo Produk  <small class="text-danger">*</small>  <br/><small class="text-secondary">Upload photo produk.</small></label>
                                                <UploadSingleImage v-on:processFile="HandleMainImage"  :layout="'potrait'" :layout_height="'190px'"/>
                                                <small class="text-danger" v-if="service_form_error.photo">{{ service_form_error.photo[0] }}</small>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-group">
                                                <SelectProductTypeNonDigital  v-on:ChooseData="ChooseProductType" />
                                                <small class="text-danger" v-if="service_form_error.type">{{ service_form_error.type[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <SelectProductCategory v-on:ChooseData="ChooseProductCategory" :type="'non-digital'" v-if="model.type == 'accessories'"/>
                                                <div v-else>
                                                    <label for="">Kategori Produk</label>
                                                    <select class="form-control"><option>-- --</option></select>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>Nama  Produk <small class="text-danger">*</small></label>
                                                <input type="text" class="form-control" placeholder="...." v-model="model.name">
                                                <small class="text-danger" v-if="service_form_error.name">{{ service_form_error.name[0] }}</small>
                                            </div>
                                            <div class="form-group">
                                                <label>Produk Deskripsi  <br/><small class="text-secondary">Tuliskan deskripsi singkat mengenai produk.</small></label>
                                                <textarea class="form-control" rows="8" placeholder="...." v-model="model.description"></textarea>
                                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <hr/>
                                    <div class="form-group text-center mb-5">
                                        <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                        <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'"/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default 
    {   
        data() {
            return {
                isSubmitProcess  : false,
                model: {},
            };
        },
        methods: 
        {
            ChooseProductCategory(data)
            {
                if( data.id ){
                    this.model.id_category = data.id;
                }
            },
            ChooseProductType(data)
            {
                this.model.type = data;
            },
            HandleMainImage(data)
            {
                this.model.images = data;
            },
            async Submit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'product_non_digital/store', data :this.model, callback_url : 'product_non_digital-data' });
                if(res){
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
