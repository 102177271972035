<template>
    <main>
        <SetArea :data_model="data_model" v-on:closePopup="closePopupArea"  v-if="isEnablePopup == 'set-area'"/>

        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Reseller 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola data reseller.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
               
                <div class="card mb-4">
                    <div class="card-body">
                        <!-- component filter table -->
                        <div class="" v-if="isEnableFilter">
                            <div class="slide-right-filter " >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="users.code_otmx_reseller">Kode Reseller</option>
                                            <option value="users.name">Nama</option>
                                            <option value="customer_register.level_code">Kode Level</option>
                                            <option value="customer_register.phone">Phone</option>
                                            <option value="users.created_at">Tgl.Dibuat</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="users.name">Nama</option>
                                            <option value="customer_register.code_otmx_reseller">Kode Reseller</option>
                                            <option value="customer_register.level_code">Level</option>
                                            <option value="users.created_at">Tgl.Dibuat</option>
                                            <option value="users.updated_at">Tgl.Diupdate</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <Download :init_url="'reseller-download'"></Download>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center">Action</th>
                                        <th class="text-center">Photo</th>
                                        <th :class="mixmeta_data.okey == 'customer_register.status' ? 'active' : ''">Status</th>
                                        <th :class="mixmeta_data.okey == 'users.name' ? 'active' : ''">Nama</th>
                                        <th :class="mixmeta_data.okey == 'customer_register.code_otmx_reseller' ? 'active' : ''">Kode Reseller</th>
                                        <th :class="mixmeta_data.okey == 'customer_register.level_code' ? 'active' : ''">Level</th>
                                        <th :class="mixmeta_data.okey == 'customer_register.phone' ? 'active' : ''">Phone</th>
                                        <th class="text-center">Downline</th>
                                        <th :class="mixmeta_data.okey == 'users.created_at' ? 'active' : ''">Tgl. Dibuat</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="DataDownline(items.code_otmx_reseller)">Downline</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            <ImageSmCircle :photo="items.photo" :initial="'sales'"/>
                                        </td>
                                        <td width="250">
                                            <span> <i class="mdi mdi-account-settings"></i>  {{ items.status !== null ?  items.status : '-- --' }}</span><br/>
                                            <span class="">{{ items.data_status }}</span>
                                        </td>
                                        <td width="230">{{ $filters.ucwords(items.name) }}</td>
                                        <td width="160">{{ items.code_otmx_reseller }}</td>
                                        <td width="100">{{ items.level_code !== null ? items.level_code : '-- --' }}</td>
                                        <td width="120">{{ items.phone !== null ? items.phone : '-- --' }}</td>
                                        <td width="100" class="text-center"><span class="text-success">{{ $filters.formatNumber(items.total_downline) }}</span></td>
                                        <td width="120">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script>
    import SetArea from './SetArea';

    export default {
        components: {
            SetArea
        },
        data() {
            return {
                isFirstLoad : true,
                isEnablePopup : false,
                isEnableFilter  : false,
                data_model : {},
                model_privileges : [],

                model_propinsi : [],
                model_kota : [],
                model_kecamatan : [],
                model_kelurahan : [],
                meta_filter :{ id_propinsi : 'all', id_kota : 'all', id_kecamatan : 'all', id_kelurahan : 'all' },
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'users.created_at';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            closePopupArea(initial)
            {
                this.isEnablePopup = false;
                if(initial){
                    this.DataQuery();
                }
            },
            closePopupProfile(){
                this.isEnablePopup = false;
            },
            DataDownline(code_otmx_reseller)
            {
                localStorage.setItem('QUERY_ID', code_otmx_reseller);
                this.$router.push({name : 'reseller-downline'});
            },
            /*
            FilterPropinsi()
            {
                this.meta_filter.id_kota = 'all';
                this.meta_filter.id_kecamatan = 'all';
                this.meta_filter.id_kelurahan = 'all';

                this.model_kota = [];
                this.model_kecamatan = [];
                this.model_kelurahan = [];
                this.QueryKota();
            },
            FilterKota()
            {
                this.meta_filter.id_kecamatan = 'all';
                this.meta_filter.id_kelurahan = 'all';
                
                this.model_kecamatan = [];
                this.model_kelurahan = [];
                this.QueryKecamatan();
            },
            FilterKecamatan()
            {
                this.meta_filter.id_kelurahan = 'all';

                this.model_kelurahan = [];
                this.QueryKelurahan();
            },
          
            async QueryProvince() 
            {
                let res = await this.ServiceApiPost({
                    url:'location/propinsi/data/all'
                });
                if(res){
                    this.model_propinsi = res;
                }
            },
            async QueryKota() 
            {
                this.model_kota = [];
                this.model_kecamatan = [];
                this.model_kelurahan = [];
                
                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'provinsi_id', value : this.meta_filter.id_propinsi  }
                    },
                    url:'location/kota/data/all'
                });
                if(res){
                    this.model_kota = res;
                }
            },
            async QueryKecamatan() 
            {
                this.model_kecamatan = [];
                this.model_kelurahan = [];

                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'kota_id', value : this.meta_filter.id_kota  }
                    },
                    url:'location/kecamatan/data/all'
                });
                if(res){
                    this.model_kecamatan = res;
                }
            },
            async QueryKelurahan() 
            {
                this.model_kelurahan = [];
                let res = await this.ServiceApiPost({
                    data : { 
                        where : { key : 'kecamatan_id', value : this.meta_filter.id_kecamatan  }
                    },
                    url:'location/kelurahan/data/all'
                });
                if(res){
                    this.model_kelurahan = res;
                }
            },*/
            DataProfile(idx)
            {
                this.data_model = this.mixmodel.data[idx];
                this.isEnablePopup = 'profile';
            },
            DataSetArea(idx){
                this.data_model = this.mixmodel.data[idx];
                this.isEnablePopup = 'set-area';
            },
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'reseller-data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                   /* filter : [
                        {key : 'sales_area.id_propinsi', val:this.meta_filter.id_propinsi},
                        {key : 'sales_area.id_kota', val:this.meta_filter.id_kota},
                        {key : 'sales_area.id_kecamatan', val:this.meta_filter.id_kecamatan},
                        {key : 'sales_area.id_kelurahan', val:this.meta_filter.id_kelurahan}
                    ]*/
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }
        }
    }
</script>
