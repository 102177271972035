<template>
    <div class="popup-modal">
        <div class="modal-dialog modal-md" >
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Detail Hutang <br/> <small class="text-secondary">Periode s/d Tanggal {{  $filters.formatDate(data_model.enddate, 'DD/MM/YYYY') }}</small></h5>
                    <a href="javascript:;" class="nav-module link-close" @click="ClosePopup()"><i class="mdi mdi-close mdi-icon"></i> </a>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-6 mt-4">
                            <div class="card-profile">
                                <ul>
                                    <li>
                                        <ImageSmCircle :photo="data_model.photo"/>
                                    </li>
                                    <li>
                                        <div class="ml-2">
                                            <span class="fs-7">{{ $filters.ucwords(data_model.nama) }}</span><br/>
                                            <span class="text-secondary fs-8">{{ data_model.code }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 mt-1">
                            <ul class="data-list">
                                <li><span class="">Total Hutang</span> <span class="float-right">Rp <b class="text-danger">{{ $filters.formatNumber(total_loans) }}</b></span></li>
                                <li><span class="">Total Pembayaran</span> <span class="float-right">Rp <span class="text-success">{{ $filters.formatNumber(total_paid) }}</span></span></li>
                                <li><span class="">Sisa Hutang</span> <span class="float-right">Rp {{ $filters.formatNumber(total_loans - total_paid) }}</span></li>
                            </ul>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="table-info">
                                <div class="float-right">
                                    <ul>
                                        <li>
                                            <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                        </li>
                                        <li class="border-left">
                                            <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                            <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                            <div class="table-cover scrollbox" v-else>
                                <table :class="isRefreshTable ? 'table-load' : '' ">
                                    <thead>
                                        <tr>
                                            <th class="">Tanggal</th>
                                            <th class="">Keterangan</th>
                                            <th class="bg-success-soft">Terima</th>
                                            <th class="bg-danger-soft">Berikan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(items, idx) in model" :key="idx">
                                            <td>{{ $filters.formatDate(items.tanggal, 'DD/MM/YYYY') }}</td>
                                            <td>{{ items.keterangan }}</td>
                                            <td width="120">Rp {{ $filters.formatNumber(items.payment_loans) }}</td>
                                            <td width="120">Rp {{ $filters.formatNumber(items.credit_loans) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    export default 
    {   
        props :['data_model'],
        data() {
            return {
                isFirstLoad : true,
                model : [],
                total_loans : 0,
                total_paid : 0,

            };
        },
        created()
        {
            this.mixmeta_data.okey = 'tanggal';
            this.mixmeta_data.oval = 'asc';
            this.DataQuery();
        },
        methods: 
        {
            ClosePopup()
            {
                this.$emit('closePopup');
            },
           
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'reseller-loans-detail', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'kode_reseller_2', val:this.data_model.kode},
                        {key : 'end_date', val : this.data_model.enddate}
                    ]
                });
                if(res)
                {
                    this.model = res.query;
                    this.total_loans = res.total_loans;
                    this.total_paid = res.total_paid;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

.scrollbox{
    height:350px;
}
</style>