<template>
    <main>
        <ImportPromo  v-on:applyData="ChooseProductPromo"  v-on:closeModal="closePopup"  v-if="isEnablePopup"/>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Banner App 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola konten banner berupa gambar dan teks pada aplikasi.</div>
                </div>
                <div class="col-md-2a mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <form @submit="Submit">
                            <div class="row" >
                                <div class="col-md-5">
                                    <label for="">Upload Image</label>
                                    <UploadSingleImage v-on:processFile="handleImage"  :layout="'potrait'" :layout_height="'200px'"/>
                                    <div>
                                        <small>File images support (Jpg, Jpeg, Png) and maximum upload file size is 2MB.</small><br/>
                                        <small class="text-danger" v-if="service_form_error.photo">{{ service_form_error.photo[0] }}</small>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label>Jenis Promo</label>
                                        <select class="form-control" v-model="is_promo_link">
                                            <option value="product">Produk</option>
                                            <option value="link">Direct Link</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Status</label>
                                        <select class="form-control" v-model="model.status">
                                            <option value="active">Aktif</option>
                                            <option value="disactivate">Tidak Aktif</option>
                                        </select>
                                        <small class="text-danger" v-if="service_form_error.status">{{ service_form_error.status[0] }}</small>
                                    </div>
                                    <div class="form-group">
                                        <label>Tipe Banner</label>
                                        <select class="form-control" v-model="model.type">
                                            <option value="promo-popup">Promo Popup</option>
                                            <option value="promo-h2h">Promo Banner H2H (Host to Host)</option>
                                            <option value="promo-product">Promo Product Apss</option>
                                            <option value="promo-banner">Promo Banner Apps</option>
                                        </select>
                                        <small class="text-danger" v-if="service_form_error.type">{{ service_form_error.type[0] }}</small>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4" v-if="model.is_promo_link == 'link'">
                                    <label>Direct Link <br/><small class="text-secondary">Masukan link url.</small></label>
                                    <input type="text" placeholder="http:// atau https://" class="form-control" v-model="model.link" />
                                </div>
                                <div class="col-md-12 mt-4" v-if="model.is_promo_link == 'product'">
                                    <hr/>
                                    <label for="">Produk Promo <small class="text-danger" v-if="service_form_error.group_id_promo">( {{ service_form_error.group_id_promo[0] }} )</small></label>
                                    <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                                    <div class="table-cover" v-else>
                                        <table class="s-table">
                                            <thead>
                                                <tr>
                                                    <th class="text-center no-filter" v-if="$store.state.privileges_modules.uap">--</th>
                                                    <th data-filter="title">Title</th>
                                                    <th data-filter="listing_type">Tipe Listing</th>
                                                    <th data-filter="product_name">Nama Produk</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                    <td width="30" class="text-center" >
                                                        <input class="mt-2" type="radio" :value="items.id" v-model="model.group_id_promo" />
                                                    </td>
                                                    <td width="250">{{ items.title }}</td>
                                                    <td width="100">{{ items.listing_type }}</td>
                                                    <td>
                                                        <span class="text-secondary">Kode Operator : {{ items.product_operator_code }}</span><br/>
                                                        {{ items.product_name }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <v-pagination
                                        v-if="mixmodel.total > 0"
                                        v-model="page"
                                        :class="'pagination'"
                                        :pages="parseInt(mixmodel.last_page)"
                                        :range-size="1"
                                        active-color="#ffffff"
                                        @update:modelValue="DataQuery"
                                    />
                                    <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                                </div>
                            </div> 
                            <hr/>
                            <div class="form-group text-center mt-5">
                                <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'"/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

    export default 
    {   
        data() {
            return {
                isSubmitProcess  : false,

                isFirstLoad : true,
                isEnablePopup : false,
                is_promo_link : 'product',
                model: {
                    status : 'active',
                    type : 'promo-banner',
                },
                model_promo : {}
            };
        },
        created()
        {
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {
            closePopup()
            {
                this.isEnablePopup = false;
            },
          
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'promo/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res)
                {
                    this.mixmodel = res.query;
                    this.isFirstLoad = false;
                }
            },
            ChooseProductPromo(data)
            {
                this.model_promo = data;
            },
            handleImage(data)
            {
                this.model.photo = data;
            },
            async Submit(e) 
            {
                e.preventDefault();

                this.isSubmitProcess = true;

                // valdiate promo link
                if(this.is_promo_link == 'link'){
                    this.model.group_id_promo = '';
                }else{
                    this.model.link = '';
                }

                let res = await this.ServiceApiPost({ action : 'post', url: 'banners/store', data :this.model, callback_url : 'banners-data' });
                if( res  ){
                    this.isSubmitProcess = false;
                    if(!res.result){
                        alert(res.msg)
                    }
                }
            }
        }
    }
</script>
