<template>
    <div class="popup-modal">
       <div class="modal-dialog modal-xl" >
           <div class="modal-content">
               <div class="modal-header">
                   <h5 class="modal-title" id="exampleModalLabel">Produk Digital</h5>
                   <button class="btn btn-sm btn-close" type="button" @click="CloseModal()"></button>
               </div>
               <div class="modal-body">
                   <div className="row">
                       <div class="col-md-12 mb-3">
                           <!--
                            <div class="table-search">
                               <a href="javascript:;" class=" ml-2">
                                   <div class="input-group input-group-joined input-group-solid">
                                       <input class="form-control pe-0" type="text" v-model="mixmeta_data.sval" placeholder="Search" aria-label="Search"  @click="mixsearch =! mixsearch"/>
                                       <div class="input-group-text">
                                           <i class="fas fa-times text-danger"  @click="ServiceRefreshData()" v-if="mixmeta_data.sval !== ''"></i>
                                           <i class="fas fa-search"  @click="mixsearch =! mixsearch" v-else></i>
                                       </div>
                                       <div class="box" v-if="mixsearch">
                                           <a href="javascript:;" @click="mixsearch =! mixsearch"><i class="fas fa-times"></i></a>
                                           <label class="text-secondary">Pilih Target Pencarian</label>
                                           <select v-model="mixmeta_data.skey">
                                               <option value="">-- Query Search Field --</option>
                                               <option value="product.product_code">Kode Produk</option>
                                               <option value="product.name">Nama Produk</option>
                                           </select>
                                           <div class="group-input">
                                               <label class="text-secondary">Masukan Keyword Pencarian</label>
                                               <input type="date" v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                               <input type="search" v-model="mixmeta_data.sval" placeholder="Enter Keyword ...." v-else/>
                                               <a class="btn" @click="ServiceSearchData()">CARI DATA</a>
                                           </div> 
                                       </div>
                                   </div>
                               </a>  
                           </div>
                            -->
                           <div class="table-filter">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <SelectProductCategory v-on:ChooseData="FilterCategory" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <SelectProductSubCategory v-on:ChooseData="FilterSubCategory" :id_category="meta_filter.id_category" v-if="meta_filter.id_category !== 'all'"/>
                                            <div v-else>
                                                <label>Sub-Kategori Produk </label>
                                                <select  class="form-control" >
                                                    <option value="all">--  --</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-cover scrollbox">
                                <table class="s-table">
                                    <thead>
                                        <tr>
                                            <th class="text-center no-filter">Action</th>
                                            <th data-filter="title">Kategori</th>
                                            <th >Sub Kategori</th>
                                            <th >Kode Produk</th>
                                            <th >Nama Produk</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                            <td  class="text-center" width="80">
                                                <a href="javascript:;" class="btn btn-sm btn-light" @click="ChooseData(idx)"  data-dismiss="modal">Pilih</a>
                                            </td>
                                            <td>{{ $filters.ucwords(items.category_name) }}</td>
                                            <td>{{ $filters.ucwords(items.subcategory_name) }}</td>
                                            <td><span class="text-secondary">{{ items.product_code }}</span></td>
                                            <td><span>{{ $filters.limit($filters.ucwords(items.name), 30, ' ...') }}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <v-pagination
                                v-if="mixmodel.total > 0"
                                v-model="page"
                                :class="'pagination'"
                                :pages="parseInt(mixmodel.last_page)"
                                :range-size="1"
                                active-color="#ffffff"
                                @update:modelValue="DataQuery"
                            />
                            <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>

                       </div>
                   </div> 
               </div>
           </div>
       </div>
   </div>
</template>

<script>
   export default 
   {
       name : 'ImportProductDigital',
       data() {
          return {
               isFirstLoad : true,
               meta_filter :{ id_category : 'all', id_subcategory : 'all' }
           }
       },
       created() 
       {
           this.ServiceInitFunction('DataQuery', 's-table');
       },
       methods:    
       {
           CloseModal(){
               this.$emit('closeModal');
           },
           ChooseData(idx)
           {
                console.log('--', this.mixmodel.data[idx]);
               this.$emit('applyData', this.mixmodel.data[idx]);
               this.$emit('closeModal');
           },
          
           FilterCategory(data)
            {
                this.meta_filter.id_category = 'all';
                this.meta_filter.id_subcategory = 'all';

                setTimeout(() => {
                    if( data.id ){
                        this.meta_filter.id_category = data.id;
                    }
                    this.DataQuery();
                }, 200);
               
            },
            FilterSubCategory(data)
            {
                this.meta_filter.id_subcategory = 'all';
                if( data.id ){
                    this.meta_filter.id_subcategory = data.id;
                }
                this.DataQuery();
            },
           async DataQuery(pageNum)
           {
               this.page = pageNum == undefined ? 1 : pageNum;
               this.isFirstLoad = true;

               let res = await this.ServiceApiDataPaging({
                    url: 'product_digital/promo', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'product_category.id', val:this.meta_filter.id_category},
                        {key : 'product_subcategory.id', val:this.meta_filter.id_subcategory},
                    ]
               });
               if(res){ 
                   this.mixmodel = res.query; 
                   this.isFirstLoad = false;
               }
           }, 
       }
   }
</script>

<style lang="scss" scoped>
.scrollbox{
   height:20rem;
}
</style>