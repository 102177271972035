<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Kelola Modal Sales
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola topup modal, pengembalian modal hingga detail mutasi modal sales.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>
        <div className="row">
            <div class="col-md-3">
                <div class="card-profile">
                    <ul>
                        <li>
                            <ImageSmCircle :photo="data_profile.photo" />
                        </li>
                        <li>
                            <div class="ml-2">
                                <span>{{ data_profile.name }}</span><br/>
                                <span>{{ data_profile.code_otmx_reseller }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-5">
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Tanggal Mulai</label>
                            <input type="date" class="form-control"  v-model="startdate"  :max="max_date"  id="startDate" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label>Tanggal Akhir</label>
                            <input type="date" class="form-control"  v-model="enddate"  :max="max_date" id="endDate" />
                        </div>
                    </div>
                 
                </div>
                <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'" :type="'Submit'" :title="'Filter Tanggal'" @click="DataQuery()"/>
            </div>
            <div class="col-md-4">
                <ul class="data-list mt-2">
                    <li><span class="float-left">Total Pinjaman Modal</span> <span class="float-right">Rp <b class="text-success">{{ $filters.formatNumber(total_modal) }}</b></span></li>
                    <li><span class="float-left">Total Pengembalian</span> <span class="float-right">Rp <span class="text-danger">{{ $filters.formatNumber(total_return) }}</span></span></li>
                    <li><span class="float-left">Total Sisa Pinjaman</span> <span class="float-right">Rp {{ $filters.formatNumber( total_modal - total_return ) }}</span></li>
                </ul>
            </div>
        </div>
        <hr/>
        <div className="row">
            <div class="col-md-12 mb-2 mt-2">
                <div class="card">
                    <div class="card-header border-bottom">
                        <div class="tab-list mb-1">
                            <ul>
                                <li>
                                    <a href="javascript:;" :class="isEnableTab == 1 ? 'active' : ''" @click="ChangeTablist(1)">
                                        <i class="mdi mdi-cash-usd mr-2"></i>
                                        <span>Mutasi Saldo Modal</span><br/>
                                        <span class="text-secondary">Histori mutasi modal</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;" :class="isEnableTab == 2 ? 'active' : ''"  @click="ChangeTablist(2)">
                                        <i class="mdi mdi-credit-card-plus mr-2"></i>
                                        <span>Topup Modal</span><br/>
                                        <span class="text-secondary">Form penambahan pinjaman modal</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;" :class="isEnableTab == 3 ? 'active' : ''" @click="ChangeTablist(3)">
                                        <i class="mdi mdi-format-float-center mr-2"></i>
                                        <span>Pengembalian Modal</span><br/>
                                        <span class="text-secondary">Form pengembalian pinjaman modal</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="cardTabContent">
                            <div v-if="isEnableTab == 1">
                                <div class="row ">
                                    <div class="col-md-12">
                                        <div class="table-cover">
                                            <table class=" s-table">
                                                <thead>
                                                    <tr>
                                                        <th>Action</th>
                                                        <th>Tanggal</th>
                                                        <th>Deskripsi</th>
                                                        <th class="text-success">D</th>
                                                        <th class="text-danger">K</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="mixmodel.total > 0">
                                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                                        <td width="100">
                                                            <div class="dropdown" >
                                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                    <a href="javascript:;" class="dropdown-item" @click="EnableEdit(idx)">Edit</a>
                                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)">Hapus</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td  width="100">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}</td>
                                                        <td width="450">
                                                            <div v-if="isEnableEdit == items.id"> 
                                                                <input type="text" class="form-control" v-model="model_update.description"  @keyup="ValidateStock(idx)"/>
                                                            </div>
                                                            <span  v-else>{{ items.description }}</span>
                                                        </td>
                                                        <td  width="150">
                                                            <div v-if="isEnableEdit == items.id && items.k_action == 'withdraw'"> 
                                                                <input type="number" class="form-control" v-model="model_update.d" />
                                                            </div>
                                                            <span  v-else>{{ items.d > 0 ? $filters.formatNumber(items.d) : '--' }}</span>
                                                        </td>
                                                        <td  width="150">
                                                            <div v-if="isEnableEdit == items.id && items.k_action == 'topup'"> 
                                                                <input type="number" class="form-control" v-model="model_update.k" />
                                                            </div>
                                                            <span  v-else>{{ items.k > 0 ? $filters.formatNumber(items.k) : '--' }}</span>
                                                        </td>
                                                        <td class="text-center" width="100" v-if="isEnableEdit !== 0">
                                                            <a href="javascript:;" class="btn btn-sm btn-success" @click="DataUpdate(idx)" v-if="isEnableEdit == items.id">Simpan</a>
                                                            <span v-else></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-else>
                                                    <tr>
                                                        <td colspan="6">Data masih kosong, Tidak ada catatan pinjaman modal !</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <v-pagination
                                            v-if="mixmodel.total > 0"
                                            v-model="page"
                                            :class="'pagination'"
                                            :pages="parseInt(mixmodel.last_page)"
                                            :range-size="1"
                                            active-color="#ffffff"
                                            @update:modelValue="DataQuery"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div v-if="isEnableTab == 2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form @submit="SubmitDebit">
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Tgl Pinjaman <small class="text-danger">*</small></label>
                                                    <input type="date" class="form-control"   v-model="model.date" id="mydate">
                                                    <small class="text-danger" v-if="service_form_error.date">{{ service_form_error.date[0] }}</small>
                                                </div>
                                                <div class="col">
                                                    <label>Jumlah Pinjaman Modal <small class="text-danger">*</small></label>
                                                    <input type="number" class="form-control" placeholder="0" v-model="model.amount">
                                                    <small class="text-danger" v-if="service_form_error.amount">{{ service_form_error.amount[0] }}</small>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <label>Note <small class="text-danger">*</small></label>
                                                <span class="text-secondary">Tinggalkan note sebagai pengigat perubahan data.</span>
                                                <textarea class="form-control mt-2" rows="6" v-model="model.description"></textarea>
                                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                                            </div>
                                            <div class="form-group text-right mt-4">
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div v-if="isEnableTab == 3">
                                <div class="row ">
                                    <div class="col-md-12">
                                        <form @submit="SubmitKredit">
                                            <div class="form-group row">
                                                <div class="col">
                                                    <label>Tgl Pengembalian <small class="text-danger">*</small></label>
                                                    <input type="date" class="form-control"  v-model="model.date" id="mydate">
                                                    <small class="text-danger" v-if="service_form_error.date">{{ service_form_error.date[0] }}</small>
                                                </div>
                                                <div class="col">
                                                    <label>Jumlah Pengembalian Modal <small class="text-danger">*</small></label>
                                                    <input type="number" class="form-control" placeholder="0" v-model="model.amount">
                                                    <small class="text-danger" v-if="service_form_error.amount">{{ service_form_error.amount[0] }}</small>
                                                </div>
                                            </div>
                                            <div class="form-group mt-3">
                                                <label>Note <small class="text-danger">*</small> <br/><span class="text-secondary">Tinggalkan note sebagai pengigat perubahan data.</span></label>
                                                <textarea class="form-control mt-2" rows="6" v-model="model.description"></textarea>
                                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                                            </div>
                                            <div class="form-group text-right mt-4">
                                                <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Data'"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </main>
</template>


<script>
    import moment from "moment";
    export default 
    {
        data() {
           return {
                isEnableTab : 1,
                isSubmitProcess :false,
                data_profile : {},
                model : {date : ''},
                model_update : {},
                total_modal : 0,
                total_return : 0,
                isEnableEdit : 0,
                startdate : 'all',
                enddate : 'all',
            }
        },
        created() 
        {
            this.mixmeta_data.okey = 'date';
            this.mixmeta_data.oval = 'asc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            CloseModal(){
                this.$emit('closeModal');
            },
            ResetModel()
            {
                this.model = {};
            },
            ChangeTablist(no)
            {
                this.isEnableTab = no;
                this.service_form_error = {};
            },
            EnableEdit(idx)
            {
                this.model_update = this.mixmodel.data[idx];
                this.isEnableEdit = this.mixmodel.data[idx].id;
            },
            async DataUpdate()
            {
                if( confirm( 'Are you sure want to update this data ?' ) )
                { 
                    let res = await this.ServiceApiPost({action : 'post',url:  'sales/modal-update', data: this.model_update });
                    if(res && res.result)
                    {
                        this.isEnableEdit = 0;
                        this.DataQuery();
                    }
                }
            },
            async DataRemove(id)
            {
                if( confirm( 'Are you sure want to remove this data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url:  'sales/modal-destroy', data:{id : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();
                    }
                }
            },
            async DataQuery(pageNum)
            {
                this.page = pageNum == undefined ? 1 : pageNum;

                if( this.startdate == 'all' && this.enddate == 'all' )
                {
                    var query_startdate = this.startdate;
                    var query_enddate   = this.enddate;
                }else
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }


                let res = await this.ServiceApiDataPaging({
                    url: 'sales/modal-transaction', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'code_otmx_reseller', val : localStorage.getItem('QUERY_ID')},
                    ],
                });
                if(res){
                    this.mixmodel = res.query;
                    this.data_profile = res.profile;
                    this.total_modal = res.total_modal;
                    this.total_return = res.total_return;
                }
            },
            async SubmitDebit(e) 
            {
                e.preventDefault();
                this.isSubmitProcess = true;

                this.model.code_otmx_reseller = this.data_profile.code_otmx_reseller;
                this.model.initial = 'kredit';
                this.model.date = document.getElementById('mydate').value;

                let res = await this.ServiceApiPost({ action : 'post', url: 'sales/modal-store', data :this.model });
                if(res)
                {
                    this.model = {};
                    this.DataQuery();
                    this.isSubmitProcess = false;
                }
            },
            async SubmitKredit(e) 
            {
                e.preventDefault();

                if( parseInt(this.model.amount) > parseInt(this.total_modal) ){
                    alert('Jumlah pengembalian tidak boleh melebihi jumlah modal');
                    return;
                }

                this.model.code_otmx_reseller = this.data_profile.code_otmx_reseller;
                this.model.initial = 'debit';
                this.model.date = document.getElementById('mydate').value;

                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'sales/modal-store', data :this.model });
                if(res ){
                    this.model = {};
                    this.DataQuery();
                    this.isSubmitProcess = false;
                }
            }  
        }
    }
</script>

<style lang="scss" scoped>
.card-profile{ margin-top:35px; }
.tab-list{
    ul{
        li{
            width:33.12%;
        }
    }
}
</style>