<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Privileges Group 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Pengaturan privileges group akan menentukan fitur / modul yang dapat di akses oleh pengguna aplikasi.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="$router.go(-1)"><i class="mdi mdi-arrow-left mdi-icon"></i></a>
                </div>
            </div>
        </header>
        <hr/>

        <form @submit="Submit">
            <div class="row">
                <div class="col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="form-group">
                                <label>Nama Privileges Group</label>
                                <input type="text" class="form-control" placeholder="Contoh : Root" v-model="model.name">
                                <small class="text-danger" v-if="service_form_error.name">{{ service_form_error.name[0] }}</small>
                            </div>
                            <div class="form-group">
                                <label>Description</label>
                                <textarea class="form-control" placeholder="...." rows="5" v-model="model.description"></textarea>
                                <small class="text-danger" v-if="service_form_error.description">{{ service_form_error.description[0] }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-5">
                        <div class="text-center">
                            <a href="javascript:;" class="btn btn-sm btn-secondary mr-3" @click="$router.go(-1)">Cancel</a>
                            <ButtonProcess :classes="'btn btn-sm btn-warning'" :submit_state="isSubmitProcess" :type="'Submit'" :title="'Simpan Perubahan'" @click="Save()"/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </main>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isSubmitProcess : false,
                model: {},
            };
        },
        created() {
            this.DataQuery();
        },
        methods: 
        {
            async DataQuery()
            {
                let query = await this.ServiceApiPost({url: 'privileges/find', data: {id_privileges : localStorage.getItem('QUERY_ID')}});
                if(query){
                    this.model = query;
                }
            },
            async Submit(e) 
            {
                e.preventDefault();

                this.isSubmitProcess = true;
                let res = await this.ServiceApiPost({ action : 'post', url: 'privileges/update', data :this.model, callback_url : 'privileges-data' });
                if(res && res.result)
                {
                    this.isSubmitProcess = false;
                }
            }
        }
    }
</script>
