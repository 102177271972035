<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Supplier
                    </h1>
                    <div class="page-header-subtitle text-secondary">Master data supplier produk.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <router-link :to="{ name: 'supplier-insert' }" class="nav-module" ><i class="mdi mdi-plus mdi-icon"></i></router-link>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="table-info">
                            <div class="float-left">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-cloud-download text-success"></i> 
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <input type="text" class="form-control" placeholder="Cari Nama Supplier, Alamat ...." v-model="mixmeta_data.sval"/>
                                <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                <a href="javascript:;" @click="ServiceSearchData()" class="search"><i class="mdi mdi-magnify"></i></a>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter">Action</th>
                                        <th data-filter="supplier_name">Nama Supplier</th>
                                        <th data-filter="address">Alamat & Tlp, Email</th>
                                        <th data-filter="created_at">Tgl.Dibuat</th>
                                        <th data-filter="updated_at">Tgl.Diupdate</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" @click="DataUpdate(items.id)"  v-if="$store.state.privileges_modules.uam.includes('update')">Edit</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)"  v-if="$store.state.privileges_modules.uam.includes('delete')">Hapus</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="250">{{ items.supplier_name }}</td>
                                        <td width="300">
                                            <span class="text-secondary">{{ items.address }}</span><br/>
                                            {{ items.phone_number }} <span class="text-secondary" v-if="items.email !== ''">( {{items.email}} )</span>
                                        </td>
                                        <td>{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                        <td>
                                            <span v-if="items.updated_at !== null">
                                                {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.updated_at, 'LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default {
        data() {
            return {
                isFirstLoad : true,
            }
        },
        created()
        {
            this.mixmeta_data.okey = 'created_at';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods:
        {
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'supplier-update'});
            },
            async DataRemove(id)
            {
                if( confirm( 'Apakah anda yakin ingin menghapus data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url:  'supplier/destroy', data:{id : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();
                    }
                }
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;

                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url:'supplier/data', 
                    offset : this.page,
                    search: { key : 'global-search', value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    
                });
                if(res){ 
                    this.mixmodel = res.query; 
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }, 
        }
    }
</script>
