<template>
    <main>
        <BrowseSales v-on:closePopup="CloseBrowseSales"  v-if="isEnablePopup == 'browse-sales'"/>
        
        <!-- popup validate  -->
        <div class="popup-modal" id="validate-payment" v-if="isEnablePopup == 'validate_payment'">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Konfirmasi !</h5>
                        <button class="btn btn-sm btn-close" type="button" @click="isEnablePopup = false"></button>
                    </div>
                    <div class="modal-body">
                        <span class="">Pastikan data telah terverifikasi dengan benar.</span><br/>
                        <span class=" text-secondary">Apakah anda yakin ingin melanjutkan validasi data ?</span>
                    </div>
                    <div class="modal-footer"><button class="btn btn-sm btn-success float-right" type="button"   @click="ValidatePayment()">OK, KONFIRMASI</button></div>
                </div>
            </div>
        </div>

        <!-- popup remove data  -->
        <div class="popup-modal" id="remove-payment" v-if="isEnablePopup == 'remove_data'">
            <div class="modal-dialog modal-sm">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Konfirmasi !</h5>
                        <button class="btn btn-sm btn-close" type="button" @click="isEnablePopup = false"></button>
                    </div>
                    <div class="modal-body">
                        <span class="mt-4">Apakah anda yakin ingin menghapus data pembayaran ?</span><br/>
                        <span class=" text-secondary">Klik "OK" untuk menghapus data pembayaran.</span>
                        <div class="mb-3">
                            <ul class="data-list mt-3">
                                <li><span class="float-start">Tgl.Pembayaran</span>  <span class="float-right">{{ $filters.formatDate(model_detail.date, 'DD/MM/YYYY LT') }}</span></li>
                                <li><span class="float-start">Kode Sales</span>  <span class="float-right">{{ model_detail.sales_name }} - {{ model_detail.code_otmx_reseller }}</span></li>
                                <li><span class="float-start">Kode Reseller</span>  <span class="float-right">{{ model_detail.reseller_name }} - {{ model_detail.code_otmx_reseller_2 }}</span></li>
                                <li><span class="float-start"><b>Jumlah Pembayaran</b></span>  <span class="float-right">Rp <b>{{  $filters.formatNumber(model_detail.amount) }}</b></span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer"><button class="btn btn-sm btn-danger float-right" type="button"  @click="RemovePayment()"><i class="mdi mdi-delete-forever"></i> Hapus</button></div>
                </div>
            </div>
        </div>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-12 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Penerimaan Pembayaran 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola penerimaan pembayaran dari sales.</div>
                </div>
            </div>
        </header>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-7" v-if="$store.state.privileges_modules.uam.includes('filter_sales')">
                                <div class="browse-reseller">
                                    <div class="img"  v-if="model_upline.name">
                                        <ImageSmCircle :photo="model_upline.photo_selfi_id" />
                                    </div>
                                    <div class="desc">
                                        <div class="profile">
                                            <span class="mb-0">{{ model_upline.name ? model_upline.name :  'Semua Sales' }} </span> 
                                            <span class="text-secondary" v-if="model_upline.name">- {{ model_upline.status !== null ? 'Tervalidasi : ' + model_upline.status :  'Belum Tervalidasi.'}} </span>
                                            <br/>
                                            <span class="">{{ model_upline.code_otmx_reseller ? model_upline.code_otmx_reseller + '-' +model_upline.level_code :  'Filter transaksi berdasarkan reseller.'}} </span><br/>
                                        </div>
                                       
                                    </div>
                                    <div class="group-button">
                                        <ButtonProcess :classes="'btn btn-sm btn-circle btn-warning'" :type="'Submit'" :title="'Search Sales'"  @click="isEnablePopup = 'browse-sales'"/>
                                        &nbsp;&nbsp;
                                        <a href="javascript:;" @click="ResetFilterSales()" v-if="model_upline.name"><i class="mdi mdi-close"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <ul class="data-list">
                                    <li><span>Penerimaan</span> <span class="float-right">Rp <b class="text-danger">{{ $filters.formatNumber(total_receive) }}</b></span></li>
                                    <li><span>Pembayaran</span> <span class="float-right">Rp <span class="text-success">{{ $filters.formatNumber(total_paid) }}</span></span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row table-filter">
                            <div class="col-md-4 col">
                                <div class="form-group">
                                    <label>Periode Transaksi</label>
                                    <select class="form-control" v-model="fdate" @change="FilterFDate()">
                                        <option value="0">Pilih Manual</option>
                                        <option value="1">Hari Sekarang</option>
                                        <option value="7">7 Hari Terakhir</option>
                                        <option value="30">30 Hari Terakhir</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Mulai</label>
                                    <input type="date" class="form-control"  v-model="startdate"   id="startDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-3 col">
                                <div class="form-group">
                                    <label>Tanggal Akhir</label>
                                    <input type="date" class="form-control"  v-model="enddate" id="endDate" :disabled="fdate !== '0' ? true  : false"/>
                                </div>
                            </div>
                            <div class="col-md-2 col pr-2">
                                <div class="form-btn">
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Filter'" @click="DataQuery();"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="table-info">
                            <div class="float-left">
                                <ul>
                                    <li class="">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery();"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-cloud-download text-success"></i> 
                                    </li>
                                    <li  class="border-left" v-if="collection.length > 0 || is_collect_all">
                                        <a href="javascript:;" class="link"  @click="isEnablePopup = 'validate_payment'"><i class="mdi mdi-check mr-1 "></i> VALIDASI PEMBAYARAN</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="group-search">
                                <input type="text" class="form-control" placeholder="Cari Kode Sales, Kode Reseller, Jumlah Pembayaran ...." v-model="mixmeta_data.sval"/>
                                <a href="javascript:;" @click="ServiceRefreshData()" class="clear" v-if="mixmeta_data.sval !== ''"><i class="mdi mdi-delete-circle"></i></a>
                                <a href="javascript:;" @click="ServiceSearchData()" class="search"><i class="mdi mdi-magnify"></i></a>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="s-table table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <!--<th v-if="$store.state.privileges_modules.uam.includes('delete')">Hapus</th>-->
                                        <th><input type="checkbox" id="checked-all" @change="CollectAllItem()" /></th>
                                        <th data-filter="date">Tanggal</th>
                                        <th data-filter="code_otmx_reseller">Kode Sales</th>
                                        <th data-filter="code_otmx_reseller_2">Kode Reseller</th>
                                        <th data-filter="description">Deskripsi</th>
                                        <th data-filter="amount">Jumlah</th>
                                        <th data-filter="updated_at">User & Tgl.Validasi</th>
                                    </tr>
                                </thead>
                                <tbody  v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx" :class="items.status == 'paid' ? 'bg-confirm' : ''">
                                        <!--<td width="50" v-if="$store.state.privileges_modules.uam.includes('delete')"><a href="javascript:;" @click="IsRemovePayment(idx)" ><i class="icon mdi mdi-delete-circle text-danger"></i></a></td>-->
                                        <td width="10">
                                            <div v-if="items.status !== 'paid'">
                                                <input class="group-checkbox" type="checkbox" :id="items.id" :checked="ValidateChecked(items.id)" @change="CollectItem(items.id)" />
                                            </div>
                                            <div v-else><i class="icon mdi mdi-check-all text-success"></i></div>
                                        </td>
                                        <td>{{ $filters.formatDate(items.date, 'DD/MM/YYYY') }}</td>
                                        <td>
                                            <span>{{ items.sales_name }}</span><br/>
                                            <span class="text-secondary">{{ items.code_otmx_reseller }}</span>
                                        </td>
                                        <td>
                                            <span>{{ items.reseller_name }}</span><br/>
                                            <span class="text-secondary">{{ items.code_otmx_reseller_2 }}</span>
                                        </td>
                                        <td width="230">{{ items.description }}</td>
                                        <td>Rp {{ $filters.formatNumber(items.amount) }}</td>
                                        <td>
                                            <span v-if="items.status == 'paid'">
                                                <span>{{ items.validate_user }}</span>
                                                <br/><small class="text-secondary"> {{ $filters.formatDate(items.updated_at, 'DD/MM/YYYY LT') }}</small>
                                            </span>
                                            <span class="text-secondary" v-else>-- --</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    import $ from 'jquery';
    import moment from "moment";
    import BrowseSales from '../Resellers/BrowseSales';
  
    export default {
        components: {
            BrowseSales,
        },
        data() {
            return {
                isRefreshTable :  false,
                isEnableFilter : false,
                isFirstLoad : true,
                isEnablePopup : false,
                is_collect_all : false,

                model_upline :{},
                model_detail : {},
                collection : [],
                validate_user :{},
                total_receive : 0,
                total_paid : 0,
                freseller : localStorage.getItem('user-code'),
                fprivileges : localStorage.getItem('user-privileges'),
                input_code_reseller : '',

                fdate : 7,
                startdate : new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10),
                enddate : new Date().toISOString().slice(0,10),
            }
        },
        created()
        {
            this.IdentifiedUserQuery();
            this.validate_user.name = localStorage.getItem("user-name");
            this.validate_user.photo = localStorage.getItem("user-photo");

            this.mixmeta_data.okey = 'date';
            this.mixmeta_data.oval = 'desc';
            this.ServiceInitFunction('DataQuery', 's-table');            
        },
        methods:
        {
            IdentifiedUserQuery()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }
            },
            async CollectAllItem()
            {
                this.is_collect_all =! this.is_collect_all;

                let model = this.mixmodel.data;
                if( this.is_collect_all == true )
                {
                    for(let key in model)
                    {
                        if( model[key].status !== 'paid' ){
                            this.collection.push( model[key].id );
                        }
                    }

                }else
                {
                    for(let key in model)
                    {
                        let index_of_element = this.collection.indexOf(model[key].id);
                        this.collection.splice(index_of_element, 1);
                    }
                }
                
            },
            
            ResetFilterSales()
            {
                if( this.fprivileges == 'all' ){
                    this.freseller = 'all';
                }else{
                    this.freseller = localStorage.getItem('user-code');
                }

                this.input_code_reseller = '';
                this.model_upline = {};
                this.DataQuery();
            },
          
            RefreshData()
            {
                this.total_loans  = 0,
                this.total_paid   = 0,
                this.ServiceRefreshData();
            },
            CloseBrowseSales(data)
            {
                this.isEnablePopup = false;
                if(data)
                {
                    this.model_upline = data;
                    this.freseller = data.code_otmx_reseller;
                    this.DataQuery();
                }
            },
            ValidateChecked(initial_id)
            { 
                if (this.collection.includes(initial_id)) {
                    return true;
                }
                return false;
            },
            CollectItem(initial_id)
            {
                if($("#"+initial_id).is(':checked'))
                {
                    if (this.collection.indexOf(initial_id) === -1) {
                        this.collection.push( initial_id );
                    }

                }else{
                    let index_of_element = this.collection.indexOf(initial_id);
                    this.collection.splice(index_of_element, 1);
                }
            },
            IsRemovePayment(idx)
            {
                this.model_detail = this.mixmodel.data[idx];
                this.isEnablePopup = 'remove_data';
            },
            async RemovePayment()
            {
                let res = await this.ServiceApiPost({ action : 'post', url:  'sales/destroy-payment', data:{id : this.model_detail.id}, msg_type:'trash' });
                if(res && res.result)
                {
                    this.DataQuery();
                }
            },
            async ValidatePayment()
            {
                let res = await this.ServiceApiPost({ action : 'post', url:  'sales/validate-payment', data:{
                    collection : this.collection,
                    user : localStorage.getItem("user-name")
                } });
                if(res && res.result)
                {
                    this.isEnablePopup = false;
                    this.collection = [];
                    this.DataQuery();
                }
            },
            FilterFDate()
            {
                switch(this.fdate)
                {
                    case '1': 
                        this.startdate = new Date( Date.now()).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '7': 
                        this.startdate = new Date( Date.now() + -6*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    case '30': 
                        this.startdate = new Date( Date.now() + -29*24*3600*1000 ).toISOString().slice(0,10);
                        this.enddate = new Date(Date.now()).toISOString().slice(0,10);
                    break;
                    default:
                    break;
                }

                if( this.fdate !== '0' )
                {
                    document.getElementById('startDate').value = this.startdate;
                    document.getElementById('endDate').value = this.enddate;
                    this.DataQuery();
                }
            },
            async DataQuery(pageNum)
            {
                this.isRefreshTable = true;
                this.isEnableFilter = false;
                this.page = pageNum == undefined ? 1 : pageNum;

                var query_startdate = this.startdate;
                var query_enddate   = this.enddate;
                if( document.getElementById('startDate') )
                {
                    var sdate = document.getElementById('startDate').value;
                    var edate = document.getElementById('endDate').value;
                    query_startdate = moment(sdate).format("DD-MM-YYYY");
                    query_enddate   = moment(edate).format("DD-MM-YYYY");
                }


                let res = await this.ServiceApiDataPaging({
                    url:'sales/receive-payment', 
                    offset : this.page,
                    search: { key : 'global-search', value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                    filter : [
                        {key : 'start_date', val : query_startdate},
                        {key : 'end_date', val : query_enddate},
                        {key : 'credit_loans_receive.code_otmx_reseller', val : this.freseller},
                    ]
                });
                if(res){ 

                    this.mixmodel = res.query;
                    this.total_receive = res.total_receive;
                    this.total_paid = res.total_paid;

                    if( this.is_collect_all == true )
                    {
                        for(let key in this.mixmodel.data)
                        {
                            let initial_id = this.mixmodel.data[key].id;
                            if (this.collection.indexOf(initial_id) === -1) {
                                this.collection.push( initial_id );
                            }
                        }
                    }


                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            }
        }
    }
</script>

