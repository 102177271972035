<template>
    <div class="page-loader">
        <div class="group" v-if="connection == true">
            <div class="loader">Loading...</div>
        </div>
        <div class="group" v-if="connection == 'error-client-network'">
            <img src="assets/images/wifi-error.png"/><br/>
            <span class="text-danger">Network Error !</span><br/>
            <span>Please check your internet connection.</span>
        </div>
        <div class="group" v-if="connection == 'error-server'">
            <img src="assets/images/server-error.png"/><br/>
            <span class="text-danger">Server Service Unavailable (503) !</span><br/>
            <span>Opps, Sorry our system is busy now. please try later.</span>
        </div>
    </div>
</template>
<script>
    export default 
    {
        props : ['connection'],
        name : 'SystemConnection'
    }
</script>

<style lang="scss" scoped>



.page-loader{
    float:left;
    height:400px;
    width:100%;
    position: relative;
    text-align:center;


    span{
        font-size:14px;
    }
    small{
        font-size:13px;
        font-size:400;
    }


    div.group{
        position:relative;
        top:200px;
        text-align:center;
        img{
            width:90px;
            opacity: .5;
        }
        span.btn{

            background-color: #f74a34cf;
            color:#fff;
            padding:7px;
            width:100px;
            font-size:13px;
            font-weight:200;
            margin:0 auto;
        }
        span.btn::after{
            font-weight:400;
            content: ' .';
            animation: dots 1s steps(2, end) infinite;
        }

        small{
            top:5px;
            padding:5px;
            border-radius:3px;
            font-weight:300;
        }
    }

    .loader,
    .loader:after {
        border-radius: 50%;
        width: 7em;
        height: 7em;
    }
    .loader {

        margin: 0 auto;
        margin-bottom:10px;
        font-size: 7px;
        position: relative;
        top:40%;
        text-indent: -9999em;
        border-top: 4px solid rgba(255, 255, 255, 0.2);
        border-right: 4px solid rgba(255, 255, 255, 0.2);
        border-bottom: 4px solid rgba(255, 255, 255, 0.2);
        border-left: 4px solid #ffffff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-animation: load8 0.5s infinite linear;
        animation: load8 0.5s infinite linear;
    }
    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

}
.page-loader-blank {
    background:none;
}
</style>