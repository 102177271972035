<template>
    <main>
        <header>
            <div class="row align-items-center justify-content-between mb-4">
                <div class="col-md-10 mt-4">
                    <h1 class="page-header-title">
                        <div class="page-header-icon"><i data-feather="activity"></i></div>
                        Produk Promo Banner 
                    </h1>
                    <div class="page-header-subtitle text-secondary">Kelola diskon / promo pada harga produk yang akan di terapkan pada konten banner di apps.</div>
                </div>
                <div class="col-md-2 mt-4">
                    <a href="javascript:;" class="nav-module" @click="isEnableFilter=true"><i class="mdi mdi-menu mdi-icon"></i> </a>
                    <router-link :to="{ name: 'promo-insert' }" class="nav-module" v-if="$store.state.privileges_modules.uam.includes('insert')"><i class="mdi mdi-plus mdi-icon"></i> </router-link>
                </div>
            </div>
        </header>
        <hr/>
        <div class="row">
            <div class="col-xl-12">
                <div class="card mb-4">
                    <div class="card-body">
                          <!-- component filter table -->
                          <div v-if="isEnableFilter">
                            <div class="slide-right-filter" >
                                <div class="box-sorting">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Ordering <br/><small class="text-secondary">Urutkan berdasarkan ascending atau descending.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.okey">
                                            <option value="id">ID</option>
                                            <option value="title">Nama Promo</option>
                                            <option value="product_name">Nama Produk</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                            <option value="updated_at">Tgl.Update</option>
                                        </select>
                                        <div class="sort">
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'desc' ? 'active float-left' : 'float-left'" @click="mixmeta_data.oval = 'desc'"><i class="mdi mdi-sort-descending"></i></a>
                                            <a href="javascript:;" :class="mixmeta_data.oval == 'asc' ? 'active float-right' : 'float-right'"  @click="mixmeta_data.oval = 'asc'"><i class="mdi mdi-sort-ascending"></i> </a>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                </div>
                                <div class="box-search">
                                    <label><i class="mdi mdi-information-outline text-warning"></i> Pencarian Data <br/><small class="text-secondary">Masukan keyword pencarian data.</small></label>
                                    <div class="form-group">
                                        <select class="form-control" v-model="mixmeta_data.skey">
                                            <option value=""> -- Target Kolom Pencarian --</option>
                                            <option value="listing_type">Tipe Listing</option>
                                            <option value="title">Nama Promo</option>
                                            <option value="product_name">Nama Produk</option>
                                            <option value="created_at">Tgl.Dibuat</option>
                                            <option value="updated_at">Tgl.Update</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <input type="date" class="form-control"  v-model="mixmeta_data.sval" v-if="mixmeta_data.skey == 'created_at' || mixmeta_data.skey == 'updated_at'"/>
                                        <input type="search" class="form-control"  v-model="mixmeta_data.sval" placeholder="Masukan Keyword ...." v-else/>
                                    </div>
                                    <hr/>
                                    <ButtonProcess :classes="'btn btn-full btn-sm btn-warning'"  :type="'Submit'" :title="'Terapkan'" @click="DataQuery()"/>
                                </div>
                            </div>
                            <div class="slide-center-info ">
                                <span class="exit" @click="isEnableFilter = false"><i class="mdi mdi-close" ></i></span>
                                <div class="clipbox ">
                                    <h3><i class="mdi mdi-information-outline text-warning"></i> Download Data</h3>
                                    <span>Anda dapat melakukan konversi data dengan cara download data sesuai pilihan format berikut.</span>
                                    <ul class="mt-3 ">
                                        <li><a href="javascript:;" id="xlsx"><i class="mdi mdi-file-excel"></i><span>.xlsx</span></a></li>
                                        <li><a href="javascript:;" id="pdf"><i class="mdi mdi-file-pdf"></i><span>.pdf</span></a></li>
                                    </ul>
                                    <br/>
                                    <div id="sent-mail">
                                        <a href="javascript:;" id="dropdown"><i class="mdi mdi-chevron-double-down"></i></a>
                                        <br/>
                                        <span >Jika anda mengisikan alamat email maka setelah proses download sistem akan mengirimkan data ke email yang dituju.</span>
                                        <div>
                                            <i class="mdi mdi-email-open-outline"></i>
                                            <input type="email" class="form-control" placeholder="Masukan Alamat Email"/>
                                        </div>
                                    </div>
                                    <ButtonProcess :classes="'btn btn-full animate__animated animate__flipInX'"  :type="'Submit'" :title="'UNDUH SEKARANG'" />
                                </div>
                            </div>
                        </div>
                        <!-- end component filter table -->
                        <div class="table-info">
                            <div class="float-right">
                                <ul>
                                    <li>
                                        <i :class="mixmeta_data.oval == 'desc' ? 'mdi mdi-sort-descending' : 'mdi mdi-sort-ascending'"></i> <span>{{ $filters.capitalize( mixmeta_data.oval )  }}</span>
                                    </li>
                                    <li class="border-left">
                                        <i class="mdi mdi-refresh" v-if="! $store.state.loading.status" @click="DataQuery()"></i>
                                        <div class="spinner-border  spinner-border-sm" role="status" v-else></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <SystemConnection :connection="service_connection"  v-if="isFirstLoad" />
                        <div class="table-cover" v-else>
                            <table :class="isRefreshTable ? 'table-load' : '' ">
                                <thead>
                                    <tr>
                                        <th class="text-center no-filter" v-if="$store.state.privileges_modules.uap">Action</th>
                                        <th :class="mixmeta_data.okey == 'listing_type' ? 'active' : ''">Tipe Listing</th>
                                        <th :class="mixmeta_data.okey == 'title' ? 'active' : ''">Title</th>
                                        <th :class="mixmeta_data.okey == 'product_name' ? 'active' : ''">Nama Produk</th>
                                        <th :class="mixmeta_data.okey == 'created_at' ? 'active' : ''">Masa berlaku promo</th>
                                        <th :class="mixmeta_data.okey == 'updated_at' ? 'active' : ''">Tgl.Diupdate</th>
                                    </tr>
                                </thead>
                                <tbody v-if="mixmodel.total > 0">
                                    <tr v-for="(items, idx) in mixmodel.data" :key="idx">
                                        <td width="80" class="text-center" v-if="$store.state.privileges_modules.uap">
                                            <div class="dropdown" >
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Tools</button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                    <a href="javascript:;" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#publish-news" @click="model.id = items.id" v-if="items.status == 'draft'" > Publish</a>
                                                    <a href="javascript:;" class="dropdown-item" @click="DataUpdate(items.id)" v-if="$store.state.privileges_modules.uam.includes('update')">Edit</a>
                                                    <a href="javascript:;" class="dropdown-item text-danger" @click="DataRemove(items.id)" v-if="$store.state.privileges_modules.uam.includes('delete')">Remove</a>
                                                </div>
                                            </div>
                                        </td>
                                        <td width="130">{{ items.listing_type }}</td>
                                        <td width="250">{{ items.title }}</td>
                                        <td width="250">
                                            <span class="text-secondary">Kode Operator : {{ items.product_operator_code }}</span><br/>
                                            <b>{{ items.product_code }}</b>- {{ items.product_name }}
                                        </td>
                                        <td  width="200">
                                        {{ $filters.formatDate(items.start_date, 'DD/MM/YYYY') }}
                                            -
                                        {{ $filters.formatDate(items.end_date, 'DD/MM/YYYY') }}
                                        </td>
                                        <td width="120">{{ $filters.formatDate(items.created_at, 'DD/MM/YYYY') }}<br/><small class="text-secondary">{{ $filters.formatDate(items.created_at, 'LT') }}</small></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <v-pagination
                            v-if="mixmodel.total > 0"
                            v-model="page"
                            :class="'pagination'"
                            :pages="parseInt(mixmodel.last_page)"
                            :range-size="1"
                            active-color="#ffffff"
                            @update:modelValue="DataQuery"
                        />
                        <div class="paginate-total">Total Records ( {{ $filters.formatNumber(mixmodel.total) }} )</div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
    export default 
    {   
        data() {
            return {
                isEnableFilter : false,
                isFirstLoad : true,
            };
        },
        created()
        {
            this.ServiceGlobalSearch();
            this.ServiceInitFunction('DataQuery', 's-table');
        },
        methods: 
        {   
           
            async DataQuery(pageNum)
            {
                this.isEnableFilter = false;
                this.isRefreshTable = true;
                this.page = pageNum == undefined ? 1 : pageNum;
                let res = await this.ServiceApiDataPaging({
                    url: 'promo/data', 
                    offset : this.page,
                    search: { key : this.mixmeta_data.skey, value : this.mixmeta_data.sval },
                    order: { key : this.mixmeta_data.okey, value : this.mixmeta_data.oval },
                });
                if(res)
                {
                    this.mixmodel = res.query;
                    this.isFirstLoad = false;
                    this.isRefreshTable = false;
                }
            },
            DataUpdate(id)
            {
                localStorage.setItem('QUERY_ID', id);
                this.$router.push({name : 'promo-update'});
            },
            async DataRemove(id)
            {
                if( confirm( 'Apakah anda yakin ingin menghapus data ?' ) )
                { 
                    let res = await this.ServiceApiPost({ action : 'post', url:  'promo/destroy', data:{id : id}, msg_type:'trash' });
                    if(res && res.result)
                    {
                        this.DataQuery();
                    }
                }
            }
        }
    }
</script>